import React from 'react';
import { AccountContextProvider } from './AccountContext';
import { NavigationContextProvider } from './NavigationContext';
import { ToastContextProvider } from './ToastContext';
import { ProjectCommentContextProvider } from './ProjectCommentContext';
import { ProjectEventContextProvider } from './ProjectEventContext';
import { ProjectMeetingContextProvider } from './ProjectMeetingContext';
import { ProjectCollaboratorContextProvider } from './ProjectCollaboratorContext';
import { PropertyContextProvider } from './PropertyContext';
import { DashboardContextProvider } from './DashboardContext';
import { CollaboratorContextProvider } from './CollaboratorContext';
import { DashboardCollaboratorContextProvider } from './DashboardCollaboratorContext';
import { CompanyContextProvider } from './CompanyContext';
import { ProjectCompanyContextProvider } from './ProjectCompanyContext';
import { DashboardCompanyContextProvider } from './DashboardCompanyContext';

const AppContexts = ({ children }) => (
  <AccountContextProvider>
    <ToastContextProvider>
      <PropertyContextProvider>
        <NavigationContextProvider>
          <CompanyContextProvider>
            <DashboardCompanyContextProvider>
              <CollaboratorContextProvider>
                <DashboardCollaboratorContextProvider>
                  <DashboardContextProvider>
                    <ProjectCompanyContextProvider>
                      <ProjectCollaboratorContextProvider>
                        <ProjectCommentContextProvider>
                          <ProjectEventContextProvider>
                            <ProjectMeetingContextProvider>
                              {children}
                            </ProjectMeetingContextProvider>
                          </ProjectEventContextProvider>
                        </ProjectCommentContextProvider>
                      </ProjectCollaboratorContextProvider>
                    </ProjectCompanyContextProvider>
                  </DashboardContextProvider>
                </DashboardCollaboratorContextProvider>
              </CollaboratorContextProvider>
            </DashboardCompanyContextProvider>
          </CompanyContextProvider>
        </NavigationContextProvider>
      </PropertyContextProvider>
    </ToastContextProvider>
  </AccountContextProvider>
);

export default AppContexts;

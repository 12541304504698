import { CONSTANT } from './constantHelper';

export const getCurrentReleaseNote = () => {
  try {
    return RELEASE_NOTES.find((rn) => rn.version === CONSTANT.version);
  } catch (error) {
    console.log(`No release note has been found for current version (${CONSTANT.version})`);
    return null;
  }
};

const RELEASE_NOTES = [
  {
    version: '3.0.0',
    date: '05/11/2024',
    title: 'Sortie de la version 3 de Ohm !',
    intro:
      "Bienvenue sur la nouvelle version de Ohm. Dans cette boite de dialogue, à chaque nouvelle mise à jour future, vous trouverez la liste des bugs réparés et des nouvelles fonctionnalités. Nous vous souhaitons une agréable découverte de l'outil.",
    newFeatures: null,
    fixes: null
  },
  {
    version: '3.0.0-beta11',
    date: '21/10/2024',
    title: null,
    intro: null,
    newFeatures: [
      'Les SPV peuvent maintenant avoir plusieurs adresses postales secondaires.',
      "Migration des données permettant l'affichage des futurs graphiques.",
      'Migration du type de propriétaire des parcelles.',
      'Affichage du type de compte pour les collaborateurs internes (Chef de projet, Manager, Administrateur) dans les panels.',
      "Ajout d'un module de texte enrichi pour la saisie des commentaires et des décisions importantes."
    ],
    fixes: [
      'Seul l\'auteur d\'un commentaire peut modifier son contenu. Le commentaire est labellisé "modifié" lorsque le contenu est modifié.',
      'Amélioration de la gestion des erreurs du script de migration de données.'
    ]
  },
  {
    version: '3.0.0-beta10',
    date: '21/10/2024',
    title: null,
    intro: 'Troisième vague de corrections liées aux tests de la V3.',
    newFeatures: null,
    fixes: [
      "Il est possible pour un Chef de projet de modifier la période réelle d'une étape du projet.",
      "Le script de migration ne change plus l'extension des fichiers .kmz en .kml. Il reste necessaire de télécharger les fichiers .kmz, de les dézipper et de réuploader les fichiers .kml contenus dans les .kmz afin que le carte s'affiche correctement. ",
      "Le champ SPV n'est plus obligatoire au niveau des projets.",
      'Certains comptes Chef de projet ne fonctionnaient pas car ils étaient en double/triple. Le script de migration a évolué pour prendre en compte les nettoyages à effectuer sans perdre de données.',
      "Fautes d'orthographes."
    ]
  },
  {
    version: '3.0.0-beta9',
    date: '30/09/2024',
    title: null,
    intro: 'Deuxième vague de corrections liées aux tests de la V3.',
    newFeatures: [
      'Ajout de la fonctionnalité "Concertation" dans le panneau latéral du projet et du module "3 dernières concertations" dans la synthèse du projet.',
      'Ajout du module "Dernière décision importante" dans la synthèse du projet',
      'Ajout des "SPV" au niveau d\'un projet',
      'Ajout du tri par nom de projet dans la liste des projets.',
      'Suppression du filtre "Client" dans la liste des projets pour les comptes non-administrateurs',
      "Ajout d'un tableau des villes présentes dans la base de données dans la partie administration. Cela permet de modifier le nom et le code postal des villes.",
      "Amélioration du système de saisie de la planification initiale d'un projet",
      'Les administrateurs et managers peuvent maintenant modifier les planifications initiales sans ajouter de commentaire.',
      "La date et ville de naissance, le genre et le nom du conjoint n'apparaîssent plus que pour les Propriétaires/Exploitants"
    ],
    fixes: [
      "L'état d'un projet est maintenant modifiable",
      "Le manager d'un projet peut maintenant être chef de projet au niveau d'un projet.",
      'La migration de la base de données prend désormais en compte les études facturées dans Ohm v2.',
      'Seul le format KML est autorisé au niveau des fiches projets.'
    ]
  },
  {
    version: '3.0.0-beta8',
    date: '15/09/2024',
    title: null,
    intro: 'Première vague de corrections liées aux tests de la V3.',
    newFeatures: [
      'Ajout de ville et de ville de naissance au niveau des fiches contacts',
      "Commentaire obligatoire lorsque un utilisateur change l'état d'une phase",
      'Tri des tags, des entreprises et des contacts par ordre alphabétique',
      'Seulement les comptes Admin et Manager peuvent créer de nouveaux tags (décisions et commentaires)',
      "Tous les champs d'une étude, d'une instruction et d'une tâche (post-instruction) sont maintenant modifiables (sauf le nom)",
      "Ajout de plusieurs types d'entreprises",
      "Filtrage du tableau de synthèse par 'En cours' et 'Prioritaire' afin d'améliorer les performances"
    ],
    fixes: [
      'Filtres Client et Chef de projet dans le tableau de synthèse',
      "Les chefs de projets désactivés n'apparaissent plus dans la liste de création de projet",
      'Corrections orthographiques et sémantiques',
      'Scroll dans la liste des commentaires',
      "Les décisions automatiques fonctionnent lorsque l'on modifie la valeur d'un risque",
      "Le budget d'une étude, d'une instruction et d'une tâche peut être de 0€",
      "Modification des conditions d'ouverture de l'étape d'études (SF4 -> min. SF3)",
      'Toute la ligne du tableau de synthèse est cliquable pour ouvrir le projet',
      'Les dates des commentaires ont été migrées depuis Ohm V2',
      "La partie droite du planning s'affiche correctement"
    ]
  },
  {
    version: '3.0.0-beta6',
    date: '23/05/2024',
    title: null,
    intro: null,
    newFeatures: ['Ajout du module de report de bug'],
    fixes: []
  },
  {
    version: '3.0.0-beta5',
    date: '23/05/2024',
    title: null,
    intro: null,
    newFeatures: [
      'Ajout de la mécanique des releases notes',
      'Ajout des factures dans les études',
      'Ajout du panneau latéral des Entreprises',
      'Ajout de la planification de la post-instruction'
    ],
    fixes: [
      "Correction de l'affichage des étapes d'un projet quand le niveau est 0",
      "Suppression de la planification de l'identification"
    ]
  }
];

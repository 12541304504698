import React from 'react';
import SignInForm from './SignInForm';
import Layout from '../Layout';

function SignIn() {
  return (
    <Layout>
      <SignInForm />
    </Layout>
  );
}

export default SignIn;

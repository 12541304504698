import React from 'react';
import { InputText } from 'primereact/inputtext';
import { CONSTANT } from '../../../../utils/constantHelper';
import { useDashboardCollaboratorContext } from '../../../../contexts/DashboardCollaboratorContext';
import PanelFilter from '../../PanelFilter';
import PanelCollaboratorListItem from '../../Common/Collaborator/PanelCollaboratorListItem';

function DashboardPanelCollaboratorList({
  handleSearch,
  handleSelectCollaborator,
  collaboratorList
}) {
  const { dashboardCollaboratorContext, setCollaboratorType } = useDashboardCollaboratorContext();

  const emptyCollaboratorList = (
    <div className="h-[calc(100vh-400px)] w-full flex items-center justify-center italic text-slate-400">
      <span>Aucun contact à afficher</span>
    </div>
  );

  return (
    <div className="flex flex-col gap-y-3 h-full">
      <span className={`p-input-icon-left w-full`}>
        <i className={`pi pi-search`} />
        <InputText
          defaultValue={dashboardCollaboratorContext.query}
          placeholder="Rechercher un contact"
          className="w-full p-inputtext-sm"
          onChange={(e) => handleSearch(e.target.value)}
        />
      </span>
      <div className="flex flew-row">
        <PanelFilter
          emptyLabel={'Tous les types'}
          options={CONSTANT.collaborator.type}
          selectedValue={dashboardCollaboratorContext.type}
          onSelect={setCollaboratorType}
        />
      </div>
      <div className="flex-1 overflow-y-scroll">
        <div className="flex flex-col h-auto pb-20">
          {!collaboratorList?.length && emptyCollaboratorList}
          {collaboratorList?.map((c) => (
            <PanelCollaboratorListItem
              key={c.id}
              collaborator={c}
              selectCollaborator={handleSelectCollaborator}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default DashboardPanelCollaboratorList;

import React, { useState, useEffect } from 'react';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import { useProjectCollaboratorContext } from '../../../../contexts/ProjectCollaboratorContext';
import { privateFetch } from '../../../../utils/apiHelper';
import Section from '../../../../components/Display/Section';
import Data from '../../../../components/Display/Data';
import ProjectStudyDetail from './ProjectStudyDetail';
import { Divider } from 'primereact/divider';
import ManageStudy from './ManageStudy';
import ManageStudyCompany from './ManageStudyCompany';
import { useProjectCompanyContext } from '../../../../contexts/ProjectCompanyContext';
import ManageInvoice from '../Shared/Invoice/ManageInvoice';
import { confirmDialog } from 'primereact/confirmdialog';
import ManageStudyCompanyCollaborators from './ManageStudyCompanyCollaborators';
import { applyCurrencyFormat } from '../../../../utils/stringHelper';

function ProjectStudy({ fetchProjectBudget }) {
  const { navigationContext } = useNavigationContext();
  const { openCollaboratorDetail, fetchProjectCollaboratorList } = useProjectCollaboratorContext();
  const { fetchProjectCompanyList } = useProjectCompanyContext();
  const [studyData, setStudyData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [studyPanelOpen, setStudyPanelOpen] = useState(false);
  const [study, setStudy] = useState(undefined);
  const [invoice, setInvoice] = useState(undefined);
  const [invoicePanelOpen, setInvoicePanelOpen] = useState(false);

  const [studyCompanyPanelOpen, setStudyCompanyPanelOpen] = useState(false);
  const [studyCompanyCollaboratorsPanelOpen, setStudyCompanyCollaboratorsPanelOpen] =
    useState(false);

  useEffect(() => {
    fetchStudyList();
  }, []);

  const fetchStudyList = async () => {
    await privateFetch('GET', `/project/${navigationContext.activeProject.id}/study/`).then(
      (res) => {
        if (res) setStudyData(res);
      }
    );
  };

  const createStudy = async (studyForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/project/${navigationContext.activeProject.id}/study/`,
      `L'étude ${studyForm.name} bien été créée.`,
      JSON.stringify(studyForm)
    ).then(() => {
      setStudyPanelOpen(false);
      setLoading(false);
      fetchStudyList();
      fetchProjectBudget();
    });
  };

  const updateStudy = async (studyForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/project/${navigationContext.activeProject.id}/study/${study.id}/`,
      `L'étude ${studyForm.name} bien été modifiée.`,
      JSON.stringify(studyForm)
    ).then(() => {
      setStudyPanelOpen(false);
      setLoading(false);
      fetchStudyList();
      fetchProjectBudget();
    });
  };

  const deleteStudy = async (targetStudy) => {
    setLoading(true);
    await privateFetch(
      'DELETE',
      `/project/${navigationContext.activeProject.id}/study/${targetStudy.id}/`,
      `L'étude a bien été supprimée`
    ).then(() => {
      setStudyPanelOpen(false);
      setLoading(false);
      setStudy(undefined);
      setInvoice(undefined);
      fetchStudyList();
      fetchProjectBudget();
    });
  };

  const updateStudyCompany = async (studyForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/project/${navigationContext.activeProject.id}/study/${study.id}/company/`,
      `Le prestataire a bien été assigné à l'étude.`,
      JSON.stringify(studyForm)
    ).then(() => {
      setStudyCompanyPanelOpen(false);
      setLoading(false);
      fetchStudyList();
      fetchProjectCompanyList();
    });
  };

  const updateStudyCompanyCollaborators = async (form) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/project/${navigationContext.activeProject.id}/study/${study.id}/collaborator/`,
      `Les contacts associés ont bien été enregistrés. `,
      JSON.stringify(form)
    ).then(() => {
      setStudyCompanyCollaboratorsPanelOpen(false);
      setLoading(false);
      fetchStudyList();
    });
  };

  const createInvoice = async (invoiceForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/project/${navigationContext.activeProject.id}/study/${study.id}/invoice/`,
      `La facture ${invoiceForm.label} a bien été ajoutée à ${study.name}`,
      JSON.stringify(invoiceForm)
    ).then(() => {
      setInvoicePanelOpen(false);
      setLoading(false);
      setStudy(undefined);
      fetchStudyList();
      fetchProjectBudget();
    });
  };

  const updateInvoice = async (invoiceForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/project/${navigationContext.activeProject.id}/invoice/${invoice.id}/`,
      `La facture ${invoiceForm.label} a bien été modifiée`,
      JSON.stringify(invoiceForm)
    ).then(() => {
      setInvoicePanelOpen(false);
      setLoading(false);
      setStudy(undefined);
      setInvoice(undefined);
      fetchStudyList();
      fetchProjectBudget();
    });
  };

  const deleteInvoice = async (targetInvoice) => {
    setLoading(true);
    await privateFetch(
      'DELETE',
      `/project/${navigationContext.activeProject.id}/invoice/${targetInvoice.id}/`,
      `La facture a bien été supprimée`
    ).then(() => {
      setInvoicePanelOpen(false);
      setLoading(false);
      setStudy(undefined);
      setInvoice(undefined);
      fetchStudyList();
      fetchProjectBudget();
    });
  };

  const handleCreateStudy = () => {
    setStudy(undefined);
    setStudyPanelOpen(true);
  };

  const handleUpdateStudy = (targetStudy) => {
    setStudy(targetStudy);
    setStudyPanelOpen(true);
  };

  const handleUpdateStudyCompany = (targetStudy) => {
    setStudy(targetStudy);
    setStudyCompanyPanelOpen(true);
  };

  const handleCreateInvoice = (targetStudy) => {
    setInvoice(undefined);
    setStudy(targetStudy);
    setInvoicePanelOpen(true);
  };

  const handleUpdateInvoice = (targetStudy, targetInvoice) => {
    setInvoice(targetInvoice);
    setStudy(targetStudy);
    setInvoicePanelOpen(true);
  };

  const handleDeleteInvoice = (targetStudy, targetInvoice) => {
    setStudy(targetStudy);
    setInvoice(targetInvoice);
    confirmInvoiceDeletion(targetStudy, targetInvoice);
  };

  const handleUpdateAssociatedCollaborators = (targetStudy) => {
    setStudy(targetStudy);
    setStudyCompanyCollaboratorsPanelOpen(true);
  };

  const confirmInvoiceDeletion = (targetStudy, targetInvoice) => {
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer la facture ${targetInvoice.label} de l'étude ${targetStudy.name} ?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmer la suppression',
      acceptClassName: 'bg-custom-red border-custom-red',
      rejectLabel: 'Annuler',
      accept: () => deleteInvoice(targetInvoice)
    });
  };

  const confirmStudyDeletion = (targetStudy) => {
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer l'étude ${targetStudy.name} ?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmer la suppression',
      acceptClassName: 'bg-custom-red border-custom-red',
      rejectLabel: 'Annuler',
      accept: () => deleteStudy(targetStudy)
    });
  };

  const generalSectionActions = [
    {
      onClick: handleCreateStudy,
      label: 'Ajouter une étude',
      icon: 'pi-plus'
    }
  ];

  return (
    <div className="data-section accordion-tab">
      <Section key="general" label="Général" actions={generalSectionActions}>
        <div className="data-section-row">
          <Data
            key="study_nb"
            label={"Nb d'études"}
            value={studyData?.study_list?.length}
            fontColor="text-slate-800"
          />
          <Data
            key="validated_nb"
            label={'dont terminées'}
            value={studyData?.study_list?.filter((s) => s.state == 'validated').length}
            fontColor="text-custom-green-dark"
          />
          <Data
            key="in_progress_nb"
            label={'dont commandées'}
            value={studyData?.study_list?.filter((s) => s.state == 'in_progress').length}
            fontColor="text-custom-orange"
          />
          <Data
            key="total_expected_budget"
            label={'Budget total estimé'}
            value={applyCurrencyFormat(studyData?.total_expected_budget)}
            suffix={'HT'}
          />
          <Data
            key="total_amount"
            label={'dont facturé'}
            value={applyCurrencyFormat(studyData?.total_amount)}
            suffix={'HT'}
          />
        </div>
      </Section>
      <Divider />
      {studyData?.study_list?.map((s, i) => (
        <div key={i} className="flex flex-col  gap-y-8">
          <ProjectStudyDetail
            key={s.id}
            study={s}
            handleUpdateStudy={() => handleUpdateStudy(s)}
            handleUpdateStudyCompany={() => handleUpdateStudyCompany(s)}
            handleCreateInvoice={() => handleCreateInvoice(s)}
            handleUpdateInvoice={(invoice) => handleUpdateInvoice(s, invoice)}
            handleDeleteInvoice={(invoice) => handleDeleteInvoice(s, invoice)}
            handleUpdateAssociatedCollaborators={() => handleUpdateAssociatedCollaborators(s)}
          />
          {i + 1 != studyData?.study_list.length && <Divider />}
        </div>
      ))}

      <ManageStudy
        loading={loading}
        isOpen={studyPanelOpen}
        study={study}
        handleClose={() => {
          setStudyPanelOpen(false);
        }}
        createStudy={createStudy}
        updateStudy={updateStudy}
        deleteStudy={() => confirmStudyDeletion(study)}
      />

      <ManageStudyCompany
        loading={loading}
        isOpen={studyCompanyPanelOpen}
        handleClose={() => {
          setStudyCompanyPanelOpen(false);
        }}
        updateStudyCompany={updateStudyCompany}
      />

      <ManageStudyCompanyCollaborators
        loading={loading}
        study={study}
        isOpen={studyCompanyCollaboratorsPanelOpen}
        handleClose={() => {
          setStudyCompanyCollaboratorsPanelOpen(false);
        }}
        updateStudyCompanyCollaborators={updateStudyCompanyCollaborators}
      />

      <ManageInvoice
        invoice={invoice}
        loading={loading}
        isOpen={invoicePanelOpen}
        handleClose={() => {
          setInvoicePanelOpen(false);
        }}
        createInvoice={createInvoice}
        updateInvoice={updateInvoice}
      />
    </div>
  );
}

export default ProjectStudy;

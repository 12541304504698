import { Button } from 'primereact/button';
import React from 'react';

function Section({ label, actions, children, containerClassName }) {
  return (
    <div className={`flex flex-col gap-y-3 ${containerClassName}`}>
      <div className="flex flex-row w-full justify-between items-center h-10">
        <h3 className="text-slate-700">{label}</h3>
        <div className="flex flex-row gap-x-3">
          {actions &&
            actions.map((a, i) => (
              <Button
                key={i}
                size="small"
                text
                rounded
                disabled={a.disabled}
                label={a.label}
                iconPos="left"
                icon={`pi ${a.icon}`}
                onClick={a.onClick}
                className={`text-slate-600 hover:text-slate-800 focus:text-slate-800 ${
                  a.disabled && 'opacity-40'
                }`}
              />
            ))}
        </div>
      </div>
      <div className="flex flex-col gap-y-5">{children}</div>
    </div>
  );
}

export default Section;

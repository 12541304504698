import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { CONSTANT } from '../../../../utils/constantHelper';
import FormInput from '../../../../components/Form/FormInput';

function ManageProjectMeasure({ isOpen, handleClose, updateProject, loading, project }) {
  // Form
  const [estimatedPower, setEstimatedPower] = useState(undefined);
  const [estimatedProduction, setEstimatedProduction] = useState(undefined);
  const [estimatedSurface, setEstimatedSurface] = useState(undefined);
  const [parcelNb, setParcelNb] = useState(undefined);
  const [distance, setDistance] = useState(undefined);
  const [structure, setStructure] = useState(undefined);

  useEffect(() => {
    setEstimatedPower(project.estimate_power);
    setEstimatedProduction(project.estimated_production);
    setEstimatedSurface(project.estimated_surface);
    setParcelNb(project.parcel_nb);
    setDistance(project.distance);
    setStructure(project.pv_structure);
  }, [isOpen]);

  const onValidate = () => {
    const projectForm = {
      estimate_power: estimatedPower,
      estimated_production: estimatedProduction,
      estimated_surface: estimatedSurface,
      parcel_nb: parcelNb,
      distance,
      pv_structure: structure
    };
    updateProject(projectForm);
  };

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={'Valider les modifications'}
        loading={loading}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 530, paddingLeft: 10, paddingRight: 10 }}
      header={<h2 className="text-slate-800">{`Informations site`}</h2>}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="manager-form-wrapper">
          <FormInput label={'Puissance estimée (MWc)'}>
            <InputNumber
              value={estimatedPower}
              placeholder="Veuillez saisir la puissance estimée"
              onChange={(e) => setEstimatedPower(e.value)}
              min={0}
              suffix=" MWc"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={'Production estimée (MWh)'}>
            <InputNumber
              value={estimatedProduction}
              placeholder="Veuillez saisir la production estimée"
              onChange={(e) => setEstimatedProduction(e.value)}
              min={0}
              suffix=" MWh"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={'Distance raccordement (km)'}>
            <InputNumber
              value={distance}
              placeholder="Veuillez saisir la distance de raccordement"
              onChange={(e) => setDistance(e.value)}
              min={0}
              suffix=" km"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={'Foncier nb de parcelles'}>
            <InputNumber
              value={parcelNb}
              placeholder="Veuillez saisir le nombre de parcelles"
              onChange={(e) => setParcelNb(e.value)}
              min={0}
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={'Surface (m²)'}>
            <InputNumber
              value={estimatedSurface}
              placeholder="Veuillez saisir la surface"
              onChange={(e) => setEstimatedSurface(e.value)}
              min={0}
              suffix={' m²'}
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={'Structure PV'}>
            <Dropdown
              value={structure}
              onChange={(e) => setStructure(e.value)}
              options={CONSTANT.project.structure}
              optionLabel="label"
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageProjectMeasure;

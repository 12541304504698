import React, { useState, useEffect } from 'react';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import { privateFetch } from '../../../../utils/apiHelper';
import Section from '../../../../components/Display/Section';
import Data from '../../../../components/Display/Data';
import ProjectOwnerDatatable from './ProjectOwnerDatatable';
import { useProjectCollaboratorContext } from '../../../../contexts/ProjectCollaboratorContext';
import ManageOwnership from './ManageOwnership';
import { confirmDialog } from 'primereact/confirmdialog';
function ProjectSecurisation({ project }) {
  const { navigationContext } = useNavigationContext();
  const { projectCollaboratorContext, openCollaboratorDetail, handleCreateProjectCollaborator } =
    useProjectCollaboratorContext();

  const [securisationDetail, setSecurisationDetail] = useState(undefined);
  const [ownerList, setOwnerList] = useState([]);
  const [defaultOwnershipValues, setDefaultOwnershipValues] = useState(undefined);
  const [ownershipPanelOpen, setOwnershipPanelOpen] = useState(false);
  const [ownership, setOwnership] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchProjectOwnerList();
    fetchProjectSecurisationDetail();
  }, [projectCollaboratorContext]);

  const fetchProjectSecurisationDetail = async () => {
    await privateFetch('GET', `/project/${navigationContext.activeProject.id}/securisation/`).then(
      (res) => {
        if (res) setSecurisationDetail(res);
      }
    );
  };

  const fetchProjectOwnerList = async () => {
    await privateFetch(
      'GET',
      `/project/${navigationContext.activeProject.id}/securisation/owner/`
    ).then((res) => {
      if (res) setOwnerList(res);
    });
  };

  const createOwnership = async (ownershipForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/project/${navigationContext.activeProject.id}/securisation/owner/${ownershipForm.owner}/ownership/`,
      `La parcelle ${ownershipForm.parcel.section} ${ownershipForm.parcel.number} bien été ajoutée.`,
      JSON.stringify(ownershipForm)
    ).then(() => {
      setOwnershipPanelOpen(false);
      setLoading(false);
      fetchProjectSecurisationDetail();
      fetchProjectOwnerList();
    });
  };

  const updateOwnership = async (ownershipForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/project/${navigationContext.activeProject.id}/securisation/owner/${ownership.owner.id}/ownership/${ownership.id}/`,
      `La parcelle ${ownership.parcel.section} ${ownership.parcel.number} bien été modifiée.`,
      JSON.stringify(ownershipForm)
    ).then(() => {
      setOwnershipPanelOpen(false);
      setLoading(false);
      fetchProjectSecurisationDetail();
      fetchProjectOwnerList();
    });
  };

  const deleteParcel = async () => {
    setLoading(true);
    await privateFetch(
      'DELETE',
      `/project/${navigationContext.activeProject.id}/securisation/parcel/${ownership.parcel.id}/`,
      `Le parcelle ${ownership.parcel.section} ${ownership.parcel.number} a bien été supprimé.`
    ).then(() => {
      setLoading(false);
      fetchProjectSecurisationDetail();
      fetchProjectOwnerList();
    });
  };

  const handleCreateOwner = () => {
    handleCreateProjectCollaborator(false, 'parcel_owner');
  };

  const handleCreateOwnership = (targetOwner) => {
    setDefaultOwnershipValues({
      owner: targetOwner
    });
    setOwnership(undefined);
    setOwnershipPanelOpen(true);
  };

  const handleUpdateOwnership = (targetOwnership) => {
    setOwnership(targetOwnership);
    setOwnershipPanelOpen(true);
  };

  const handleDuplicateOwnership = (targetOwnership) => {
    setOwnership(undefined);
    setDefaultOwnershipValues({
      owner: targetOwnership.owner,
      parcel: targetOwnership.parcel,
      state: targetOwnership.state
    });
    setOwnershipPanelOpen(true);
  };

  const confirmParcelDeletion = () => {
    setOwnershipPanelOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer la parcelle ${ownership.parcel.section}${ownership.parcel.number} ? \n\nLes liaisons avec les différents propriétaires seront également supprimées.`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => deleteParcel(),
      acceptClassName: 'p-button-danger'
    });
  };

  const generalSectionActions = [
    {
      onClick: handleCreateOwner,
      label: 'Propriétaire',
      icon: 'pi-plus'
    }
  ];

  return (
    <div className="data-section accordion-tab">
      <Section key="general" label="Général" actions={generalSectionActions}>
        <div className="data-section-row">
          <Data key="owner_nb" label={'Nb de propriétaires'} value={securisationDetail?.owner_nb} />
          <Data
            key="agreement_nb"
            label={"Nb d'accords"}
            value={securisationDetail?.total_agreement_nb}
            fontColor="text-slate-800"
          />
          <Data
            key="signed_nb"
            label={'dont signés'}
            value={securisationDetail?.signed_agreement_nb}
            fontColor="text-custom-green"
          />
          <Data
            key="held_nb"
            label={'dont en attente'}
            value={securisationDetail?.held_agreement_nb}
            fontColor="text-custom-orange"
          />
          <Data
            key="refused_nb"
            label={'dont refusés'}
            value={securisationDetail?.refused_agreement_nb}
            fontColor="text-custom-red"
          />
        </div>
        <div className="data-section-row">
          <Data key="parcel_nb" label={'Nb de parcelles'} value={securisationDetail?.parcel_nb} />
          <Data
            key="critical_nb"
            label={'dont critiques'}
            value={securisationDetail?.critical_parcel_nb}
          />
        </div>
      </Section>

      {ownerList.map((o) => (
        <ProjectOwnerDatatable
          key={o.id}
          owner={o}
          handleCreateOwnership={() => handleCreateOwnership(o)}
          handleUpdateOwnership={(targetOwnership) => handleUpdateOwnership(targetOwnership)}
          handleDuplicateOwnership={(targetOwnership) => handleDuplicateOwnership(targetOwnership)}
          openOwnerDetail={() => openCollaboratorDetail(o)}
        />
      ))}

      <ManageOwnership
        loading={loading}
        project={project}
        isOpen={ownershipPanelOpen}
        defaultValues={defaultOwnershipValues}
        ownership={ownership}
        ownerList={ownerList}
        handleClose={() => {
          setOwnershipPanelOpen(false);
        }}
        createOwnership={createOwnership}
        updateOwnership={updateOwnership}
        deleteParcel={confirmParcelDeletion}
      />
    </div>
  );
}

export default ProjectSecurisation;

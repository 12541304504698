import React from 'react';
import CustomIcon from '../CustomIcon';
import { classNames } from 'primereact/utils';

function Pill({ pill, outlined, onClick }) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'py-1 px-2 rounded-full flex flex-row items-center gap-x-2 text-xs font-semibold w-fit overflow-hidden',
        {
          'border border-slate-400 text-slate-600': outlined,
          'bg-slate-600 text-slate-50': !outlined,
          'cursor-pointer': onClick
        }
      )}>
      {pill.icon && <CustomIcon icon={pill.icon} className="h-4 w-4 p-0.5 min-w-[16px]" />}
      <p className="whitespace-nowrap text-ellipsis overflow-hidden"> {pill.label}</p>
    </div>
  );
}

export default Pill;

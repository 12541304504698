import React from 'react';
import Section from '../../../components/Display/Section';
import PanelEventListItem from '../../../components/Panel/Project/Event/PanelEventListItem';
import { useProjectEventContext } from '../../../contexts/ProjectEventContext';

function ProjectLastEvent({ handleOpenInTab }) {
  const { lastEventContext } = useProjectEventContext();
  const eventActions = [
    {
      label: 'Voir tout',
      icon: 'pi-arrow-up-right',
      onClick: handleOpenInTab
    }
  ];
  return (
    <Section label="Dernière décision" actions={eventActions}>
      {lastEventContext ? (
        <div className="relative w-full">
          <PanelEventListItem event={lastEventContext} />
        </div>
      ) : (
        <div className="h-[400px] w-full flex items-center justify-center italic text-slate-400 border border-slate-200 rounded">
          <span>Pas de décision disponible</span>
        </div>
      )}
    </Section>
  );
}

export default ProjectLastEvent;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import { privateFetch } from '../../../../utils/apiHelper';
import PanelMeetingList from './PanelMeetingList';
import ManageMeeting from './ManageMeeting';
import { useProjectMeetingContext } from '../../../../contexts/ProjectMeetingContext';
import { useDebouncedCallback } from 'use-debounce';

function PanelMeeting({ isActive }) {
  const { navigationContext, toggleProjectPanel } = useNavigationContext();
  const {
    projectMeetingContext,
    fetchProjectMeetingList,
    setProjectMeetingQuery,
    fetchProjectAllMeeting
  } = useProjectMeetingContext();

  const [meeting, setMeeting] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [meetingManagerIsOpen, setMeetingManagerIsOpen] = useState(false);

  useEffect(() => {
    fetchProjectMeetingList();
    fetchProjectAllMeeting();
  }, [navigationContext.activeProject?.id]);

  const createMeeting = async (meetingForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/project/${navigationContext.activeProject.id}/meeting/`,
      `Le meeting a bien été créé.`,
      JSON.stringify(meetingForm)
    ).then(() => {
      setLoading(false);
      setMeetingManagerIsOpen(false);
      fetchProjectMeetingList();
      fetchProjectAllMeeting();
    });
  };

  const debouncedQuery = useDebouncedCallback((query) => {
    setProjectMeetingQuery(query);
  }, 500);

  return (
    <div
      className={`h-full w-[450px] bg-white flex flex-col border-l py-3 px-5 gap-y-3 ${
        isActive ? '' : 'hidden'
      }`}>
      <div className="flex flex-row w-full justify-between items-center">
        <h3 className="text-2xl text-slate-900 font-semibold">{'Concertations'}</h3>
        <div className="flex flex-row gap-x-1">
          <Button
            key="add"
            text
            rounded
            icon={'pi pi-plus'}
            onClick={() => {
              setMeeting(undefined);
              setMeetingManagerIsOpen(true);
            }}
          />
          <Button
            key="close"
            text
            rounded
            icon={'pi pi-times'}
            onClick={() => {
              toggleProjectPanel(undefined);
            }}
          />
        </div>
      </div>

      <div className="panel-content-list-container">
        <PanelMeetingList
          meetingList={projectMeetingContext?.meetings}
          handleSearch={(e) => debouncedQuery(e)}
        />
      </div>

      <ManageMeeting
        loading={loading}
        isOpen={meetingManagerIsOpen}
        meeting={meeting}
        handleClose={() => {
          setMeetingManagerIsOpen(false);
        }}
        createMeeting={createMeeting}
      />
    </div>
  );
}

export default PanelMeeting;

import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useMemo } from 'react';

function DashboardSearch({ value, handleSearch }) {
  const isActive = useMemo(() => value && value.length, [value]);

  return (
    <span className={`p-input-icon-left`}>
      <i className={`pi pi-search ml-1`} />
      <InputText
        placeholder="Nom d'un projet"
        className={`p-inputtext-sm rounded-full ${
          isActive ? 'active-custom-dropdown w-[300px]' : 'w-[200px]'
        }`}
        onChange={(e) => handleSearch(e.target.value)}
        value={value}
      />
    </span>
  );
}

export default DashboardSearch;

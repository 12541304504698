import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { CONSTANT } from '../../../../utils/constantHelper';
import FormInput from '../../../../components/Form/FormInput';
import { privateFetch } from '../../../../utils/apiHelper';
import { AutoComplete } from 'primereact/autocomplete';

function ManageProjectInformation({ isOpen, handleClose, updateProject, loading, project }) {
  // Form
  const [city, setCity] = useState(undefined);
  const [comcom, setComcom] = useState('');
  const [urbanism, setUrbanism] = useState('');
  const [origin, setOrigin] = useState(undefined);

  // Autocomplete
  const [filteredCities, setFilteredCities] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    setCity(project.city);
    setQuery(`${project.city?.name}, France`);
    setOrigin(project.origin);
    setUrbanism(project.urbanism);
    setComcom(project.comcom);
  }, [isOpen]);

  const fetchCityList = async (q) => {
    await privateFetch('GET', `/city/autocomplete/?query=${q}`).then((res) => {
      setFilteredCities(res);
    });
  };

  const onValidate = () => {
    const projectForm = {
      comcom,
      origin,
      urbanism,
      city: city.place_id
    };
    updateProject(projectForm);
  };

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={'Valider les modifications'}
        loading={loading}
        onClick={onValidate}
        severity="success"
        disabled={!city}
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 530, paddingLeft: 10, paddingRight: 10 }}
      header={<h2 className="text-slate-800">{`Informations site`}</h2>}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="manager-form-wrapper">
          <FormInput label={'Commune'}>
            <AutoComplete
              field="name"
              forceSelection
              value={query}
              suggestions={filteredCities}
              completeMethod={(e) => fetchCityList(e.query)}
              onChange={(e) => {
                setQuery(e.value);
                setCity(undefined);
              }}
              onSelect={(e) => setCity(e.value)}
              placeholder="Veuillez sélectionner une ville"
            />
          </FormInput>

          <FormInput key="comcom" label="Communauté de commmune">
            <InputText
              value={comcom}
              placeholder="Veuillez saisir le nom de la comcom"
              onChange={(e) => setComcom(e.target.value)}
            />
          </FormInput>

          <FormInput key="urbanism" label="Urbanisme">
            <InputText
              value={urbanism}
              placeholder="Veuillez saisir l'urbanisme"
              onChange={(e) => setUrbanism(e.target.value)}
            />
          </FormInput>

          <FormInput key="origin" label={'Origine du projet'}>
            <Dropdown
              value={origin}
              onChange={(e) => setOrigin(e.value)}
              options={CONSTANT.project.origin}
              optionLabel="label"
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageProjectInformation;

// PrimeReact
import { PrimeReactProvider, addLocale, locale } from 'primereact/api';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { HashRouter } from 'react-router-dom';

import AppContexts from './contexts/Contexts';
import Router from './Router';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-fullscreen/styles.css';
import './assets/theme/theme.css';
import './App.scss';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { localeJson } from './assets/theme/fr';

addLocale('fr', localeJson.fr);
locale('fr');

function App() {
  return (
    <PrimeReactProvider>
      <AppContexts>
        <HashRouter>
          <ConfirmDialog style={{ width: '20vw' }} />
          <Router />
        </HashRouter>
      </AppContexts>
    </PrimeReactProvider>
  );
}

export default App;

import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { applyCurrencyFormat, formatDate } from '../../../../../utils/stringHelper';

function InvoiceDatatable({ invoiceList, handleDelete, handleUpdate }) {
  const actionBodyTemplate = (rowData) => (
    <div className="flex flex-row gap-x-3">
      <Button
        onClick={() => handleUpdate(rowData)}
        icon="pi pi-pencil text-xs"
        className="opacity-100 hover:bg-slate-100"
        severity={'secondary'}
        rounded
        outlined
      />
      <Button
        onClick={() => handleDelete(rowData)}
        icon="pi pi-trash text-xs"
        className="opacity-100 hover:bg-red-100"
        severity={'danger'}
        rounded
        outlined
      />
    </div>
  );

  return (
    <DataTable
      value={invoiceList}
      emptyMessage={'Pas de facture à afficher'}
      size="small"
      removableSort>
      <Column className="font-bold" field="label" header="Numéro"></Column>
      <Column
        sortable
        field="parcel.surface"
        header="Montant"
        body={(rowData) => applyCurrencyFormat(rowData.amount)}></Column>
      <Column
        sortable
        field="date"
        header="Date"
        body={(rowData) => formatDate(rowData.date)}></Column>
      <Column
        field="description"
        header="Description"
        className="max-w-[500px] whitespace-normal"></Column>
      <Column frozen className="w-24" body={actionBodyTemplate}></Column>
    </DataTable>
  );
}

export default InvoiceDatatable;

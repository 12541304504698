import React, { useState, useEffect } from 'react';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import { privateFetch } from '../../../../utils/apiHelper';
import PanelCollaboratorList from './DashboardPanelCollaboratorList';
import { Button } from 'primereact/button';
import { useDebouncedCallback } from 'use-debounce';
import { useDashboardCollaboratorContext } from '../../../../contexts/DashboardCollaboratorContext';
import PanelCollaboratorDetail from '../../Common/Collaborator/PanelCollaboratorDetail';
import { useDashboardCompanyContext } from '../../../../contexts/DashboardCompanyContext';
// import ManageCollaborator from '../../Common/Collaborator/ManageCollaborator';

function DashboardPanelCollaborator({ isActive }) {
  const { toggleDashboardPanel } = useNavigationContext();
  const {
    dashboardCollaboratorContext,
    fetchCollaboratorDetail,
    setCollaboratorQuery,
    resetCollaboratorDetail,
    handleCreateCollaborator,
    handleUpdateCollaborator
  } = useDashboardCollaboratorContext();
  const { openDashboardCompanyDetail } = useDashboardCompanyContext();

  const debouncedQuery = useDebouncedCallback((query) => {
    setCollaboratorQuery(query);
  }, 500);

  return (
    <div
      className={`h-full w-[450px] bg-white flex flex-col border-l py-3 px-5 gap-y-3 ${
        isActive ? '' : 'hidden'
      }`}>
      <div className="flex flex-row w-full justify-between items-center">
        <h3 className="text-2xl text-slate-900 font-semibold">{'Annuaire'}</h3>
        <div className="flex flex-row gap-x-1">
          {dashboardCollaboratorContext.collaborator ? (
            <Button
              key="back"
              size="small"
              icon={'pi pi-arrow-left'}
              rounded
              text
              onClick={resetCollaboratorDetail}
            />
          ) : (
            <Button
              key="add"
              text
              rounded
              iconPos="left"
              icon={'pi pi-plus'}
              onClick={() => handleCreateCollaborator(false, 'other')}
            />
          )}
          <Button
            key="close"
            text
            rounded
            icon={'pi pi-times'}
            onClick={() => {
              toggleDashboardPanel(undefined);
            }}
          />
        </div>
      </div>

      {dashboardCollaboratorContext.collaborator ? (
        <div className="panel-content-detail-container">
          <PanelCollaboratorDetail
            collaborator={dashboardCollaboratorContext.collaborator}
            handleCollaboratorUpdate={() => handleUpdateCollaborator(false)}
            selectCompany={openDashboardCompanyDetail}
          />
        </div>
      ) : (
        <div className="panel-content-list-container">
          <PanelCollaboratorList
            collaboratorList={dashboardCollaboratorContext.collaborators}
            handleSelectCollaborator={(collaborator) => fetchCollaboratorDetail(collaborator)}
            handleSearch={debouncedQuery}
          />
        </div>
      )}
    </div>
  );
}

export default DashboardPanelCollaborator;

import React from 'react';
import PanelCompanyListItem from '../../Common/Company/PanelCompanyListItem';
import { InputText } from 'primereact/inputtext';
import { useProjectCompanyContext } from '../../../../contexts/ProjectCompanyContext';
import PanelFilter from '../../PanelFilter';
import { CONSTANT } from '../../../../utils/constantHelper';

function PanelCompanyList({ handleSearch, handleSelectCompany, companyList }) {
  const { projectCompanyContext, setProjectCompanyType } = useProjectCompanyContext();

  const emptyCompanyList = (
    <div className="h-[calc(100vh-400px)] w-full flex items-center justify-center italic text-slate-400">
      <span>Aucune entreprise à afficher</span>
    </div>
  );

  return (
    <div className="flex flex-col gap-y-3 h-full">
      <span className={`p-input-icon-left w-full`}>
        <i className={`pi pi-search`} />
        <InputText
          defaultValue={projectCompanyContext.query}
          placeholder="Rechercher une entreprise"
          className="w-full p-inputtext-sm"
          onChange={(e) => handleSearch(e.target.value)}
        />
      </span>
      <div className="flex flew-row">
        <PanelFilter
          emptyLabel={'Tous les types'}
          options={CONSTANT.company.type}
          selectedValue={projectCompanyContext.type}
          onSelect={setProjectCompanyType}
          showFilter
        />
      </div>
      <div className="flex-1 overflow-y-scroll">
        <div className="flex flex-col h-auto pb-20">
          {!companyList.length && emptyCompanyList}
          {companyList.map((c) => (
            <PanelCompanyListItem key={c.id} company={c} selectCompany={handleSelectCompany} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PanelCompanyList;

import moment from 'moment';
import { getSession, saveSession } from './sessionHelper';

const BASE_URL = '/';

export async function publicFetch(method, url, errorMessage = null, body = null) {
  const targetUrl = `${BASE_URL}api/v1/public${url}`;
  return await fetch(targetUrl, {
    method: method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  })
    .then(async (response) => {
      if (response.status === 200) {
        return await response.json();
      } else if (errorMessage) {
        window.PrimeToast.show({
          severity: 'warn',
          summary: errorMessage
        });
        return null;
      } else {
        const body = await response.json();
        window.PrimeToast.show({
          severity: 'error',
          summary: `Une erreur est survenue, veuillez réessayer. Requête : ${method} ${targetUrl} | Erreur HTTP ${
            response.status
          } : ${JSON.stringify(body)}`
        });
        return null;
      }
    })
    .catch((error) => {
      console.log(`[Error - publicFetch] ${method} - ${targetUrl}`);
      console.log(`[Error - publicFetch] ${error}`);
      console.log(error.toString());
      return null;
    });
}

export async function privateFetch(
  method,
  url,
  succesMessage = null,
  body = null,
  rawResponse = false,
  isDocument = false
) {
  // Session desn't exist
  if (!getSession()) {
    console.log("Session doesn't exist.");
    return null;
  }
  const targetUrl = `${BASE_URL}api/v1/private/cu/${getSession().id}${url}`;
  console.log(`${method} - ${targetUrl}`);
  // Expired token
  if (moment(getSession().access_exp_dt).isBefore(moment())) {
    console.log('Access token is expired, refreshing...');
    if (!(await refreshAccesToken())) {
      console.log('Refresh token is expired, aborting.');
      return false;
    }
  }

  // Header construction
  let headers = { Authorization: `Bearer ${getSession().access}` };
  if (!isDocument) headers['Content-Type'] = 'application/json';

  // API call
  return await fetch(targetUrl, {
    method: method,
    headers: headers,
    body: body
  })
    .then(async (response) => {
      if (response.status === 200) {
        succesMessage &&
          window.PrimeToast.show({
            severity: 'success',
            summary: succesMessage
          });

        if (rawResponse) return response;
        return await response.json();
      } else {
        console.log(`[Error - privateFetch] API responded with status code ${response.status}`);
        const body = await response.json();
        window.PrimeToast.show({
          severity: 'error',
          summary: `Une erreur est survenue, veuillez réessayer. Requête : ${method} ${targetUrl} | Erreur HTTP ${
            response.status
          } : ${JSON.stringify(body)}`
        });
        return null;
      }
    })
    .catch((error) => {
      console.log(`[Error - privateFetch] ${method} - ${targetUrl}`);
      console.log(`[Error - privateFetch] ${error}`);
      console.log(error.toString());
      window.PrimeToast.show({
        severity: 'error',
        summary: `Une erreur est survenue, veuillez réessayer. Requête : ${method} ${targetUrl} | Erreur : ${error}`
      });
      return null;
    });
}

export async function refreshAccesToken() {
  const url = `${BASE_URL}api/v1/public/user/token/refresh/`;
  const session = getSession();
  if (!session) return null;
  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      refresh: session.refresh
    })
  })
    .then(async (response) => {
      if (response.status === 200) {
        const data = await response.json();
        saveSession({
          ...session,
          access: data.access,
          access_exp_dt: data.access_exp_dt
        });
        return true;
      } else {
        console.log(`Refresh Token is outdated. Removing session from localStorage.`);
        saveSession(null);
        return null;
      }
    })
    .catch((error) => {
      console.log(`[Error - refreshToken] POST - ${url}`);
      console.log(`[Error - refreshToken] ${error}`);
      console.log(error.toString());
      return null;
    });
}

import React from 'react';
import { getConstant } from '../../../../utils/constantHelper';
import Data from '../../../Display/Data';
import { Button } from 'primereact/button';
import CustomIcon from '../../../CustomIcon';
import PanelCollaboratorListItem from '../Collaborator/PanelCollaboratorListItem';

function PanelCompanyDetail({
  company,
  handleCompanyUpdate,
  selectCollaborator,
  createCollaborator
}) {
  const newContactButton = (
    <div
      className="w-full flex flex-row gap-x-3 p-3 border-b last:border-b-0 items-center cursor-pointer hover:bg-slate-200 text-slate-600 hover:text-slate-800"
      onClick={createCollaborator}>
      <Button
        key="new"
        rounded
        outlined
        icon={'pi pi-plus'}
        size="large"
        className="h-12 w-12 text-inherit"
      />
      <span className="font-medium ">{'Nouveau contact associé'}</span>
    </div>
  );

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="w-full flex flex-col border-t pt-4 gap-y-5 h-full overflow-hidden">
        <div className="flex flex-row w-full justify-between pr-1">
          <div className="flex flex-row gap-x-3">
            <CustomIcon icon={'companyPh'} className="h-12 w-12 min-w-[48px]" />
            <div className="flex flex-col justify-center text-slate-800">
              <span className="font-semibold text-slate-800">{company.name}</span>
              <span className="text-sm text-slate-600">
                {getConstant('company.type', company.type).label}
              </span>
            </div>
          </div>
          <Button
            key="edit"
            text
            rounded
            iconPos="left"
            icon={'pi pi-pencil'}
            onClick={handleCompanyUpdate}
          />
        </div>
        <div className="flex flex-col px-1 gap-y-5">
          <Data
            key="location"
            label={'Adresse'}
            value={company.location}
            large
            bgColor={'bg-white'}
          />
          {company.second_location && (
            <Data
              key="location"
              label={'Seconde Adresse'}
              value={company.second_location}
              large
              bgColor={'bg-white'}
            />
          )}
          {company.third_location && (
            <Data
              key="location"
              label={'Troisième Adresse'}
              value={company.third_location}
              large
              bgColor={'bg-white'}
            />
          )}
          {company.fourth_location && (
            <Data
              key="location"
              label={'Quatrième Adresse'}
              value={company.fourth_location}
              large
              bgColor={'bg-white'}
            />
          )}
          <Data key="siret" label={'SIRET'} value={company.siret} large bgColor={'bg-white'} />
          <Data key="iban" label={'IBAN'} value={company.iban} large bgColor={'bg-white'} />
        </div>
        <div className="flex flex-col overflow-scroll">
          <h5 className="text-slate-600 mb-2">Contacts associés</h5>
          <div className="overflow-y-scroll">
            <div className="flex flex-col h-auto pb-20">
              {company.collaborators.map((c) => (
                <PanelCollaboratorListItem
                  key={c.id}
                  collaborator={c}
                  selectCollaborator={() => selectCollaborator(c)}
                />
              ))}
              {newContactButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PanelCompanyDetail;

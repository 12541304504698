import React, { useState, useEffect, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import FormInput from '../../../../components/Form/FormInput';
import { AutoComplete } from 'primereact/autocomplete';
import { useCompanyContext } from '../../../../contexts/CompanyContext';
import { privateFetch } from '../../../../utils/apiHelper';

function ManageStudyCompany({ isOpen, handleClose, updateStudyCompany, loading }) {
  const autocompleteRef = useRef(null);
  const { companyContext, handleCreateCompany } = useCompanyContext();

  // Autocomplete
  const [query, setQuery] = useState('');
  const [filteredCompanyList, setFilteredCompanyList] = useState([]);

  const [company, setCompany] = useState(undefined);

  useEffect(() => {
    // Autocomplete
    setQuery('');
    setCompany(undefined);
  }, [isOpen]);

  useEffect(() => {
    fetchCompanyList(query);
    autocompleteRef.current?.show();
  }, [companyContext.companies]);

  const fetchCompanyList = async (q = '') => {
    await privateFetch('GET', `/company/consultant/?q=${q}`).then((res) => {
      setFilteredCompanyList(res);
    });
  };

  const onValidate = () => {
    const studyForm = {
      company: company.id
    };
    updateStudyCompany(studyForm);
  };

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={`Valider l'assignation`}
        loading={loading}
        disabled={!company}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  const handleSelect = (company) => {
    if (!company.id) {
      handleCreateCompany(true, 'consultant');
      setQuery(query);
    } else {
      setCompany(company);
    }
  };

  const investorTemplate = (item) => {
    if (!item.id) {
      return (
        <div className="flex flex-row gap-x-3">
          <i className="pi pi-user-plus"></i>
          <span className="autocomplete-new-item">Ajouter une nouvelle entreprise</span>
        </div>
      );
    } else {
      return <span>{item.name}</span>;
    }
  };

  return (
    <Sidebar
      style={{ width: 530, paddingLeft: 10, paddingRight: 10 }}
      header={<h2 className="text-slate-800">{`Ajouter un prestataire`}</h2>}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="manager-form-wrapper">
          <FormInput label={'Nom du prestataire'} required isValid={company}>
            <AutoComplete
              ref={autocompleteRef}
              inputClassName="autocomplete-input"
              field="name"
              value={query}
              suggestions={[...filteredCompanyList, { id: 0 }]}
              completeMethod={(e) => fetchCompanyList(e.query)}
              onChange={(e) => {
                setQuery(e.value);
                setCompany(undefined);
              }}
              itemTemplate={investorTemplate}
              onSelect={(e) => handleSelect(e.value)}
              dropdown
              placeholder="Veuillez saisir le nom de l'entreprise"
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageStudyCompany;

import React, { createContext, useContext, useEffect, useState } from 'react';
import { privateFetch } from '../utils/apiHelper';
import { useNavigationContext } from './NavigationContext';
import ManageCompany from '../pages/private/Common/ManageCompany';

const ProjectCompanyContext = createContext(null);

const initial = {
  query: '',
  type: '',
  companies: [],
  company: undefined
};

export const ProjectCompanyContextProvider = ({ children }) => {
  const { navigationContext, toggleProjectPanel } = useNavigationContext();
  const [projectCompanyContext, setProjectCompanyContext] = useState(initial);

  // Manager Controls
  const [projectCompanyManagerVisible, setProjectCompanyManagerVisible] = useState(false);
  const [projectCompanyMandatoryType, setProjectCompanyMandatoryType] = useState(undefined);
  const [projectManagerAsDialog, setProjectManagerAsDialog] = useState(false);

  useEffect(() => {
    if (navigationContext.activeProject?.id) {
      fetchProjectCompanyList();
    }
  }, [
    projectCompanyContext.query,
    projectCompanyContext.type,
    navigationContext.activeProject?.id
  ]);

  const setProjectCompanyQuery = (query) => {
    setProjectCompanyContext({
      ...projectCompanyContext,
      query
    });
  };

  const setProjectCompanyType = (type) => {
    setProjectCompanyContext({
      ...projectCompanyContext,
      type
    });
  };

  const resetProjectCompanyDetail = () => {
    setProjectCompanyContext({
      ...projectCompanyContext,
      company: undefined
    });
  };

  const openProjectCompanyDetail = (projectCompany) => {
    if (navigationContext.activeProjectPanel !== 'company') toggleProjectPanel('company');
    fetchProjectCompanyDetail(projectCompany);
  };

  const fetchProjectCompanyList = async () => {
    await privateFetch(
      'GET',
      `/project/${navigationContext.activeProject.id}/company/?q=${projectCompanyContext.query}&t=${
        projectCompanyContext.type || ''
      }`
    ).then((res) => {
      if (res) {
        setProjectCompanyContext({
          ...projectCompanyContext,
          companies: res
        });
      }
    });
  };

  const fetchProjectCompanyDetail = async (targetProjectCompany) => {
    await privateFetch('GET', `/company/${targetProjectCompany.id}/`).then((res) => {
      if (res) {
        setProjectCompanyContext({
          ...projectCompanyContext,
          company: res
        });
      }
    });
  };

  const createProjectCompany = async (companyForm) => {
    await privateFetch(
      'POST',
      `/project/${navigationContext.activeProject.id}/company/`,
      `L'entreprise ${companyForm.name} a bien été créé.`,
      JSON.stringify(companyForm)
    ).then((res) => {
      setProjectCompanyManagerVisible(false);
      fetchProjectCompanyList();
    });
  };

  const updateProjectCompany = async (companyForm) => {
    await privateFetch(
      'PUT',
      `/company/${projectCompanyContext.company.id}/`,
      `L'entreprise ${companyForm.name} a bien été modifiée.`,
      JSON.stringify(companyForm)
    ).then((res) => {
      setProjectCompanyManagerVisible(false);
      fetchProjectCompanyList();
      setTimeout(() => {
        fetchProjectCompanyDetail(projectCompanyContext.company);
      }, 500);
    });
  };

  const handleCreateProjectCompany = (asDialog = false, mandatoryType = undefined) => {
    setProjectManagerAsDialog(asDialog);
    setProjectCompanyManagerVisible(true);
    setProjectCompanyMandatoryType(mandatoryType);
  };

  const handleUpdateCompany = (asDialog = false) => {
    setProjectManagerAsDialog(asDialog);
    setProjectCompanyManagerVisible(true);
  };

  return (
    <ProjectCompanyContext.Provider
      value={{
        projectCompanyContext,
        setProjectCompanyContext,
        fetchProjectCompanyList,
        fetchProjectCompanyDetail,
        setProjectCompanyQuery,
        setProjectCompanyType,
        resetProjectCompanyDetail,
        openProjectCompanyDetail,
        handleCreateProjectCompany,
        handleUpdateCompany
      }}>
      {children}

      <ManageCompany
        isOpen={projectCompanyManagerVisible}
        company={projectCompanyContext.company}
        asDialog={projectManagerAsDialog}
        mandatoryType={projectCompanyMandatoryType}
        handleCreateCompany={createProjectCompany}
        handleUpdateCompany={updateProjectCompany}
        handleClose={() => {
          setProjectCompanyManagerVisible(false);
          setProjectCompanyMandatoryType(undefined);
        }}
      />
    </ProjectCompanyContext.Provider>
  );
};

export const useProjectCompanyContext = () => {
  return useContext(ProjectCompanyContext);
};

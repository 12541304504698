import React, { useState, useEffect, useMemo } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import FormInput from '../../../components/Form/FormInput';
import { CONSTANT, getConstant } from '../../../utils/constantHelper';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { privateFetch } from '../../../utils/apiHelper';
import { getDateStringOrUndefined, validateEmail } from '../../../utils/stringHelper';
import { Message } from 'primereact/message';
import { Dialog } from 'primereact/dialog';
import Tag from '../../../components/Display/Tag';
import { AutoComplete } from 'primereact/autocomplete';

function ManageCollaborator({
  isOpen,
  handleClose,
  asDialog,
  collaborator,
  createCollaborator,
  updateCollaborator,
  loading,
  mandatoryType,
  mandatoryCompany
}) {
  // Form
  const [firstName, setFirstName] = useState('');
  const [name, setName] = useState('');
  const [type, setType] = useState(undefined);
  const [subtype, setSubtype] = useState(undefined);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [gender, setGender] = useState(undefined);
  const [partnerName, setPartnerName] = useState('');
  const [birthDate, setBirthDate] = useState(undefined);
  const [informations, setInformations] = useState('');
  const [company, setCompany] = useState(undefined);
  const [city, setCity] = useState(undefined);
  const [birthPlace, setBirthPlace] = useState(undefined);

  // Data
  const [companyList, setCompanyList] = useState([]);

  // Autocomplete
  const [filteredCities, setFilteredCities] = useState([]);
  const [cityQuery, setCityQuery] = useState('');
  const [filteredBirthCities, setFilteredBirthCities] = useState([]);
  const [birthCityQuery, setBirthCityQuery] = useState('');

  const isOwner = useMemo(() => type === 'parcel_owner', [type]);

  useEffect(() => {
    fetchCompanyList();
  }, []);

  useEffect(() => {
    if (collaborator) {
      setFirstName(collaborator.first_name);
      setName(collaborator.name);
      setType(collaborator.type);
      setSubtype(collaborator.subtype);
      setEmail(collaborator.email);
      setPhone(collaborator.phone);
      setAddress(collaborator.address);
      setGender(collaborator.gender);
      setPartnerName(collaborator.partner_name);
      setBirthDate(collaborator.birth_dt);
      setInformations(collaborator.informations);
      setCompany(collaborator.company);
    } else {
      setFirstName('');
      setName('');
      setType(mandatoryType || getConstant('collaborator.type', 'other').value);
      setSubtype('');
      setEmail('');
      setPhone('');
      setAddress('');
      setGender(undefined);
      setPartnerName('');
      setBirthDate(undefined);
      setInformations('');
      setCompany(mandatoryCompany);
    }
  }, [isOpen, collaborator]);

  const fetchCompanyList = async () => {
    await privateFetch('GET', `/company/`).then((res) => {
      if (res) setCompanyList(res);
    });
  };

  const fetchCityList = async (q) => {
    await privateFetch('GET', `/city/autocomplete/?query=${q}`).then((res) => {
      setFilteredCities(res);
    });
  };

  const fetchBirthCityList = async (q) => {
    await privateFetch('GET', `/city/autocomplete/?query=${q}`).then((res) => {
      setFilteredBirthCities(res);
    });
  };

  const onValidate = () => {
    const collaboratorForm = {
      name,
      first_name: firstName,
      type,
      email,
      phone,
      address,
      gender,
      partner_name: partnerName,
      birth_dt: getDateStringOrUndefined(birthDate),
      informations,
      company: company?.id,
      city: city?.place_id,
      birth_place: birthPlace?.place_id
    };
    collaboratorForm.subtype = isOwner ? subtype : undefined;
    collaborator ? updateCollaborator(collaboratorForm) : createCollaborator(collaboratorForm);
  };

  const nameIsValid = name.length > 2;
  const firstNameIsValid = firstName.length > 2;
  const contactIsValid = validateEmail(email) || phone.length > 9;
  const typeIsValid = type && (type !== 'parcel_owner' || subtype);
  const formIsValid = nameIsValid && firstNameIsValid && typeIsValid && contactIsValid;

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={collaborator ? 'Modifier le contact' : 'Créer le contact'}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
        className="w-full"
      />
    </div>
  );

  const dropdownTemplate = (option, props) => {
    if (option) {
      return (
        <Tag
          tag={{
            label: option.name,
            icon: 'company'
          }}
        />
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const collaboratorForm = (
    <div className={`${asDialog ? 'dialog' : 'sidebar'}-content-container`}>
      <div className="manager-form-wrapper">
        <FormInput key="firstName" label="Prénom" required isValid={firstNameIsValid}>
          <InputText
            value={firstName}
            placeholder="Veuillez saisir le prénom du contact"
            onChange={(e) => setFirstName(e.target.value)}
          />
        </FormInput>
        <FormInput key="name" label="Nom" required isValid={nameIsValid}>
          <InputText
            value={name}
            placeholder="Veuillez saisir le nom du contact"
            onChange={(e) => setName(e.target.value)}
          />
        </FormInput>
        <FormInput key="type" label="Type" required isValid={type}>
          <Dropdown
            id="type"
            value={type}
            disabled={mandatoryType}
            onChange={(e) => setType(e.value)}
            options={CONSTANT.collaborator.type}
            optionLabel="label"
          />
        </FormInput>
        {isOwner && (
          <FormInput key="subtype" label="Sous-type" required isValid={subtype}>
            <Dropdown
              id="subtype"
              value={subtype}
              placeholder="Veuillez sélectionner un sous-type"
              onChange={(e) => setSubtype(e.value)}
              options={CONSTANT.collaborator.subtype}
              optionLabel="label"
            />
          </FormInput>
        )}
        <FormInput key="email" label="Email" required isValid={contactIsValid}>
          <InputText
            value={email}
            placeholder="Veuillez saisir l'adresse email du contact"
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormInput>
        <FormInput key="phone" label="Téléphone" required isValid={contactIsValid}>
          <InputText
            value={phone}
            placeholder="Veuillez saisir le numéro de téléphone du contact"
            onChange={(e) => setPhone(e.target.value)}
          />
        </FormInput>
        {!contactIsValid && <Message severity="warn" text="Obligatoire : email ou téléphone" />}
        {mandatoryCompany && (
          <FormInput key="company" label="Entreprise" required isValid={company}>
            <Dropdown
              id="company"
              value={company?.id}
              placeholder="Veuillez sélectionner une entreprise"
              onChange={(e) => setCompany(e.value)}
              options={companyList}
              optionValue="id"
              optionLabel="name"
              disabled
              valueTemplate={dropdownTemplate}
            />
          </FormInput>
        )}

        <FormInput label={'Ville'}>
          <AutoComplete
            field="name"
            value={cityQuery}
            suggestions={filteredCities}
            completeMethod={(e) => fetchCityList(e.query)}
            onChange={(e) => {
              setCityQuery(e.value);
              setCity(undefined);
            }}
            onSelect={(e) => setCity(e.value)}
            placeholder="Veuillez sélectionner une ville"
          />
        </FormInput>

        <FormInput key="address" label="Adresse">
          <InputText
            value={address}
            placeholder="Veuillez saisir l'adresse du contact"
            onChange={(e) => setAddress(e.target.value)}
          />
        </FormInput>

        <FormInput key="gender" label="Genre" hidden={!isOwner}>
          <Dropdown
            id="genderx"
            value={gender}
            onChange={(e) => setGender(e.value)}
            options={CONSTANT.collaborator.gender}
            optionLabel="label"
            placeholder="Veuillez sélectionner un genre"
          />
        </FormInput>

        <FormInput key="partner" label="Conjoint" hidden={!isOwner}>
          <InputText
            value={partnerName}
            placeholder="Veuillez saisir le nom du partenaire"
            onChange={(e) => setPartnerName(e.target.value)}
          />
        </FormInput>

        <FormInput key="birthDate" label="Date de naissance" hidden={!isOwner}>
          <Calendar
            value={birthDate}
            onChange={(e) => setBirthDate(e.value)}
            dateFormat="dd/mm/yy"
            placeholder="Veuillez selectionner la date de naissance"
            locale="fr"
          />
        </FormInput>

        <FormInput label={'Ville de naissance'} hidden={!isOwner}>
          <AutoComplete
            field="name"
            value={birthCityQuery}
            suggestions={filteredBirthCities}
            completeMethod={(e) => fetchBirthCityList(e.query)}
            onChange={(e) => {
              setBirthCityQuery(e.value);
              setBirthPlace(undefined);
            }}
            onSelect={(e) => setBirthPlace(e.value)}
            placeholder="Veuillez sélectionner une ville de naissance"
          />
        </FormInput>

        <FormInput key="information" label={'Notes internes'}>
          <InputTextarea
            rows={3}
            autoResize
            placeholder={'Veuillez saisir des commentaires'}
            value={informations}
            onChange={(e) => setInformations(e.target.value)}
          />
        </FormInput>
      </div>
      {!asDialog && footerButtons}
    </div>
  );

  if (asDialog) {
    return (
      <Dialog
        header={`${collaborator ? 'Modifier' : 'Créer'} un contact`}
        draggable={false}
        visible={isOpen}
        footer={footerButtons}
        className="h-auto pb-15"
        onHide={handleClose}>
        {collaboratorForm}
      </Dialog>
    );
  }

  return (
    <Sidebar
      style={{ width: 530, paddingLeft: 10, paddingRight: 10 }}
      header={
        <h2 className="text-slate-800">{`${collaborator ? 'Modifier' : 'Créer'} un contact`}</h2>
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      {collaboratorForm}
    </Sidebar>
  );
}

export default ManageCollaborator;

import React, { useState } from 'react';
import Section from '../../../../components/Display/Section';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import Tag from '../../../../components/Display/Tag';
import { getConstant } from '../../../../utils/constantHelper';
import { applyAreaFormat } from '../../../../utils/stringHelper';

function ProjectOwnerDatatable({
  owner,
  handleCreateOwnership,
  handleUpdateOwnership,
  handleDuplicateOwnership,
  openOwnerDetail
}) {
  const [filters, setFilters] = useState(null);
  const actionBodyTemplate = (rowData) => (
    <div className="flex flex-row gap-x-3">
      <Button
        onClick={() => handleUpdateOwnership(rowData)}
        icon="pi pi-pencil text-xs"
        className="opacity-50 hover:opacity-100 hover:bg-slate-300"
        severity={'secondary'}
        rounded
        outlined
        tooltip="Modifier"
      />
      <Button
        onClick={() => handleDuplicateOwnership(rowData)}
        icon="pi pi-copy text-xs"
        className="opacity-50 hover:opacity-100 hover:bg-slate-300"
        severity={'secondary'}
        rounded
        outlined
        tooltip="Dupliquer"
      />
    </div>
  );

  const cityBodyTemplate = (rowData) => {
    if (!rowData.parcel.city) {
      return '';
    }
    return `${rowData.parcel.city.name} (${rowData.parcel.city.zip_code})`;
  };

  const ownerSectionActions = [
    {
      onClick: openOwnerDetail,
      label: "Plus d'informations",
      icon: 'pi-eye'
    },
    {
      onClick: handleCreateOwnership,
      label: 'Parcelle',
      icon: 'pi-plus'
    }
  ];

  const sectionTitle = (
    <div className="flex flex-row gap-x-5 items-center">
      <span>{owner.alias}</span>
      <Tag
        tag={{
          label: getConstant('collaborator.subtype', owner.subtype).label
        }}
        rounded
      />
    </div>
  );

  return (
    <Section key={owner.id} label={sectionTitle} actions={ownerSectionActions}>
      <DataTable
        id="ownerships-table"
        value={owner?.ownerships}
        removableSort
        emptyMessage={'Pas de parcelle à afficher'}
        size="small"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 50, 100]}
        filterDisplay={'row'}
        selectionMode="single"
        filters={filters}
        onFilter={(e) => setFilters(e.filters)}>
        <Column frozen className="w-24" body={actionBodyTemplate}></Column>

        <Column
          sortable
          frozen
          className="font-bold !w-36"
          field="parcel.section,parcel.number"
          header="Parcelle"
          body={(rowData) => `${rowData.parcel.section}${rowData.parcel.number}`}
          filter
          filterMatchMode={FilterMatchMode.CONTAINS}
          showFilterMenu={false}></Column>
        <Column
          sortable
          className="!w-8"
          field="parcel.section"
          header="Section"
          filter
          filterMatchMode={FilterMatchMode.CONTAINS}
          showFilterMenu={false}></Column>
        <Column
          sortable
          field="parcel.number"
          className="!w-8"
          header="Numéro"
          filter
          filterMatchMode={FilterMatchMode.CONTAINS}
          showFilterMenu={false}></Column>
        <Column
          sortable
          field="parcel.surface"
          className="font-bold"
          header="Surface"
          showFilterMenu={false}
          body={(rowData) => applyAreaFormat(rowData.parcel.surface)}></Column>
        <Column
          sortable
          field="state"
          header="État"
          body={(rowData) => (
            <Tag tag={getConstant('project.securisation.ownershipStates', rowData.state)} rounded />
          )}></Column>
        <Column
          sortable
          field="parcel.is_critical"
          header="Type"
          body={(rowData) => (
            <Tag
              tag={getConstant('project.securisation.parcelTypes', rowData.parcel.is_critical)}
              rounded
            />
          )}></Column>

        <Column sortable field="parcel.city.name" header="Commune" body={cityBodyTemplate}></Column>
      </DataTable>
    </Section>
  );
}

export default ProjectOwnerDatatable;

import React from 'react';
import CustomIcon from '../CustomIcon';

function PanelbarItem({ icon, isActive, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`w-full h-16 flex items-center justify-center transition-colors cursor-pointer text-slate-50 ${
        isActive ? 'opacity-100 bg-slate-900' : 'opacity-80  hover:opacity-100 hover:bg-slate-900'
      }
      `}>
      <CustomIcon icon={icon} className={'w-6 h-6'} />
    </div>
  );
}

export default PanelbarItem;

import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { publicFetch } from '../../../utils/apiHelper';
import { saveSession } from '../../../utils/sessionHelper';
import FormInput from '../../../components/Form/FormInput';
import { validateEmail } from '../../../utils/stringHelper';

function SignInForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    await publicFetch(
      'POST',
      '/user/token/',
      'Les informations de connexion sont erronées. Veuillez réessayer.',
      JSON.stringify({
        username: email,
        password
      })
    ).then((response) => {
      if (!response) {
        setLoading(false);
      } else {
        saveSession(response);
        navigate('/');
      }
    });
  };

  const passwordIsValid = () => {
    return password.length > 7;
  };

  const emailIsValid = () => {
    return validateEmail(email);
  };

  const recoverPasswordButton = (
    <span
      className="cursor-pointer hover:text-custom-orange"
      onClick={() => navigate('/recover-password')}>
      Mot de passe oublié ?
    </span>
  );

  return (
    <div className="flex flex-col gap-y-16 ml-36 w-[500px] mb-8">
      <div className="flex flex-col gap-3">
        <span className="text-5xl font-semibold">Bienvenue</span>
        <span className="text-2xl font-normal">Se connecter à votre compte</span>
      </div>
      <div className="flex flex-col gap-6">
        <FormInput label={'Adresse mail'} required isValid={emailIsValid()}>
          <InputText
            id="email"
            type="text"
            placeholder="Entrez votre adresse email"
            className="w-full"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormInput>

        <FormInput
          label={'Mot de passe'}
          required
          isValid={passwordIsValid()}
          helpMessage={recoverPasswordButton}>
          <InputText
            id="password"
            type="password"
            placeholder="Entrez votre mot de passe"
            className="w-full"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormInput>
      </div>

      <Button
        label="Se connecter"
        icon="pi pi-user"
        className="btn"
        loading={loading}
        disabled={!passwordIsValid() || !emailIsValid()}
        onClick={handleLogin}
      />
    </div>
  );
}

export default SignInForm;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { privateFetch } from '../../../utils/apiHelper';
import Navbar from '../../../components/Navbar/Navbar';
import DashboardDatatable from './DashboardDatatable';
import ManageProject from './ManageProject';
import { useNavigationContext } from '../../../contexts/NavigationContext';
import { useDashboardContext } from '../../../contexts/DashboardContext';
import slugify from 'react-slugify';
import DashboardPanel from '../../../components/Panel/Dashboard/DashboardPanel';
import { useDebouncedCallback } from 'use-debounce';

function DashboardPage() {
  const { openProjectDetail } = useNavigationContext();
  const { dashboardContext, toggleDashboardFilters, filteringIsActive } = useDashboardContext();

  const [data, setData] = useState({
    projectList: [],
    totalRows: 0
  });
  const [datatableLoading, setDatatableLoading] = useState(true);
  const [projectPanelOpen, setProjectPanelOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchProjectList();
  }, []);

  useEffect(() => {
    refreshProjectList();
  }, [dashboardContext]);

  const refreshProjectList = useDebouncedCallback(() => {
    fetchProjectList();
  }, 500);

  const selectProject = (targetProject) => {
    openProjectDetail(targetProject);
  };

  const fetchProjectList = async () => {
    setDatatableLoading(true);
    await privateFetch(
      'GET',
      `/project/?rows=${dashboardContext.options.rows}&page=${
        dashboardContext.options.page
      }&offset=${dashboardContext.options.offset}&query=${slugify(
        dashboardContext.filters.query
      )}&state=${dashboardContext.filters.stateList}&techno=${
        dashboardContext.filters.technologyList
      }&manager=${dashboardContext.filters.managerList.map(
        (m) => m.id
      )}&client=${dashboardContext.filters.clientList.map((c) => c.id)}&sort_field=${
        dashboardContext.sortOption.sortField
      }&sort_order=${dashboardContext.sortOption.sortOrder}`
    ).then((res) => {
      if (res) {
        setData({ projectList: res.rows, totalRows: res.total_rows });
        setDatatableLoading(false);
      }
    });
  };

  const createProject = async (projectForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/project/`,
      `Le projet ${projectForm.name} a bien été créé.`,
      JSON.stringify(projectForm)
    ).then((res) => {
      setProjectPanelOpen(false);
      setLoading(false);
      fetchProjectList();
      if (res) {
        openProjectDetail(res);
      }
    });
  };

  return (
    <div className={`h-screen w-screen bg-white flex flex-col`}>
      <Navbar />
      <div className="w-full flex flex-row-reverse h-full">
        <DashboardPanel />
        <div className="px-5 py-3 w-full flex flex-col gap-y-3 overflow-scroll ">
          <div className="flex flex-row w-full justify-between items-center">
            <h2 className="text-slate-800">Liste des projets</h2>
            <Button
              key="password"
              label={'Nouveau projet'}
              size="small"
              outlined
              iconPos="left"
              icon="pi pi-plus"
              onClick={() => {
                setProjectPanelOpen(true);
              }}
            />
          </div>
          <DashboardDatatable
            data={data}
            loading={datatableLoading}
            onProjectSelect={selectProject}
          />
        </div>
      </div>

      <ManageProject
        loading={loading}
        isOpen={projectPanelOpen}
        handleClose={() => {
          setProjectPanelOpen(false);
        }}
        createProject={createProject}
      />
    </div>
  );
}

export default DashboardPage;

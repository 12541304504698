import React, { useState, useEffect, useRef } from 'react';
import Section from '../../../components/Display/Section';
import { MapContainer, TileLayer, FeatureGroup, LayersControl, LayerGroup } from 'react-leaflet';
import ReactLeafletKml from 'react-leaflet-kml'; // react-leaflet-kml must be loaded AFTER react-leaflet
import { FullscreenControl } from 'react-leaflet-fullscreen';

function ProjectKml({ project, handleOpenInTab }) {
  const [kml, setKml] = useState(undefined);
  const [interactiveMap, setInteractiveMap] = useState(false);
  const mapRef = useRef(null);
  const groupRef = useRef(null);

  useEffect(() => {
    if (project.kml_file) {
      setKml(undefined);
      setTimeout(() => {
        fetch(project.kml_file.file)
          .then((res) => res.text())
          .then((kmlText) => {
            const parser = new DOMParser();
            const kml = parser.parseFromString(kmlText, 'text/xml');
            setKml(kml);
          });

        setTimeout(() => {
          centerMap();
        }, 500);
      }, 500);
    } else {
      setKml(undefined);
      setInteractiveMap(false);
    }
  }, [project, project.kml_file]);

  const centerMap = () => {
    const map = mapRef.current;
    const group = groupRef.current;
    if (map && group) {
      map.fitBounds(group.getBounds());
    }
  };

  const kmlActions = [
    {
      label: 'Ouvrir',
      icon: 'pi-arrow-up-right',
      onClick: handleOpenInTab,
      disabled: !kml
    }
  ];

  const emptyKmlSection = (
    <div className="h-[400px] w-full flex items-center justify-center italic text-slate-400 border border-slate-200 rounded">
      <span>Pas de fichier KML disponible</span>
    </div>
  );

  return (
    <Section label="Implantation" actions={kmlActions}>
      {!kml ? (
        emptyKmlSection
      ) : (
        <div className="relative h-[400px] w-full">
          {!interactiveMap && (
            <div
              onClick={() => setInteractiveMap(true)}
              className="absolute flex items-center justify-center h-[400px] w-full top-0 left-0 bg-slate-600 bg-opacity-80 opacity-0 hover:opacity-100 z-40 cursor-pointer transition-all">
              <span className="text-white font-semibold text-xl">
                Cliquer pour intéragir avec la carte
              </span>
            </div>
          )}
          <MapContainer
            className="z-10"
            style={{ height: '100%', width: '100%' }}
            zoom={4}
            center={[46.711, 1.719]}
            ref={mapRef}>
            <LayersControl>
              <LayersControl.BaseLayer name="Open Street Map" checked>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Google Map Satellite">
                <LayerGroup>
                  <TileLayer
                    attribution="Google Maps Satellite"
                    url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                  />
                  <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}" />
                </LayerGroup>
              </LayersControl.BaseLayer>
            </LayersControl>
            <FeatureGroup ref={groupRef}>{kml && <ReactLeafletKml kml={kml} />}</FeatureGroup>
            <FullscreenControl />
          </MapContainer>
        </div>
      )}
    </Section>
  );
}

export default ProjectKml;

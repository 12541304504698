import React from 'react';
import { ReactComponent as User } from '../assets/icon/user.svg';
import { ReactComponent as Dashboard } from '../assets/icon/dashboard.svg';
import { ReactComponent as Admin } from '../assets/icon/admin.svg';
import { ReactComponent as Logout } from '../assets/icon/logout.svg';
import { ReactComponent as Project } from '../assets/icon/project.svg';
import { ReactComponent as Contact } from '../assets/icon/contact.svg';
import { ReactComponent as Comment } from '../assets/icon/comment.svg';
import { ReactComponent as Event } from '../assets/icon/event.svg';
import { ReactComponent as Chevron } from '../assets/icon/down.svg';
import { ReactComponent as Company } from '../assets/icon/company.svg';
import { ReactComponent as Earth } from '../assets/icon/earth.svg';
import { ReactComponent as Column } from '../assets/icon/column.svg';
import { ReactComponent as Calendar } from '../assets/icon/calendar.svg';
import { ReactComponent as CompanyPh } from '../assets/icon/company-placeholder.svg';
import { ReactComponent as Rocket } from '../assets/icon/rocket.svg';
import { ReactComponent as Bug } from '../assets/icon/bug.svg';
import { ReactComponent as Meeting } from '../assets/icon/meeting.svg';

function CustomIcon(props) {
  switch (props.icon) {
    case 'user':
      return <User {...props} />;
    case 'project':
      return <Project {...props} />;
    case 'admin':
      return <Admin {...props} />;
    case 'dashboard':
      return <Dashboard {...props} />;
    case 'logout':
      return <Logout {...props} />;
    case 'contact':
      return <Contact {...props} />;
    case 'comment':
      return <Comment {...props} />;
    case 'event':
      return <Event {...props} />;
    case 'chevron':
      return <Chevron {...props} />;
    case 'company':
      return <Company {...props} />;
    case 'earth':
      return <Earth {...props} />;
    case 'column':
      return <Column {...props} />;
    case 'calendar':
      return <Calendar {...props} />;
    case 'companyPh':
      return <CompanyPh {...props} />;
    case 'rocket':
      return <Rocket {...props} />;
    case 'bug':
      return <Bug {...props} />;
    case 'meeting':
      return <Meeting {...props} />;
    default:
      return null;
  }
}

export default CustomIcon;

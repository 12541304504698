import React, { useState, useEffect, useMemo } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { CONSTANT } from '../../../utils/constantHelper';
import moment from 'moment';
import FormInput from '../../../components/Form/FormInput';
import { Calendar } from 'primereact/calendar';
import { Divider } from 'primereact/divider';

function ManageProjectScheduleCreation({
  isOpen,
  handleClose,
  schedules,
  createSchedules,
  loading
}) {
  const [studySchedule, setStudySchedule] = useState(undefined);
  const [instructionSchedule, setInstructionSchedule] = useState(undefined);
  const [prebuildingSchedule, setPrebuildingSchedule] = useState(undefined);

  useEffect(() => {
    if (schedules) {
      initiSchedules();
    }
  }, [schedules]);

  const initiSchedules = () => {
    if (!schedules.study_step) {
      setStudySchedule({
        startDate: new Date(moment()),
        endDate: new Date(moment().add(18, 'months'))
      });
    }
    if (!schedules.instruction_step) {
      setInstructionSchedule({
        startDate: new Date(moment().add(18, 'months')),
        endDate: new Date(moment().add(30, 'months'))
      });
    }
    if (!schedules.prebuilding_step) {
      setPrebuildingSchedule({
        startDate: new Date(moment().add(30, 'months')),
        endDate: new Date(moment().add(48, 'months'))
      });
    }
  };

  const onValidate = () => {
    const stepsForm = [];
    if (studySchedule) {
      stepsForm.push({
        type: 'study_step',
        start_dt: moment(studySchedule.startDate).format('YYYY-MM-DD'),
        end_dt: moment(studySchedule.endDate).format('YYYY-MM-DD')
      });
    }
    if (instructionSchedule) {
      stepsForm.push({
        type: 'instruction_step',
        start_dt: moment(instructionSchedule.startDate).format('YYYY-MM-DD'),
        end_dt: moment(instructionSchedule.endDate).format('YYYY-MM-DD')
      });
    }
    if (prebuildingSchedule) {
      stepsForm.push({
        type: 'prebuilding_step',
        start_dt: moment(prebuildingSchedule.startDate).format('YYYY-MM-DD'),
        end_dt: moment(prebuildingSchedule.endDate).format('YYYY-MM-DD')
      });
    }

    createSchedules(stepsForm);
  };

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={'Valider la création'}
        loading={loading}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 530, paddingLeft: 10, paddingRight: 10 }}
      header={<h2 className="text-slate-800">{`Plannifier le projet`}</h2>}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="manager-form-wrapper">
          {studySchedule && (
            <div className="flex flex-col gap-y-2">
              <h4>Phase d'études</h4>
              <FormInput
                key="studyStartDate"
                label="Mois de début estimée"
                required
                isValid={studySchedule.startDate}>
                <Calendar
                  value={studySchedule.startDate}
                  onChange={(e) => setStudySchedule({ ...studySchedule, startDate: e.value })}
                  dateFormat="MM yy"
                  view="month"
                  placeholder="Veuillez selectionner le mois de début estimée"
                  locale="fr"
                />
              </FormInput>
              <FormInput
                key="studyEndDate"
                label="Mois de fin estimée"
                required
                isValid={studySchedule.endDate}>
                <Calendar
                  value={studySchedule.endDate}
                  onChange={(e) => setStudySchedule({ ...studySchedule, endDate: e.value })}
                  dateFormat={`MM yy`}
                  view="month"
                  placeholder="Veuillez selectionner le mois de fin estimée"
                  locale="fr"
                />
              </FormInput>
            </div>
          )}
          {instructionSchedule && (
            <div className="flex flex-col gap-y-2">
              <h4>Phase d'instruction</h4>
              <FormInput
                key="instructionStartDate"
                label="Mois de début estimée"
                required
                isValid={instructionSchedule.startDate}>
                <Calendar
                  value={instructionSchedule.startDate}
                  onChange={(e) =>
                    setInstructionSchedule({ ...instructionSchedule, startDate: e.value })
                  }
                  dateFormat="MM yy"
                  view="month"
                  placeholder="Veuillez selectionner le mois de début estimée"
                  locale="fr"
                />
              </FormInput>
              <FormInput
                key="instructionEndDate"
                label="Mois de fin estimée"
                required
                isValid={instructionSchedule.endDate}>
                <Calendar
                  value={instructionSchedule.endDate}
                  onChange={(e) =>
                    setInstructionSchedule({ ...instructionSchedule, endDate: e.value })
                  }
                  dateFormat={`MM yy`}
                  view="month"
                  placeholder="Veuillez selectionner le mois de fin estimée"
                  locale="fr"
                />
              </FormInput>
            </div>
          )}
          {prebuildingSchedule && (
            <div className="flex flex-col gap-y-2">
              <h4>Phase de post-instruction</h4>
              <FormInput
                key="prebuildingStartDate"
                label="Mois de début estimée"
                required
                isValid={prebuildingSchedule.startDate}>
                <Calendar
                  value={prebuildingSchedule.startDate}
                  onChange={(e) =>
                    setPrebuildingSchedule({ ...prebuildingSchedule, startDate: e.value })
                  }
                  dateFormat="MM yy"
                  view="month"
                  placeholder="Veuillez selectionner le mois de début estimée"
                  locale="fr"
                />
              </FormInput>
              <FormInput
                key="prebuildingEndDate"
                label="Mois de fin estimée"
                required
                isValid={prebuildingSchedule.endDate}>
                <Calendar
                  value={prebuildingSchedule.endDate}
                  onChange={(e) =>
                    setPrebuildingSchedule({ ...prebuildingSchedule, endDate: e.value })
                  }
                  dateFormat={`MM yy`}
                  view="month"
                  placeholder="Veuillez selectionner le mois de fin estimée"
                  locale="fr"
                />
              </FormInput>
            </div>
          )}
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageProjectScheduleCreation;

import React, { useState, useEffect, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import FormInput from '../../../Form/FormInput';
import { InputTextarea } from 'primereact/inputtextarea';
import { usePropertyContext } from '../../../../contexts/PropertyContext';
import { MultiSelect } from 'primereact/multiselect';
import { useAccountContext } from '../../../../contexts/AccountContext';
import RichTextEditor from '../../../Form/RichTextEditor';

function ManageEvent({ isOpen, handleClose, event, createEvent, updateEvent, loading }) {
  const { propertyContext, handleCreateProperty } = usePropertyContext();
  const { isEmployee } = useAccountContext();

  // Form
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [propertyIdList, setPropertyIdList] = useState([]);

  useEffect(() => {
    if (event) {
      setTitle(event.title);
      setContent(event.content);
      setPropertyIdList(event.properties.map((p) => p.id));
    } else {
      setTitle('');
      setContent('');
      setPropertyIdList([]);
    }
  }, [isOpen, event]);

  const onValidate = () => {
    const eventForm = {
      title,
      content,
      properties: propertyIdList
    };
    event ? updateEvent(eventForm) : createEvent(eventForm);
  };

  const titleIsValid = title.length > 2 && title.length <= 200;
  const contentIsValid = content.length > 2;
  const propertiesIsValid = propertyIdList.length;

  const formIsValid = titleIsValid && contentIsValid && propertiesIsValid;

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={event ? 'Modifier la décision' : 'Enregistrer la décision'}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
        className="w-full"
      />
    </div>
  );

  const createTagButton = (
    <Button
      onClick={() => handleCreateProperty('event')}
      icon="pi pi-plus"
      className="hover:bg-slate-100 text-gray-400"
      severity={'secondary'}
      rounded
      outlined
    />
  );

  return (
    <Sidebar
      style={{ width: 530, paddingLeft: 10, paddingRight: 10 }}
      header={<h2 className="text-slate-800">{`${event ? 'Modifier' : 'Créer'} une décision`}</h2>}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="manager-form-wrapper">
          <FormInput
            key="title"
            label="Titre"
            required
            isValid={titleIsValid}
            tooltipMessage={'Champs obligatoire (max. 200 caractères)'}>
            <InputText
              value={title}
              placeholder="Veuillez saisir le titre"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormInput>

          <FormInput key="content" label={'Contenu'} required isValid={contentIsValid}>
            <RichTextEditor
              value={content}
              onChange={(value) => setContent(value)}
              height={'h-48'}
              placeholder={'Veuillez saisir le contenu'}
            />
          </FormInput>

          <FormInput label={'Tag associés'} required isValid={propertiesIsValid}>
            <div className="flex flex-row justify-between w-full items-center gap-x-3">
              <MultiSelect
                id="properties"
                key="properties"
                className="grow"
                value={propertyIdList}
                onChange={(e) => {
                  setPropertyIdList(e.value);
                }}
                options={propertyContext.filter((p) => p.type === 'event')}
                optionLabel="label"
                optionValue="id"
                placeholder="Veuillez sélectionner au moins un tag"
                scrollHeight={'350px'}
                showSelectAll={false}
                filter
                display="chip"
              />
              {!isEmployee && createTagButton}
            </div>
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageEvent;

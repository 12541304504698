import React, { useEffect } from 'react';
import { getImage } from '../../utils/imageHelper';
import NavbarItem from './NavbarItem';
import NavbarProjectSelector from './NavbarProjectSelector';
import { useNavigationContext } from '../../contexts/NavigationContext';
import { useNavigate } from 'react-router-dom';
import { useAccountContext } from '../../contexts/AccountContext';
import { saveSession } from '../../utils/sessionHelper';
import LogoutItem from './LogoutItem';
import SearchBar from '../Search/SearchBar';

function Navbar() {
  const navigate = useNavigate();
  const { navigationContext, setNavigationContext, openProjectDetail, showBugReport } =
    useNavigationContext();
  const { setAccountContext } = useAccountContext();

  useEffect(() => {
    navigationContext && navigationContext.activeApp && navigate(`/${navigationContext.activeApp}`);
  }, [navigationContext?.activeApp]);

  const navigateToApp = (app) => {
    setNavigationContext({
      ...navigationContext,
      activeApp: app
    });
  };

  const handleSignOut = () => {
    setAccountContext(null);
    saveSession(null);
    navigate('/');
  };

  return (
    <div className="w-full h-16 bg-slate-800 flex flex-row justify-between items-center pl-5">
      <div className="flex flex-row items-center gap-x-5 py-3 h-full">
        <img
          src={getImage('logo-aden.png')}
          alt="Logo du Collectif Node"
          className="h-full cursor-pointer"
          onClick={() => navigateToApp('dashboard')}
        />
        <SearchBar />
      </div>
      <div className="flex flex-row gap-x-3 h-full">
        <div className="flex flex-row gap-x-5 h-full py-2">
          {navigationContext.activeProject?.id && (
            <NavbarItem
              label={navigationContext.activeProject.name}
              icon={'project'}
              key={'project'}
              isActive={navigationContext.activeApp === 'project'}
              onClick={() => navigateToApp('project')}
            />
          )}
          <NavbarItem
            label={'Synthèse'}
            icon={'dashboard'}
            key={'dashboard'}
            isActive={navigationContext.activeApp === 'dashboard'}
            onClick={() => navigateToApp('dashboard')}
          />
          <NavbarItem
            label={'Administration'}
            icon={'admin'}
            isActive={navigationContext.activeApp === 'admin'}
            onClick={() => navigateToApp('admin')}
          />
          <NavbarItem label={'Bug'} icon={'bug'} onClick={showBugReport} />
        </div>
        <LogoutItem onClick={handleSignOut} />
      </div>
    </div>
  );
}

export default Navbar;

import React, { createContext, useContext, useEffect, useState } from 'react';
import { privateFetch } from '../utils/apiHelper';
import ManageProperty from '../pages/private/Common/ManageProperty';

const PropertyContext = createContext(null);

export const PropertyContextProvider = ({ children }) => {
  const [propertyContext, setPropertyContext] = useState([]);
  const [propertyManagerVisible, setPropertyManagerVisible] = useState(false);
  const [propertyMandatoryType, setPropertyMandatoryType] = useState(undefined);

  useEffect(() => {
    fetchPropertyList();
  }, []);

  const fetchPropertyList = async () => {
    await privateFetch('GET', `/property/`).then((res) => {
      if (res) {
        setPropertyContext(res);
      }
    });
  };

  const createProperty = async (propertyForm) => {
    await privateFetch(
      'POST',
      `/property/`,
      `Le tag ${propertyForm.label} a bien été créé.`,
      JSON.stringify(propertyForm)
    ).then((res) => {
      setPropertyManagerVisible(false);
      fetchPropertyList();
    });
  };

  const handleCreateProperty = (mandatoryType = undefined) => {
    setPropertyManagerVisible(true);
    setPropertyMandatoryType(mandatoryType);
  };

  return (
    <PropertyContext.Provider value={{ propertyContext, fetchPropertyList, handleCreateProperty }}>
      <ManageProperty
        isOpen={propertyManagerVisible}
        mandatoryType={propertyMandatoryType}
        handleCreate={createProperty}
        handleClose={() => {
          setPropertyManagerVisible(false);
          setPropertyMandatoryType(undefined);
        }}
      />
      {children}
    </PropertyContext.Provider>
  );
};

export const usePropertyContext = () => {
  return useContext(PropertyContext);
};

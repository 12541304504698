import React from 'react';

function Avatar({ alias }) {
  const getInitials = () => {
    if (!alias) return ['-'];
    return alias
      .split(' ')
      .map((w) => w.charAt(0))
      .slice(0, 2);
  };
  return (
    <div className="h-12 w-12 min-w-[48px] bg-slate-300 rounded-full flex flex-row justify-center items-center gap-x-[1px]">
      {getInitials().map((w, i) => (
        <span key={i} className="font-semibold text-slate-600">
          {w.toUpperCase()}
        </span>
      ))}
    </div>
  );
}

export default Avatar;

import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import FormInput from '../../../components/Form/FormInput';

function ManageClient({ client, isOpen, handleClose, createClient, updateClient, loading }) {
  const [name, setName] = useState('');
  const [iban, setIban] = useState('');
  const [siret, setSiret] = useState('');

  useEffect(() => {
    if (client) {
      setName(client.name);
      setIban(client.iban);
      setSiret(client.siret);
    } else {
      setName('');
      setIban('');
      setSiret('');
    }
  }, [isOpen, client]);

  const onValidate = () => {
    const clientForm = {
      name,
      iban,
      siret
    };
    client ? updateClient(clientForm) : createClient(clientForm);
  };

  const nameIsValid = name.length > 2;
  const formIsValid = nameIsValid;

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={client ? `Modifier le client` : 'Créer le client'}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={<h2 className="text-slate-800">{`${client ? 'Modifier' : 'Créer'} un client`}</h2>}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="manager-form-wrapper">
          <FormInput key="name" label="Nom" required isValid={nameIsValid}>
            <InputText
              value={name}
              placeholder="Veuillez saisir le nom du client"
              onChange={(e) => setName(e.target.value)}
            />
          </FormInput>
          {/*
          <FormInput key="siret" label="Numéro de SIRET">
            <InputText
              value={siret}
              placeholder="Veuillez saisir le numéro de SIRET"
              onChange={(e) => setSiret(e.target.value)}
            />
          </FormInput>
          <FormInput key="iban" label={'IBAN'}>
            <InputText
              value={iban}
              placeholder="Veuillez saisir l'IBAN"
              onChange={(e) => setIban(e.target.value)}
            />
          </FormInput>
          */}
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageClient;

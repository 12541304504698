import React, { useEffect, useState, useMemo } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { CONSTANT } from '../../../utils/constantHelper';
import { Dropdown } from 'primereact/dropdown';
import FormInput from '../../../components/Form/FormInput';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';

function ManageCompany({
  isOpen,
  handleClose,
  handleCreateCompany,
  handleUpdateCompany,
  asDialog,
  company,
  defaultType
}) {
  const [name, setName] = useState('');
  const [siret, setSiret] = useState('');
  const [iban, setIban] = useState('');
  const [type, setType] = useState(undefined);
  const [location, setLocation] = useState('');
  const [secondLocation, setSecondLocation] = useState('');
  const [thirdLocation, setThirdLocation] = useState('');
  const [fourthLocation, setFourthLocation] = useState('');

  useEffect(() => {
    if (company) {
      setName(company.name);
      setSiret(company.siret);
      setIban(company.iban);
      setType(company.type);
      setLocation(company.location);
      setSecondLocation(company.second_location);
      setThirdLocation(company.third_location);
      setFourthLocation(company.fourth_location);
    } else {
      setName('');
      setSiret('');
      setIban('');
      setType(defaultType);
      setLocation('');
      setSecondLocation('');
      setThirdLocation('');
      setFourthLocation('');
    }
  }, [isOpen]);

  const isSPV = useMemo(() => type === 'spv', [type]);

  const onValidate = () => {
    let payload = {
      name,
      siret,
      iban,
      type,
      location
    };
    if (isSPV) {
      payload = {
        ...payload,
        second_location: secondLocation,
        third_location: thirdLocation,
        fourth_location: fourthLocation
      };
    }
    company ? handleUpdateCompany(payload) : handleCreateCompany(payload);
  };

  const formIsValid = name.length > 2 && type;

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={`${company ? 'Modifier' : 'Créer'} l'entreprise`}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
        className="w-full"
      />
    </div>
  );

  const companyForm = (
    <div className={`${asDialog ? 'dialog' : 'sidebar'}-content-container`}>
      <div className="manager-form-wrapper">
        <FormInput key="name" label="Nom" required isValid={name.length > 2}>
          <InputText
            value={name}
            placeholder="Veuillez saisir le nom de l'entreprise"
            onChange={(e) => setName(e.target.value)}
          />
        </FormInput>
        <FormInput label={'Type'} required isValid={type}>
          <Dropdown
            value={type}
            onChange={(e) => setType(e.value)}
            options={CONSTANT.company.type}
            optionLabel="label"
            optionValue="value"
            placeholder="Veuillez sélectionner un type d'entreprise"
          />
        </FormInput>
        <FormInput key="location" label="Adresse Postale">
          <InputText
            value={location}
            placeholder="Veuillez saisir l'adresse postale"
            onChange={(e) => setLocation(e.target.value)}
          />
        </FormInput>
        {isSPV && location && (
          <FormInput key="second_location" label="Deuxième Adresse Postale ">
            <InputText
              value={secondLocation}
              placeholder="Veuillez saisir la deuxième adresse postale "
              onChange={(e) => setSecondLocation(e.target.value)}
            />
          </FormInput>
        )}
        {isSPV && secondLocation && (
          <FormInput key="third_location" label="Troisième Adresse Postale ">
            <InputText
              value={thirdLocation}
              placeholder="Veuillez saisir la troisième adresse postale"
              onChange={(e) => setThirdLocation(e.target.value)}
            />
          </FormInput>
        )}
        {isSPV && thirdLocation && (
          <FormInput key="fourth_location" label="Quatrième Adresse Postale ">
            <InputText
              value={fourthLocation}
              placeholder="Veuillez saisir la quatrième adresse postale"
              onChange={(e) => setFourthLocation(e.target.value)}
            />
          </FormInput>
        )}
        <FormInput key="siret" label="Numéro de SIRET">
          <InputText
            value={siret}
            placeholder="Veuillez saisir le numéro de SIRET"
            onChange={(e) => setSiret(e.target.value)}
          />
        </FormInput>
        <FormInput key="iban" label="IBAN">
          <InputText
            value={iban}
            placeholder="Veuillez saisir l'IBAN"
            onChange={(e) => setIban(e.target.value)}
          />
        </FormInput>
      </div>
      {!asDialog && footerButtons}
    </div>
  );

  if (asDialog) {
    return (
      <Dialog
        header={`${company ? "Modifier l'" : 'Nouvelle '}entreprise`}
        draggable={false}
        visible={isOpen}
        footer={footerButtons}
        className="h-auto pb-15"
        onHide={handleClose}>
        {companyForm}
      </Dialog>
    );
  }
  return (
    <Sidebar
      style={{ width: 530, paddingLeft: 10, paddingRight: 10 }}
      header={
        <h2 className="text-slate-800">{`${company ? "Modifier l'" : 'Nouvelle '}entreprise`}</h2>
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      {companyForm}
    </Sidebar>
  );
}

export default ManageCompany;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import { CONSTANT } from '../../../../utils/constantHelper';
import { useDashboardContext } from '../../../../contexts/DashboardContext';
import { InputSwitch } from 'primereact/inputswitch';

function PanelColumns({ isActive }) {
  const { toggleDashboardPanel } = useNavigationContext();
  const { toggleDashboardColumn, columnIsHidden } = useDashboardContext();

  return (
    <div
      className={`h-full w-[450px] bg-white flex flex-col border-l py-3 px-5 gap-y-3 ${
        isActive ? '' : 'hidden'
      }`}>
      <div className="flex flex-row w-full justify-between items-center">
        <h3 className="text-2xl text-slate-900 font-semibold">{'Affichage des colonnes'}</h3>
        <div className="flex flex-row gap-x-1">
          <Button
            key="close"
            text
            rounded
            icon={'pi pi-times'}
            onClick={() => {
              toggleDashboardPanel(undefined);
            }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-3 w-full">
        {CONSTANT.dashboard.displayableColmuns.map((c) => (
          <div className="flex flex-row w-full items-center justify-between" key={c.key}>
            <span>{c.label}</span>
            <InputSwitch
              checked={!columnIsHidden(c.key)}
              onChange={(e) => toggleDashboardColumn(c.key, e.value)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PanelColumns;

import React, { createContext, useContext, useEffect, useState } from 'react';
import { privateFetch } from '../utils/apiHelper';

const CollaboratorContext = createContext(null);

const initial = {
  internalCollaborators: []
};

export const CollaboratorContextProvider = ({ children }) => {
  const [collaboratorContext, setCollaboratorContext] = useState(initial);

  useEffect(() => {
    setTimeout(() => {
      fetchInternalCollaboratorList();
    }, 300);
  }, []);

  const fetchInternalCollaboratorList = async () => {
    await privateFetch('GET', `/collaborator/?t=internal`).then((res) => {
      if (res) {
        setCollaboratorContext({
          ...collaboratorContext,
          internalCollaborators: res
        });
      }
    });
  };

  return (
    <CollaboratorContext.Provider
      value={{
        collaboratorContext
      }}>
      {children}
    </CollaboratorContext.Provider>
  );
};

export const useCollaboratorContext = () => {
  return useContext(CollaboratorContext);
};

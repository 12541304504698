import React from 'react';
import Layout from '../Layout';
import PasswordSetupForm from './PasswordSetupForm';

function PasswordSetup() {
  return (
    <Layout>
      <PasswordSetupForm />
    </Layout>
  );
}

export default PasswordSetup;

import React, { createContext, useContext, useEffect, useState } from 'react';
import { privateFetch } from '../utils/apiHelper';
import { useNavigationContext } from './NavigationContext';
import ManageCollaborator from '../pages/private/Common/ManageCollaborator';

const DashboardCollaboratorContext = createContext(null);

const initial = {
  query: '',
  type: undefined,
  collaborators: [],
  collaborator: undefined
};

export const DashboardCollaboratorContextProvider = ({ children }) => {
  const { navigationContext, toggleDashboardPanel } = useNavigationContext();
  const [dashboardCollaboratorContext, setDashboardCollaboratorContext] = useState(initial);

  // Manager Controls
  const [managerVisible, setManagerVisible] = useState(false);
  const [mandatoryType, setMandatoryType] = useState(undefined);
  const [mandatoryCompany, setMandatoryCompany] = useState(undefined);
  const [asDialog, setAsDialog] = useState(false);

  useEffect(() => {
    fetchCollaboratorList();
  }, [dashboardCollaboratorContext.query, dashboardCollaboratorContext.type]);

  const setCollaboratorQuery = (query) => {
    setDashboardCollaboratorContext({
      ...dashboardCollaboratorContext,
      query
    });
  };

  const setCollaboratorType = (type) => {
    setDashboardCollaboratorContext({
      ...dashboardCollaboratorContext,
      type
    });
  };

  const resetCollaboratorDetail = () => {
    setDashboardCollaboratorContext({
      ...dashboardCollaboratorContext,
      collaborator: undefined
    });
  };

  const openCollaboratorDetail = (collaborator) => {
    if (navigationContext.activeDashboardPanel !== 'collaborator') {
      toggleDashboardPanel('collaborator');
    }
    fetchCollaboratorDetail(collaborator);
  };

  const fetchCollaboratorList = async () => {
    await privateFetch(
      'GET',
      `/collaborator/?q=${dashboardCollaboratorContext.query}&t=${
        dashboardCollaboratorContext.type || ''
      }`
    ).then((res) => {
      if (res) {
        setDashboardCollaboratorContext({
          ...dashboardCollaboratorContext,
          collaborators: res
        });
      }
    });
  };

  const fetchCollaboratorDetail = async (
    targetCollaborator = dashboardCollaboratorContext.collaborator
  ) => {
    await privateFetch('GET', `/collaborator/${targetCollaborator.id}/`).then((res) => {
      if (res) {
        setDashboardCollaboratorContext({
          ...dashboardCollaboratorContext,
          collaborator: res
        });
      }
    });
  };

  const createCollaborator = async (collaboratorForm) => {
    await privateFetch(
      'POST',
      `/collaborator/`,
      `Le contact a bien été créé.`,
      JSON.stringify(collaboratorForm)
    ).then((res) => {
      setManagerVisible(false);
      setTimeout(() => {
        setTimeout(() => {
          openCollaboratorDetail(res);
        }, 300);
        fetchCollaboratorList();
      }, 300);
    });
  };

  const updateCollaborator = async (collaboratorForm) => {
    await privateFetch(
      'PUT',
      `/collaborator/${dashboardCollaboratorContext.collaborator.id}/`,
      `Le contact a bien été modifié.`,
      JSON.stringify(collaboratorForm)
    ).then((res) => {
      setManagerVisible(false);
      setTimeout(() => {
        fetchCollaboratorList();
        setTimeout(() => {
          fetchCollaboratorDetail();
        }, 300);
      }, 300);
    });
  };

  const handleCreateCollaborator = (
    asDialog = false,
    mandatoryType = undefined,
    mandatoryCompany = undefined
  ) => {
    resetCollaboratorDetail();
    setAsDialog(asDialog);
    setManagerVisible(true);
    setMandatoryType(mandatoryType);
    setMandatoryCompany(mandatoryCompany);
  };

  const handleUpdateCollaborator = (asDialog = false) => {
    setAsDialog(asDialog);
    setManagerVisible(true);
  };

  return (
    <DashboardCollaboratorContext.Provider
      value={{
        dashboardCollaboratorContext,
        setDashboardCollaboratorContext,
        fetchCollaboratorList,
        fetchCollaboratorDetail,
        setCollaboratorQuery,
        setCollaboratorType,
        resetCollaboratorDetail,
        openCollaboratorDetail,
        handleCreateCollaborator,
        handleUpdateCollaborator
      }}>
      {children}

      <ManageCollaborator
        isOpen={managerVisible}
        asDialog={asDialog}
        mandatoryType={mandatoryType}
        mandatoryCompany={mandatoryCompany}
        collaborator={dashboardCollaboratorContext?.collaborator}
        createCollaborator={createCollaborator}
        updateCollaborator={updateCollaborator}
        handleClose={() => {
          setManagerVisible(false);
          setMandatoryType(undefined);
        }}
      />
    </DashboardCollaboratorContext.Provider>
  );
};

export const useDashboardCollaboratorContext = () => {
  return useContext(DashboardCollaboratorContext);
};

import React, { createContext, useContext, useEffect, useState } from 'react';
import { privateFetch } from '../utils/apiHelper';
import { useNavigationContext } from './NavigationContext';

const ProjectMeetingContext = createContext(null);

const initial = {
  query: '',
  author: undefined,
  meetings: []
};

export const ProjectMeetingContextProvider = ({ children }) => {
  const { navigationContext } = useNavigationContext();
  const [projectMeetingContext, setProjectMeetingContext] = useState(initial);
  const [projectAllMeetingContext, setProjectAllMeetingContext] = useState(initial);

  useEffect(() => {
    if (navigationContext.activeProject?.id) {
      fetchProjectMeetingList();
    }
  }, [
    projectMeetingContext.query,
    projectMeetingContext.author,
    navigationContext.activeProject?.id
  ]);

  useEffect(() => {
    if (navigationContext.activeProject?.id) {
      fetchProjectAllMeeting();
    }
  }, []);

  const setProjectMeetingQuery = (query) => {
    setProjectMeetingContext({
      ...projectMeetingContext,
      query
    });
  };

  const setProjectMeetingAuthor = (collaborator) => {
    setProjectMeetingContext({
      ...projectMeetingContext,
      author: collaborator
    });
  };

  const fetchProjectMeetingList = async () => {
    await privateFetch(
      'GET',
      `/project/${navigationContext.activeProject.id}/meeting/?q=${projectMeetingContext.query}&c=${
        projectMeetingContext.author?.id || ''
      }`
    ).then((res) => {
      if (res) {
        setProjectMeetingContext({
          ...projectMeetingContext,
          meetings: res
        });
      }
    });
  };

  const fetchProjectAllMeeting = async () => {
    await privateFetch('GET', `/project/${navigationContext.activeProject.id}/meeting/`).then(
      (res) => {
        if (res) {
          setProjectAllMeetingContext({
            ...projectAllMeetingContext,
            meetings: res.slice(0, 3)
          });
        }
      }
    );
  };

  return (
    <ProjectMeetingContext.Provider
      value={{
        projectMeetingContext,
        setProjectMeetingContext,
        fetchProjectMeetingList,
        setProjectMeetingQuery,
        setProjectMeetingAuthor,
        fetchProjectAllMeeting,
        projectAllMeetingContext
      }}>
      {children}
    </ProjectMeetingContext.Provider>
  );
};

export const useProjectMeetingContext = () => {
  return useContext(ProjectMeetingContext);
};

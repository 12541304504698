import React, { useState, useEffect } from 'react';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import { useProjectCollaboratorContext } from '../../../../contexts/ProjectCollaboratorContext';
import { privateFetch } from '../../../../utils/apiHelper';
import Section from '../../../../components/Display/Section';
import Data from '../../../../components/Display/Data';
import ProjectPrebuildingDetail from './ProjectPrebuildingDetail';
import { Divider } from 'primereact/divider';
import ManagePrebuilding from './ManagePrebuilding';
import ManagePrebuildingCompany from './ManagePrebuildingCompany';
import { useProjectCompanyContext } from '../../../../contexts/ProjectCompanyContext';
import ManageInvoice from '../Shared/Invoice/ManageInvoice';
import { confirmDialog } from 'primereact/confirmdialog';
import ManagePrebuildingCompanyCollaborators from './ManagePrebuildingCompanyCollaborators';
import { applyCurrencyFormat } from '../../../../utils/stringHelper';

function ProjectPrebuilding({ fetchProjectBudget }) {
  const { navigationContext } = useNavigationContext();
  const { openCollaboratorDetail, fetchProjectCollaboratorList } = useProjectCollaboratorContext();
  const { fetchProjectCompanyList } = useProjectCompanyContext();
  const [prebuildingData, setPrebuildingData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [prebuildingPanelOpen, setPrebuildingPanelOpen] = useState(false);
  const [prebuilding, setPrebuilding] = useState(undefined);
  const [invoice, setInvoice] = useState(undefined);
  const [invoicePanelOpen, setInvoicePanelOpen] = useState(false);

  const [prebuildingCompanyPanelOpen, setPrebuildingCompanyPanelOpen] = useState(false);
  const [prebuildingCompanyCollaboratorsPanelOpen, setPrebuildingCompanyCollaboratorsPanelOpen] =
    useState(false);

  useEffect(() => {
    fetchPrebuildingList();
  }, []);

  const fetchPrebuildingList = async () => {
    await privateFetch('GET', `/project/${navigationContext.activeProject.id}/prebuilding/`).then(
      (res) => {
        if (res) setPrebuildingData(res);
      }
    );
  };

  const createPrebuilding = async (prebuildingForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/project/${navigationContext.activeProject.id}/prebuilding/`,
      `L'étude ${prebuildingForm.name} bien été créée.`,
      JSON.stringify(prebuildingForm)
    ).then(() => {
      setPrebuildingPanelOpen(false);
      setLoading(false);
      fetchPrebuildingList();
      fetchProjectBudget();
    });
  };

  const updatePrebuilding = async (prebuildingForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/project/${navigationContext.activeProject.id}/prebuilding/${prebuilding.id}/`,
      `L'étude ${prebuildingForm.name} bien été modifiée.`,
      JSON.stringify(prebuildingForm)
    ).then(() => {
      setPrebuildingPanelOpen(false);
      setLoading(false);
      fetchPrebuildingList();
      fetchProjectBudget();
    });
  };

  const deletePrebuilding = async (targetPrebuilding) => {
    setLoading(true);
    await privateFetch(
      'DELETE',
      `/project/${navigationContext.activeProject.id}/prebuilding/${targetPrebuilding.id}/`,
      `L'étude a bien été supprimée`
    ).then(() => {
      setPrebuildingPanelOpen(false);
      setLoading(false);
      setPrebuilding(undefined);
      setInvoice(undefined);
      fetchPrebuildingList();
      fetchProjectBudget();
    });
  };

  const updatePrebuildingCompany = async (prebuildingForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/project/${navigationContext.activeProject.id}/prebuilding/${prebuilding.id}/company/`,
      `Le prestataire a bien été assigné à l'étude.`,
      JSON.stringify(prebuildingForm)
    ).then(() => {
      setPrebuildingCompanyPanelOpen(false);
      setLoading(false);
      fetchPrebuildingList();
      fetchProjectCompanyList();
    });
  };

  const updatePrebuildingCompanyCollaborators = async (form) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/project/${navigationContext.activeProject.id}/prebuilding/${prebuilding.id}/collaborator/`,
      `Les contacts associés ont bien été enregistrés. `,
      JSON.stringify(form)
    ).then(() => {
      setPrebuildingCompanyCollaboratorsPanelOpen(false);
      setLoading(false);
      fetchPrebuildingList();
    });
  };

  const createInvoice = async (invoiceForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/project/${navigationContext.activeProject.id}/prebuilding/${prebuilding.id}/invoice/`,
      `La facture ${invoiceForm.label} a bien été ajoutée à ${prebuilding.name}`,
      JSON.stringify(invoiceForm)
    ).then(() => {
      setInvoicePanelOpen(false);
      setLoading(false);
      setPrebuilding(undefined);
      fetchPrebuildingList();
      fetchProjectBudget();
    });
  };

  const updateInvoice = async (invoiceForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/project/${navigationContext.activeProject.id}/invoice/${invoice.id}/`,
      `La facture ${invoiceForm.label} a bien été modifiée`,
      JSON.stringify(invoiceForm)
    ).then(() => {
      setInvoicePanelOpen(false);
      setLoading(false);
      setPrebuilding(undefined);
      setInvoice(undefined);
      fetchPrebuildingList();
      fetchProjectBudget();
    });
  };

  const deleteInvoice = async (targetInvoice) => {
    setLoading(true);
    await privateFetch(
      'DELETE',
      `/project/${navigationContext.activeProject.id}/invoice/${targetInvoice.id}/`,
      `La facture a bien été supprimée`
    ).then(() => {
      setInvoicePanelOpen(false);
      setLoading(false);
      setPrebuilding(undefined);
      setInvoice(undefined);
      fetchPrebuildingList();
      fetchProjectBudget();
    });
  };

  const handleCreatePrebuilding = () => {
    setPrebuilding(undefined);
    setPrebuildingPanelOpen(true);
  };

  const handleUpdatePrebuilding = (targetPrebuilding) => {
    setPrebuilding(targetPrebuilding);
    setPrebuildingPanelOpen(true);
  };

  const handleUpdatePrebuildingCompany = (targetPrebuilding) => {
    setPrebuilding(targetPrebuilding);
    setPrebuildingCompanyPanelOpen(true);
  };

  const handleCreateInvoice = (targetPrebuilding) => {
    setInvoice(undefined);
    setPrebuilding(targetPrebuilding);
    setInvoicePanelOpen(true);
  };

  const handleUpdateInvoice = (targetPrebuilding, targetInvoice) => {
    setInvoice(targetInvoice);
    setPrebuilding(targetPrebuilding);
    setInvoicePanelOpen(true);
  };

  const handleDeleteInvoice = (targetPrebuilding, targetInvoice) => {
    setPrebuilding(targetPrebuilding);
    setInvoice(targetInvoice);
    confirmInvoiceDeletion(targetPrebuilding, targetInvoice);
  };

  const handleUpdateAssociatedCollaborators = (targetPrebuilding) => {
    setPrebuilding(targetPrebuilding);
    setPrebuildingCompanyCollaboratorsPanelOpen(true);
  };

  const confirmInvoiceDeletion = (targetPrebuilding, targetInvoice) => {
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer la facture ${targetInvoice.label} de la tâche ${targetPrebuilding.name} ?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmer la suppression',
      acceptClassName: 'bg-custom-red border-custom-red',
      rejectLabel: 'Annuler',
      accept: () => deleteInvoice(targetInvoice)
    });
  };

  const confirmPrebuildingDeletion = (targetPrebuilding) => {
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer la tâche ${targetPrebuilding.name} ?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmer la suppression',
      acceptClassName: 'bg-custom-red border-custom-red',
      rejectLabel: 'Annuler',
      accept: () => deletePrebuilding(targetPrebuilding)
    });
  };

  const generalSectionActions = [
    {
      onClick: handleCreatePrebuilding,
      label: 'Ajouter une tâche',
      icon: 'pi-plus'
    }
  ];

  return (
    <div className="data-section accordion-tab">
      <Section key="general" label="Général" actions={generalSectionActions}>
        <div className="data-section-row">
          <Data
            key="prebuilding_nb"
            label={'Nb de précons.'}
            value={prebuildingData?.prebuilding_list?.length}
            fontColor="text-slate-800"
          />
          <Data
            key="validated_nb"
            label={'dont terminées'}
            value={prebuildingData?.prebuilding_list?.filter((s) => s.state == 'validated').length}
            fontColor="text-custom-green-dark"
          />
          <Data
            key="in_progress_nb"
            label={'dont commandées'}
            value={
              prebuildingData?.prebuilding_list?.filter((s) => s.state == 'in_progress').length
            }
            fontColor="text-custom-orange"
          />
          <Data
            key="total_expected_budget"
            label={'Budget total estimé'}
            value={applyCurrencyFormat(prebuildingData?.total_expected_budget)}
            suffix={'HT'}
          />
          <Data
            key="total_amount"
            label={'dont facturé'}
            value={applyCurrencyFormat(prebuildingData?.total_amount)}
            suffix={'HT'}
          />
        </div>
      </Section>
      <Divider />
      {prebuildingData?.prebuilding_list?.map((s, i) => (
        <div key={i} className="flex flex-col  gap-y-8">
          <ProjectPrebuildingDetail
            key={s.id}
            prebuilding={s}
            handleUpdatePrebuilding={() => handleUpdatePrebuilding(s)}
            handleUpdatePrebuildingCompany={() => handleUpdatePrebuildingCompany(s)}
            handleCreateInvoice={() => handleCreateInvoice(s)}
            handleUpdateInvoice={(invoice) => handleUpdateInvoice(s, invoice)}
            handleDeleteInvoice={(invoice) => handleDeleteInvoice(s, invoice)}
            handleUpdateAssociatedCollaborators={() => handleUpdateAssociatedCollaborators(s)}
          />
          {i + 1 != prebuildingData?.prebuilding_list.length && <Divider />}
        </div>
      ))}

      <ManagePrebuilding
        loading={loading}
        isOpen={prebuildingPanelOpen}
        prebuilding={prebuilding}
        handleClose={() => {
          setPrebuildingPanelOpen(false);
        }}
        createPrebuilding={createPrebuilding}
        updatePrebuilding={updatePrebuilding}
        deletePrebuilding={() => confirmPrebuildingDeletion(prebuilding)}
      />

      <ManagePrebuildingCompany
        loading={loading}
        isOpen={prebuildingCompanyPanelOpen}
        handleClose={() => {
          setPrebuildingCompanyPanelOpen(false);
        }}
        updatePrebuildingCompany={updatePrebuildingCompany}
      />

      <ManagePrebuildingCompanyCollaborators
        loading={loading}
        prebuilding={prebuilding}
        isOpen={prebuildingCompanyCollaboratorsPanelOpen}
        handleClose={() => {
          setPrebuildingCompanyCollaboratorsPanelOpen(false);
        }}
        updatePrebuildingCompanyCollaborators={updatePrebuildingCompanyCollaborators}
      />

      <ManageInvoice
        invoice={invoice}
        loading={loading}
        isOpen={invoicePanelOpen}
        handleClose={() => {
          setInvoicePanelOpen(false);
        }}
        createInvoice={createInvoice}
        updateInvoice={updateInvoice}
      />
    </div>
  );
}

export default ProjectPrebuilding;

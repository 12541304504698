import React from 'react';
import PanelEventListItem from './PanelEventListItem';
import { InputText } from 'primereact/inputtext';
import { useProjectEventContext } from '../../../../contexts/ProjectEventContext';
import { usePropertyContext } from '../../../../contexts/PropertyContext';
import { useCollaboratorContext } from '../../../../contexts/CollaboratorContext';
import PanelFilter from '../../PanelFilter';

function PanelEventList({ eventList, handleSearch, handleEventUpdate }) {
  const { projectEventContext, setProjectEventType, setProjectEventAuthor } =
    useProjectEventContext();
  const { propertyContext } = usePropertyContext();
  const { collaboratorContext } = useCollaboratorContext();

  const emptyEventList = (
    <div className="h-[calc(100vh-400px)] w-full flex items-center justify-center italic text-slate-400">
      <span>Aucune décision à afficher</span>
    </div>
  );

  return (
    <div className="flex flex-col gap-y-3 h-full">
      <span className={`p-input-icon-left w-full`}>
        <i className={`pi pi-search`} />
        <InputText
          defaultValue={projectEventContext.query}
          placeholder="Rechercher une décision"
          className="w-full p-inputtext-sm"
          onChange={(e) => handleSearch(e.target.value)}
        />
      </span>

      <div className="flex flew-row gap-x-2">
        <PanelFilter
          emptyLabel={'Tous les types'}
          options={propertyContext.filter((p) => p.type === 'event')}
          selectedValue={projectEventContext.type}
          onSelect={setProjectEventType}
          showFilter
        />
        <PanelFilter
          key="collaborator"
          emptyLabel={'Tous les utilisateurs'}
          options={collaboratorContext.internalCollaborators}
          selectedValue={projectEventContext.author}
          onSelect={setProjectEventAuthor}
          optionLabel={'alias'}
          showFilter
        />
      </div>

      <div className="flex flex-col gap-y-3 overflow-y-scroll pb-20">
        {!eventList.length && emptyEventList}
        {eventList.map((c) => (
          <PanelEventListItem key={c.id} event={c} handleEventUpdate={() => handleEventUpdate(c)} />
        ))}
      </div>
    </div>
  );
}

export default PanelEventList;

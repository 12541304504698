import React from 'react';
import { useNavigationContext } from '../../../contexts/NavigationContext';
import PanelbarItem from '../PanelbarItem';

function Panelbar() {
  const { navigationContext, toggleDashboardPanel } = useNavigationContext();

  return (
    <div className="w-20 h-full bg-slate-800 flex flex-col">
      <PanelbarItem
        key={'collaborator'}
        icon={'contact'}
        className={'h-16'}
        onClick={() => toggleDashboardPanel('collaborator')}
        isActive={navigationContext.activeDashboardPanel === 'collaborator'}
      />
      <PanelbarItem
        key={'company'}
        icon={'company'}
        className={'h-16'}
        onClick={() => toggleDashboardPanel('company')}
        isActive={navigationContext.activeDashboardPanel === 'company'}
      />
      <PanelbarItem
        key={'column'}
        icon={'column'}
        className={'h-16'}
        onClick={() => toggleDashboardPanel('column')}
        isActive={navigationContext.activeDashboardPanel === 'column'}
      />
    </div>
  );
}

export default Panelbar;

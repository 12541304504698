import React from 'react';
import { InputText } from 'primereact/inputtext';
import { useProjectCommentContext } from '../../../../contexts/ProjectCommentContext';
import { useCollaboratorContext } from '../../../../contexts/CollaboratorContext';
import PanelFilter from '../../PanelFilter';
import { usePropertyContext } from '../../../../contexts/PropertyContext';
import PanelCommentListItem from './PanelCommentListItem';

function PanelCommentList({ commentList, handleSearch, handleCommentUpdate }) {
  const { projectCommentContext, setProjectCommentType, setProjectCommentAuthor } =
    useProjectCommentContext();
  const { propertyContext } = usePropertyContext();
  const { collaboratorContext } = useCollaboratorContext();

  const emptyCommentList = (
    <div className="h-[calc(100vh-400px)] w-full flex items-center justify-center italic text-slate-400">
      <span>Aucun commentaire à afficher</span>
    </div>
  );

  return (
    <div className="flex flex-col gap-y-3 h-full">
      <span className={`p-input-icon-left w-full`}>
        <i className={`pi pi-search`} />
        <InputText
          defaultValue={projectCommentContext.query}
          placeholder="Rechercher un commentaire"
          className="w-full p-inputtext-sm"
          onChange={(e) => handleSearch(e.target.value)}
        />
      </span>
      <div className="flex flew-row gap-x-2">
        <PanelFilter
          key="type"
          emptyLabel={'Tous les types'}
          options={propertyContext.filter((p) => p.type === 'comment')}
          selectedValue={projectCommentContext.type}
          onSelect={setProjectCommentType}
          showFilter
        />
        <PanelFilter
          key="collaborator"
          emptyLabel={'Tous les utilisateurs'}
          options={collaboratorContext.internalCollaborators}
          selectedValue={projectCommentContext.author}
          onSelect={setProjectCommentAuthor}
          optionLabel={'alias'}
          showFilter
        />
      </div>

      <div className="flex-1 overflow-y-scroll">
        <div className="flex flex-col gap-y-3 h-auto pb-20">
          {!commentList.length && emptyCommentList}
          {commentList.map((c) => (
            <PanelCommentListItem
              key={c.id}
              comment={c}
              handleCommentUpdate={() => handleCommentUpdate(c)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PanelCommentList;

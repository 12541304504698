import React from 'react';
import CustomIcon from '../CustomIcon';

function NavbarItem({ icon, label, isActive, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`flex flex-row items-center py-2 px-3 h-full gap-x-3 rounded transition-colors whitespace-nowrap ${
        isActive
          ? 'opacity-100 text-yellow-500'
          : 'opacity-80 text-slate-50 hover:opacity-100 hover:bg-slate-900 hover:cursor-pointer'
      }`}>
      <CustomIcon icon={icon} className={'h-6 w-6'} />
      {label && <span className={` text-xl font-medium`}>{label}</span>}
    </div>
  );
}

export default NavbarItem;

import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { getConstant } from '../../../utils/constantHelper';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { Tag } from 'primereact/tag';
import CustomIcon from '../../../components/CustomIcon';

function AdminClients({ clientList, handleClientCreation, handleClientUpdate }) {
  const editBodyTemplate = (rowData) => (
    <Button
      onClick={() => handleClientUpdate(rowData)}
      icon="pi pi-pencil text-xs"
      severity={'secondary'}
      rounded
      outlined
    />
  );

  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex flex-row w-full justify-between items-center">
        <div className="flex flex-row gap-x-2 items-center text-slate-800">
          <CustomIcon icon={'company'} className="h-9 w-9 p-1" />
          <h4>{`${clientList.length} clients`}</h4>
        </div>
        <Button
          key="account"
          label={'Nouveau client'}
          size="small"
          outlined
          iconPos="left"
          icon="pi pi-plus"
          onClick={handleClientCreation}
        />
      </div>

      <DataTable
        value={clientList}
        removableSort
        scrollable={true}
        size={'small'}
        scrollHeight="100%"
        selectionMode="single">
        <Column className="w-12" body={editBodyTemplate}></Column>
        <Column sortable className="font-bold" field="name" header="Nom de l'entreprise"></Column>
      </DataTable>
    </div>
  );
}

export default AdminClients;

import React from 'react';
import RecoverPasswordForm from './RecoverPasswordForm';
import Layout from '../Layout';

function RecoverPassword() {
  return (
    <Layout>
      <RecoverPasswordForm />
    </Layout>
  );
}

export default RecoverPassword;

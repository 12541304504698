import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import FormInput from '../../../components/Form/FormInput';
import { Form } from 'react-router-dom';

function ManageCity({ city, isOpen, handleClose, updateCity, loading }) {
  const [name, setName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [departement, setDepartement] = useState('');
  const [region, setRegion] = useState('');

  useEffect(() => {
    if (city) {
      setName(city.name);
      setZipCode(city?.zip_code || '');
      setDepartement(city.departement);
      setRegion(city.region);
    }
  }, [isOpen, city]);

  const onValidate = () => {
    const cityForm = {
      name,
      zip_code: zipCode,
      departement,
      region
    };
    updateCity(cityForm);
  };

  const formIsValid = name.length && zipCode.length && departement.length && region.length;

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={'Modifier la ville'}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={<h2 className="text-slate-800">{`Modifier une ville`}</h2>}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="manager-form-wrapper">
          <FormInput key="name" label="Nom" required isValid={name.length}>
            <InputText
              value={name}
              placeholder="Veuillez saisir le nom de la ville"
              onChange={(e) => setName(e.target.value)}
            />
          </FormInput>
          <FormInput key="zipCode" label="Code postal" required isValid={zipCode.length}>
            <InputText
              value={zipCode}
              placeholder="Veuillez saisir le code postal de la ville"
              onChange={(e) => setZipCode(e.target.value)}
            />
          </FormInput>
          <FormInput key="departement" label="Département" required isValid={departement.length}>
            <InputText
              value={departement}
              placeholder="Veuillez saisir le département de la ville"
              onChange={(e) => setDepartement(e.target.value)}
            />
          </FormInput>
          <FormInput key="region" label="Région" required isValid={region.length}>
            <InputText
              value={region}
              placeholder="Veuillez saisir la région de la ville"
              onChange={(e) => setRegion(e.target.value)}
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageCity;

import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import Data from '../../../components/Display/Data';
import { formatAlias, formatCity } from '../../../utils/stringHelper';
import MultipleDataTag from '../../../components/Display/MultipleDataTag';
import DataTag from '../../../components/Display/DataTag';
import { getConstant } from '../../../utils/constantHelper';
import Section from '../../../components/Display/Section';
import { useProjectCompanyContext } from '../../../contexts/ProjectCompanyContext';

function ProjectOverview({
  project,
  handleProjectUpdate,
  handleStepUpdate,
  openCollaboratorPanel
}) {
  const { openProjectCompanyDetail } = useProjectCompanyContext();
  const buildStepTags = () => {
    let stepTags = [];
    // Identification
    stepTags.push({
      ...getConstant('project.steps.identification_step.shortLevels', project.identification_step),
      onClick: () => handleStepUpdate('identification_step')
    });
    // Securisation
    project.securisation_step !== null &&
      stepTags.push({
        ...getConstant('project.steps.securisation_step.shortLevels', project.securisation_step),
        onClick: () => handleStepUpdate('securisation_step')
      });
    // Etude
    project.study_step !== null &&
      stepTags.push({
        ...getConstant('project.steps.study_step.shortLevels', project.study_step),
        onClick: () => handleStepUpdate('study_step')
      });
    // Instruction
    project.instruction_step !== null &&
      stepTags.push({
        ...getConstant('project.steps.instruction_step.shortLevels', project.instruction_step),
        onClick: () => handleStepUpdate('instruction_step')
      });
    // Post-instruction
    project.prebuilding_step !== null &&
      stepTags.push({
        ...getConstant('project.steps.prebuilding_step.shortLevels', project.prebuilding_step),
        onClick: () => handleStepUpdate('prebuilding_step')
      });
    return stepTags;
  };

  const sectionActions = [
    {
      onClick: handleProjectUpdate,
      label: 'Modifier',
      icon: 'pi-pencil'
    }
  ];

  return (
    <div className="flex flex-col">
      <Section label="Informations générales" actions={sectionActions}>
        {/* Informations */}
        <div className="data-section-row">
          <Data key="name" label={'Nom'} value={project.name} large />
          <Data key="city" label={'Ville'} value={formatCity(project.city)} large />
          <DataTag
            key="manager"
            label="Chef de projet"
            tag={{
              label: formatAlias(project.manager),
              icon: 'contact'
            }}
            onClick={() => openCollaboratorPanel(project.manager)}
          />
          <MultipleDataTag
            key="observers"
            label={'Observateurs'}
            tags={project.observers.map((observer) => ({
              label: formatAlias(observer),
              icon: 'contact',
              onClick: () => openCollaboratorPanel(observer)
            }))}
          />
        </div>
        <div className="data-section-row">
          <DataTag
            key="state"
            label={'Etat'}
            tag={getConstant('project.state', project.state)}
            rounded
          />
          <DataTag
            key="technology"
            label={'Technologie'}
            tag={getConstant('project.technology', project.technology)}
            rounded
          />

          <Data
            key="estimate_power"
            label={'Puissance estimée'}
            value={project.estimate_power}
            suffix="MW"
          />

          <DataTag
            key="client"
            label={'Client'}
            tag={{
              label: project.client?.name,
              icon: 'company'
            }}
            onClick={() => openProjectCompanyDetail(project?.client)}
          />
          <DataTag
            key="spv"
            label={'SPV'}
            tag={{
              label: project.spv?.name,
              icon: 'company'
            }}
            onClick={() => openProjectCompanyDetail(project?.spv)}
          />
        </div>
        <div className="data-section-row">
          <MultipleDataTag key="steps" label={'Phases'} tags={buildStepTags()} rounded />
        </div>
      </Section>
    </div>
  );
}

export default ProjectOverview;

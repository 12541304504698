import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { CONSTANT } from '../../../utils/constantHelper';
import { Dropdown } from 'primereact/dropdown';
import FormInput from '../../../components/Form/FormInput';
import { Button } from 'primereact/button';

function ManageProperty({ isOpen, handleClose, mandatoryType, handleCreate }) {
  const [label, setLabel] = useState('');
  const [type, setType] = useState(undefined);
  const labelIsValid = label.length > 2;
  const formIsValid = labelIsValid && type;

  useEffect(() => {
    setLabel('');
    setType(mandatoryType || CONSTANT.misc.propertyTypes[0]);
  }, [isOpen]);

  const onValidate = () => {
    const payload = {
      label,
      type
    };
    handleCreate(payload);
  };

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={'Créer le tag'}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
        className="w-full"
      />
    </div>
  );

  return (
    <Dialog
      header="Nouveau tag"
      draggable={false}
      visible={isOpen}
      footer={footerButtons}
      className="h-auto pb-15"
      onHide={handleClose}>
      <div className="dialog-content-container">
        <div className="manager-form-wrapper">
          <FormInput label={'Type de tag'} required isValid={type}>
            <Dropdown
              value={type}
              onChange={(e) => setType(e.value)}
              options={CONSTANT.misc.propertyTypes}
              optionLabel="label"
              optionValue="value"
              readOnly={mandatoryType}
              disabled={mandatoryType}
            />
          </FormInput>
          <FormInput key="title" label="Titre" required isValid={labelIsValid}>
            <InputText
              value={label}
              placeholder="Veuillez saisir le nom du tag"
              onChange={(e) => setLabel(e.target.value)}
            />
          </FormInput>
        </div>
      </div>
    </Dialog>
  );
}

export default ManageProperty;

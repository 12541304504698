import React from 'react';
import { useNavigationContext } from '../../../contexts/NavigationContext';
import Panelbar from './ProjectPanelbar';
import PanelCollaborator from './Collaborator/PanelCollaborator';
import PanelComment from './Comment/PanelComment';
import PanelEvent from './Event/PanelEvent';
import PanelCompany from './Company/PanelCompany';
import PanelMeeting from './Meeting/PanelMeeting';

import '../Panel.scss';

function ProjectPanel() {
  const { navigationContext } = useNavigationContext();

  return (
    <div className="flex flex-row h-full">
      <PanelCollaborator isActive={navigationContext.activeProjectPanel === 'collaborator'} />
      <PanelComment isActive={navigationContext.activeProjectPanel === 'comment'} />
      <PanelEvent isActive={navigationContext.activeProjectPanel === 'event'} />
      <PanelCompany isActive={navigationContext.activeProjectPanel === 'company'} />
      <PanelMeeting isActive={navigationContext.activeProjectPanel === 'meeting'} />

      <Panelbar />
    </div>
  );
}
export default ProjectPanel;

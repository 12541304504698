import React, { useState } from 'react';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { getConstant } from '../../../utils/constantHelper';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import Tag from '../../../components/Display/Tag';
import CustomIcon from '../../../components/CustomIcon';

function AdminAccounts({ accountList, handleAccountCreation, handleAccountUpdate }) {
  const [showFilters, setShowFilters] = useState(false);

  const accountStateBodyTemplate = (rowData) => {
    return (
      <i
        className={classNames('pi', {
          'true-icon pi-check-circle text-green-500': rowData.user.is_active,
          'false-icon pi-times-circle text-red-500': !rowData.user.is_active
        })}></i>
    );
  };

  const editBodyTemplate = (rowData) => (
    <Button
      onClick={() => handleAccountUpdate(rowData)}
      icon="pi pi-pencil text-xs"
      severity={'secondary'}
      rounded
      outlined
    />
  );

  return (
    <div className="flex flex-col gap-y-3 h-full">
      <div className="flex flex-row w-full justify-between items-center">
        <div className="flex flex-row gap-x-1 items-center text-slate-800">
          <CustomIcon icon={'user'} className="h-9 w-9 p-1" />
          <h4>{`${accountList.length} comptes`}</h4>
        </div>
        <div className="flex flex-row gap-x-3">
          <Button
            key="filters"
            size="small"
            outlined={!showFilters}
            iconPos="left"
            icon={showFilters ? 'pi pi-filter text-slate-50' : 'pi pi-filter'}
            onClick={() => setShowFilters(!showFilters)}
          />
          <Button
            key="account"
            label={'Nouveau compte'}
            size="small"
            outlined
            iconPos="left"
            icon="pi pi-plus"
            onClick={handleAccountCreation}
          />
        </div>
      </div>

      <DataTable
        value={accountList}
        removableSort
        scrollable
        scrollHeight="100%"
        size={'small'}
        filterDisplay={showFilters && 'row'}
        selectionMode="single">
        <Column className="w-12" body={editBodyTemplate}></Column>

        <Column
          sortable
          className="font-bold"
          field="collaborator.first_name"
          header="Prénom"
          filter
          filterMatchMode={FilterMatchMode.CONTAINS}
          showFilterMenu={false}></Column>
        <Column
          sortable
          className="font-bold"
          field="collaborator.name"
          header="Nom"
          filter
          filterMatchMode={FilterMatchMode.CONTAINS}
          showFilterMenu={false}></Column>
        <Column
          sortable
          field="type"
          header="Role"
          body={(data) => <Tag tag={getConstant('admin.accountType', data.type)} />}></Column>
        <Column
          sortable
          field="client.name"
          header="Client"
          body={(data) => <Tag tag={{ label: data.client?.name }} />}></Column>
        <Column sortable field="email" header="Email"></Column>
        <Column sortable field="collaborator.phone" header="Téléphone"></Column>
        <Column
          sortable
          field="user.is_active"
          header="Compte actif"
          body={accountStateBodyTemplate}></Column>
      </DataTable>
    </div>
  );
}

export default AdminAccounts;

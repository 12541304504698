import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import FormInput from '../../../../../components/Form/FormInput';
import { InputText } from 'primereact/inputtext';
import { getDateStringOrUndefined } from '../../../../../utils/stringHelper';
import moment from 'moment';
import { InputTextarea } from 'primereact/inputtextarea';

function ManageInvoice({ isOpen, handleClose, createInvoice, updateInvoice, loading, invoice }) {
  const [label, setLabel] = useState('');
  const [amount, setAmount] = useState(undefined);
  const [date, setDate] = useState(undefined);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (invoice) {
      setLabel(invoice.label);
      setAmount(invoice.amount);
      setDescription(invoice.description);
      setDate(new Date(invoice.date));
    } else {
      setLabel('');
      setAmount(undefined);
      setDescription('');
      setDate(new Date(moment()));
    }
  }, [isOpen]);

  const onValidate = () => {
    const invoiceForm = {
      label,
      amount,
      date: getDateStringOrUndefined(date),
      description
    };
    invoice ? updateInvoice(invoiceForm) : createInvoice(invoiceForm);
  };

  const contentIsValid = label.length > 2 && amount && date;

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={`Valider la ${invoice ? 'modification' : 'création'}`}
        loading={loading}
        disabled={!contentIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 530, paddingLeft: 10, paddingRight: 10 }}
      header={<h2 className="text-slate-800">{`${invoice ? 'Modifier' : 'Créer'} une facture`}</h2>}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="manager-form-wrapper">
          <FormInput
            key="label"
            label="Numéro"
            required
            isValid={label.length > 2}
            helpMessage={'200 car. max'}>
            <InputText
              value={label}
              placeholder="Veuillez saisir le numéro de facture"
              onChange={(e) => setLabel(e.target.value)}
              maxLength={200}
            />
          </FormInput>

          <FormInput label={'Montant'} required isValid={amount} helpMessage={'en € HT'}>
            <InputNumber
              value={amount}
              placeholder="Veuillez saisir le montant de la facture"
              onChange={(e) => setAmount(e.value)}
              min={0}
              suffix={' € HT'}
              locale="fr-FR"
            />
          </FormInput>

          <FormInput key="date" label="Date de réglement" required isValid={date}>
            <Calendar
              value={date}
              onChange={(e) => setDate(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date de réglement"
              locale="fr"
            />
          </FormInput>

          <FormInput key="description" label="Description" helpMessage={'200 car. max'}>
            <InputTextarea
              rows={5}
              autoResize
              value={description}
              placeholder="Veuillez saisir la description de la facture"
              onChange={(e) => setDescription(e.target.value)}
              maxLength={200}
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageInvoice;

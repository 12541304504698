import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import { privateFetch } from '../../../../utils/apiHelper';
import PanelEventList from './PanelEventList';
import ManageEvent from './ManageEvent';
import { useProjectEventContext } from '../../../../contexts/ProjectEventContext';
import { useDebouncedCallback } from 'use-debounce';

function PanelEvent({ isActive }) {
  const { navigationContext, toggleProjectPanel } = useNavigationContext();
  const {
    projectEventContext,
    fetchProjectEventList,
    setProjectEventQuery,
    fetchProjectLastEvent
  } = useProjectEventContext();

  const [event, setEvent] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [eventManagerIsOpen, setEventManagerIsOpen] = useState(false);

  useEffect(() => {
    fetchProjectEventList();
    fetchProjectLastEvent();
  }, [navigationContext.activeProject?.id]);

  const createEvent = async (eventForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/project/${navigationContext.activeProject.id}/event/`,
      `Le eventaire a bien été créé.`,
      JSON.stringify(eventForm)
    ).then(() => {
      setLoading(false);
      setEventManagerIsOpen(false);
      fetchProjectEventList();
      fetchProjectLastEvent();
    });
  };

  const updateEvent = async (eventForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/event/${event.id}/`,
      `Le eventaire a bien été modifié.`,
      JSON.stringify(eventForm)
    ).then(() => {
      setLoading(false);
      setEventManagerIsOpen(false);
      fetchProjectEventList();
      fetchProjectLastEvent();
    });
  };

  const handleEventUpdate = (event) => {
    setEvent(event);
    setEventManagerIsOpen(true);
  };

  const debouncedQuery = useDebouncedCallback((query) => {
    setProjectEventQuery(query);
  }, 500);

  return (
    <div
      className={`h-full w-[450px] bg-white flex flex-col border-l py-3 px-5 gap-y-3 ${
        isActive ? '' : 'hidden'
      }`}>
      <div className="flex flex-row w-full justify-between items-center">
        <h3 className="text-2xl text-slate-900 font-semibold">{'Décisions importantes'}</h3>
        <div className="flex flex-row gap-x-1">
          <Button
            key="add"
            text
            rounded
            icon={'pi pi-plus'}
            onClick={() => {
              setEvent(undefined);
              setEventManagerIsOpen(true);
            }}
          />
          <Button
            key="close"
            text
            rounded
            icon={'pi pi-times'}
            onClick={() => {
              toggleProjectPanel(undefined);
            }}
          />
        </div>
      </div>

      <div className="panel-content-list-container">
        <PanelEventList
          eventList={projectEventContext.events}
          handleEventUpdate={handleEventUpdate}
          handleSearch={(e) => debouncedQuery(e)}
        />
      </div>

      <ManageEvent
        loading={loading}
        isOpen={eventManagerIsOpen}
        event={event}
        handleClose={() => {
          setEventManagerIsOpen(false);
        }}
        createEvent={createEvent}
        updateEvent={updateEvent}
      />
    </div>
  );
}

export default PanelEvent;

import React, { createContext, useContext, useEffect, useState } from 'react';
import { privateFetch } from '../utils/apiHelper';
import { useNavigationContext } from './NavigationContext';

const ProjectCommentContext = createContext(null);

const initial = {
  query: '',
  type: undefined,
  author: undefined,
  comments: []
};

export const ProjectCommentContextProvider = ({ children }) => {
  const { navigationContext } = useNavigationContext();
  const [projectCommentContext, setProjectCommentContext] = useState(initial);

  useEffect(() => {
    if (navigationContext.activeProject.id) {
      fetchProjectCommentList();
    }
  }, [
    projectCommentContext.query,
    projectCommentContext.type,
    projectCommentContext.author,
    navigationContext.activeProject?.id
  ]);

  const setProjectCommentQuery = (query) => {
    setProjectCommentContext({
      ...projectCommentContext,
      query
    });
  };

  const setProjectCommentType = (type) => {
    setProjectCommentContext({
      ...projectCommentContext,
      type
    });
  };

  const setProjectCommentAuthor = (collaborator) => {
    setProjectCommentContext({
      ...projectCommentContext,
      author: collaborator
    });
  };

  const fetchProjectCommentList = async () => {
    await privateFetch(
      'GET',
      `/project/${navigationContext.activeProject.id}/comment/?q=${projectCommentContext.query}&t=${
        projectCommentContext.type || ''
      }&c=${projectCommentContext.author?.id || ''}`
    ).then((res) => {
      if (res) {
        setProjectCommentContext({
          ...projectCommentContext,
          comments: res
        });
      }
    });
  };

  return (
    <ProjectCommentContext.Provider
      value={{
        projectCommentContext,
        setProjectCommentContext,
        fetchProjectCommentList,
        setProjectCommentQuery,
        setProjectCommentType,
        setProjectCommentAuthor
      }}>
      {children}
    </ProjectCommentContext.Provider>
  );
};

export const useProjectCommentContext = () => {
  return useContext(ProjectCommentContext);
};

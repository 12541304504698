import React from 'react';
import Avatar from '../../../Display/Avatar';
import { getConstant } from '../../../../utils/constantHelper';
import CustomIcon from '../../../CustomIcon';

function PanelCompanyListItem({ company, selectCompany }) {
  return (
    <div
      className="w-full flex flex-row gap-x-3 p-3 border-b last:border-b-0 cursor-pointer hover:bg-slate-200"
      onClick={() => selectCompany(company)}>
      <CustomIcon icon={'companyPh'} className="h-12 w-12 min-w-[48px]" />
      <div className="flex flex-col justify-center ">
        <span className="font-semibold text-slate-800">{company.name}</span>
        <span className="text-sm text-slate-600">
          {getConstant('company.type', company.type).label}
        </span>
      </div>
    </div>
  );
}

export default PanelCompanyListItem;

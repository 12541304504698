import React from 'react';
import Tag from '../../../Display/Tag';
import Pill from '../../../Display/Pill';
import { formatDate } from '../../../../utils/stringHelper';
import { useProjectCollaboratorContext } from '../../../../contexts/ProjectCollaboratorContext';

function PanelEventListItem({ event, handleEventUpdate }) {
  const { openCollaboratorDetail } = useProjectCollaboratorContext();

  const propertyList = (
    <div className="flex flex-wrap flex-row gap-2">
      {event?.properties?.map((p) => (
        <Pill key={p.id} pill={{ label: p.label }} outlined />
      ))}
    </div>
  );

  if (!event) return null;
  return (
    <div className="w-full flex flex-col gap-y-2 pt-2 pb-3 px-3 rounded bg-slate-100">
      <div className="w-full flex flex-row justify-between py-1 items-center">
        <h4>{event.title}</h4>
      </div>
      <div className="w-full flex flex-col gap-y-3 pt-3 border-t">
        {event?.properties?.length ? propertyList : null}
        <p className="text-sm font-medium" dangerouslySetInnerHTML={{ __html: event.resume }} />
        <div className="text-sm list-html" dangerouslySetInnerHTML={{ __html: event.content }} />

        <div className="flex flex-row w-full justify-between items-center pt-3">
          <Tag
            tag={{
              label: event.author?.alias,
              icon: 'contact'
            }}
            onClick={() => openCollaboratorDetail(event.author)}
          />
          <span className="text-sm italic text-slate-500">{formatDate(event.last_update)}</span>
        </div>
      </div>
    </div>
  );
}

export default PanelEventListItem;

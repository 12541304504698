import React from 'react';
import Tag from '../../../Display/Tag';
import { useProjectCollaboratorContext } from '../../../../contexts/ProjectCollaboratorContext';
import moment from 'moment';

function PanelMeetingListItem({ meeting, isPanel }) {
  const { openCollaboratorDetail } = useProjectCollaboratorContext();

  return (
    <div className="w-full flex flex-col gap-y-2 pt-2 pb-3 px-3 rounded bg-slate-100">
      <div className="w-full flex flex-row justify-between py-1 items-center">
        <h4>{moment(meeting.date).format('ll')}</h4>
        <Tag
          tag={{
            label: meeting.author.alias,
            icon: 'contact'
          }}
          onClick={() => openCollaboratorDetail(meeting.author)}
        />
      </div>
      <div className="w-full  pt-3 border-t">
        {isPanel ? (
          <p className="text-sm">{meeting.content}</p>
        ) : (
          <p className="text-sm  truncate ">{meeting.content}</p>
        )}
      </div>
    </div>
  );
}

export default PanelMeetingListItem;

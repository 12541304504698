import React, { useState, useEffect, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import FormInput from '../../../components/Form/FormInput';
import { Message } from 'primereact/message';

function ManageDocument({
  accept,
  isOpen,
  handleClose,
  uploadDocument,
  updateDocument,
  deleteDocument,
  loading,
  document
}) {
  const fileUploadRef = useRef(null);
  const [fileName, setFileName] = useState(undefined);

  // Mandatory
  const [name, setName] = useState('');
  const [file, setFile] = useState(undefined);
  const [filePath, setFilePath] = useState('');

  useEffect(() => {
    if (document) {
      setName(document.name);
      setFilePath(document.file);
    } else {
      setName('');
      setFile(undefined);
      setFileName(undefined);
      setFilePath(undefined);
      fileUploadRef.current && fileUploadRef.current.clear();
    }
  }, [document, isOpen]);

  const onValidate = () => {
    const formData = new FormData();
    formData.append('name', name);
    // Update case only
    document && formData.append('id', document.id);
    // If file has changed
    file && formData.append('file', file);
    document ? updateDocument(formData) : uploadDocument(formData);
  };

  const onUpload = (event) => {
    if (event.files.length === 1) {
      const file = event.files[0];
      setFilePath(file.objectURL);
      setFile(file);
      setFileName(file.name);
      setName(file.name);
    }
  };

  const handleRemoveFile = () => {
    setFile(undefined);
    setFileName(undefined);
    setFilePath(undefined);
    fileUploadRef.current.clear();
  };

  const handleOpenDocument = () => {
    window.open(filePath, '_blank');
  };

  const formIsValid = name?.length > 2;
  const footerButtons = (
    <div className="manager-footer">
      {document && (
        <Button
          key={'delete'}
          label={`Supprimer le document`}
          onClick={deleteDocument}
          severity="danger"
          outlined
        />
      )}
      <Button
        key={'validate'}
        label={`${
          document ? (document.validated ? 'Modifier' : 'Valider') : 'Ajouter'
        } le document`}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={
        <h2 className="text-slate-800">{`${document ? `Modifier` : 'Ajouter'} un document`}</h2>
      }
      showCloseIcon={true}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="manager-form-wrapper">
          {document && (
            <FormInput label={'Télécharger le document'}>
              <div style={{ width: 'fit-content' }}>
                <Button label={name} icon="pi pi-cloud-download" onClick={handleOpenDocument} />
              </div>
            </FormInput>
          )}
          <FormInput
            label={'Nouveau fichier'}
            helpMessage={'Seuls les fichiers .kml sont acceptés'}>
            {fileName && (
              <div className="flex gap-x-3 text-custom-green font-medium items-center mb-2 mt-1">
                <span className="pi pi-check"></span>
                <span>{fileName}</span>
              </div>
            )}
            <div className="flex flex-row gap-x-3">
              {file && (
                <Button
                  key={'delete-img'}
                  label={'Changer de fichier'}
                  outlined
                  onClick={handleRemoveFile}
                  severity="primary"
                />
              )}
              <FileUpload
                ref={fileUploadRef}
                auto
                disabled={file}
                mode="basic"
                name="file"
                accept={accept || '*'}
                customUpload
                uploadHandler={onUpload}
                chooseLabel="Depuis mon ordinateur"
              />
            </div>
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageDocument;

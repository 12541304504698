import React, { useState, useEffect, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import FormInput from '../../../Form/FormInput';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { getDateStringOrUndefined } from '../../../../utils/stringHelper';

function ManageMeeting({ isOpen, handleClose, meeting, createMeeting, updateMeeting, loading }) {
  // Form
  const [date, setDate] = useState(undefined);
  const [content, setContent] = useState('');

  useEffect(() => {
    if (meeting) {
      setDate(meeting.title);
      setContent(meeting.content);
    } else {
      setDate(undefined);
      setContent('');
    }
  }, [isOpen, meeting]);

  const onValidate = () => {
    const meetingForm = {
      date: getDateStringOrUndefined(date),
      content
    };
    meeting ? updateMeeting(meetingForm) : createMeeting(meetingForm);
  };

  const dateIsValid = date;
  const contentIsValid = content.length > 2;

  const formIsValid = dateIsValid && contentIsValid;

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={meeting ? 'Modifier la décision' : 'Enregistrer la concertation'}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
        className="w-full"
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 530, paddingLeft: 10, paddingRight: 10 }}
      header={
        <h2 className="text-slate-800">{`${meeting ? 'Modifier' : 'Créer'} une concertation`}</h2>
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="manager-form-wrapper">
          <FormInput key="date" label="Date de la concertation" required isValid={dateIsValid}>
            <Calendar
              value={date}
              onChange={(e) => setDate(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date de la concertation"
              locale="fr"
            />
          </FormInput>

          <FormInput key="content" label={'Contenu'} required isValid={contentIsValid}>
            <InputTextarea
              rows={5}
              autoResize
              placeholder="Veuillez saisir le contenu"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageMeeting;

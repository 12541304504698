import React from 'react';
import Section from '../../../components/Display/Section';
import { CONSTANT, getConstant } from '../../../utils/constantHelper';
import Tag from '../../../components/Display/Tag';

function ProjectRisks({ project, onUpdate }) {
  return (
    <Section label="Risques" containerClassName="w-full">
      <div className="flex flex-col w-full">
        {project &&
          CONSTANT.project.riskTypes.map((rt, i) => (
            <div
              key={i}
              onClick={() => onUpdate(rt)}
              className="flex flex-row justify-between gap-x-20 w-full py-3 px-2 items-center border-b last:border-b-0 cursor-pointer hover:bg-slate-100">
              <span className="font-medium text-slate-800">{rt.label}</span>
              {project[rt.value] === null ? (
                <span className="text-sm italic text-slate-400 py-1">Non renseigné</span>
              ) : (
                <Tag
                  tag={{
                    label: getConstant('project.riskLevels', project[rt.value]).label,
                    bgColor: getConstant('project.riskLevels', project[rt.value]).bgColor
                  }}
                  rounded
                />
              )}
            </div>
          ))}
      </div>
    </Section>
  );
}

export default ProjectRisks;

import { MultiSelect } from 'primereact/multiselect';
import React, { useMemo } from 'react';

function DashboardFilter({ options, value, onChange, emptyLabel, optionLabel, showFilter }) {
  const isActive = useMemo(() => value && value.length, [value]);
  return (
    <MultiSelect
      value={value}
      onChange={(e) => onChange(e.target.value)}
      options={options}
      optionLabel={optionLabel || 'label'}
      placeholder={emptyLabel}
      scrollHeight={'350px'}
      className={`text-sm rounded-full pl-1  ${
        isActive ? 'active-custom-dropdown w-[300px]' : 'w-[200px] '
      }`}
      showSelectAll={false}
      showClear
      filter={showFilter}
    />
  );
}

export default DashboardFilter;

import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import FormInput from '../../../components/Form/FormInput';
import { CONSTANT } from '../../../utils/constantHelper';
import { Dropdown } from 'primereact/dropdown';
import { privateFetch } from '../../../utils/apiHelper';
import { AutoComplete } from 'primereact/autocomplete';
import { useAccountContext } from '../../../contexts/AccountContext';

function ManageProject({ isOpen, handleClose, createProject, loading }) {
  const { isAdmin, accountContext } = useAccountContext();
  // Form
  const [name, setName] = useState('');
  const [technology, setTechnology] = useState(CONSTANT.project.technology[0].value);
  const [manager, setManager] = useState(undefined);
  const [client, setClient] = useState(undefined);
  const [city, setCity] = useState(undefined);

  // Data
  const [clientList, setClientList] = useState([]);
  const [managerList, setManagerList] = useState([]);

  // Autocomplete
  const [filteredCities, setFilteredCities] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    fetchClientList();
    fetchManagerList();
  }, []);

  useEffect(() => {
    setName('');
    setTechnology(CONSTANT.project.technology[0].value);
    setManager(undefined);
    setCity(undefined);
    setQuery('');
    if (isAdmin) setClient(undefined);
    else setClient(accountContext.client);
  }, [isOpen]);

  const fetchManagerList = async () => {
    await privateFetch('GET', `/collaborator/manager/`).then((res) => {
      if (res) setManagerList(res);
    });
  };

  const fetchClientList = async () => {
    await privateFetch('GET', `/company/?t=client`).then((res) => {
      if (res) setClientList(res);
    });
  };

  const fetchCityList = async (q) => {
    await privateFetch('GET', `/city/autocomplete/?query=${q}`).then((res) => {
      setFilteredCities(res);
    });
  };

  const onValidate = () => {
    const projectForm = {
      name,
      technology,
      manager: manager.id,
      client: client.id
    };
    if (city) {
      projectForm['city'] = city.place_id;
    }
    createProject(projectForm);
  };

  const nameIsValid = name.length > 2;
  const formIsValid = nameIsValid && manager && client && city;

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={'Créer le projet'}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={<h2 className="text-slate-800">{`Créer un projet`}</h2>}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="manager-form-wrapper">
          <FormInput
            key="name"
            label="Nom"
            required
            isValid={nameIsValid}
            helpMessage={'Nom de la commune + lieu-dit'}>
            <InputText
              value={name}
              size={250}
              placeholder="Veuillez saisir le nom du projet"
              onChange={(e) => setName(e.target.value)}
            />
          </FormInput>

          <FormInput key="technology" label={'Technologie'}>
            <Dropdown
              id="technology"
              value={technology}
              onChange={(e) => setTechnology(e.value)}
              options={CONSTANT.project.technology}
              optionLabel="label"
            />
          </FormInput>

          <FormInput key="manager" label={'Chef de projet'} required isValid={manager}>
            <Dropdown
              id="manager"
              value={manager}
              onChange={(e) => setManager(e.value)}
              options={managerList}
              optionLabel="alias"
              placeholder="Veuillez sélectionner un un chef de projet"
              scrollHeight={300}
              filter={true}
            />
          </FormInput>

          <FormInput key="client" label={'Client'} required isValid={client}>
            <Dropdown
              id="client"
              value={client}
              onChange={(e) => setClient(e.value)}
              options={clientList}
              optionLabel="name"
              disabled={!isAdmin}
              placeholder="Veuillez sélectionner un client"
            />
          </FormInput>

          <FormInput label={'Commune'} required isValid={city}>
            <AutoComplete
              field="name"
              value={query}
              suggestions={filteredCities}
              completeMethod={(e) => fetchCityList(e.query)}
              onChange={(e) => {
                setQuery(e.value);
                setCity(undefined);
              }}
              onSelect={(e) => setCity(e.value)}
              placeholder="Veuillez sélectionner une ville"
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageProject;

import React, { createContext, useContext, useEffect, useState } from 'react';
import { privateFetch } from '../utils/apiHelper';
import { useNavigationContext } from './NavigationContext';
import ManageCompany from '../pages/private/Common/ManageCompany';

const CompanyContext = createContext(null);

const initial = {
  query: '',
  companies: [],
  company: undefined
};

export const CompanyContextProvider = ({ children }) => {
  const { navigationContext, toggleProjectPanel } = useNavigationContext();
  const [companyContext, setCompanyContext] = useState(initial);
  const [companyManagerVisible, setCompanyManagerVisible] = useState(false);
  const [companyDefaultType, setCompanyDefaultType] = useState(undefined);
  const [companyManagerAsDialog, setCompanyManagerAsDialog] = useState(false);

  useEffect(() => {
    fetchCompanyList();
  }, [companyContext.query, companyContext.type]);

  const getClientList = () => {
    return companyContext.companies.filter((c) => c.type === 'client');
  };

  const setCompanyQuery = (query) => {
    setCompanyContext({
      ...companyContext,
      query
    });
  };

  const setCompanyType = (type) => {
    setCompanyContext({
      ...companyContext,
      type
    });
  };

  const resetCompanyDetail = () => {
    setCompanyContext({
      ...companyContext,
      company: undefined
    });
  };

  const openCompanyDetail = (company) => {
    if (navigationContext.activeProjectPanel !== 'company') toggleProjectPanel('company');
    fetchCompanyDetail(company);
  };

  const fetchCompanyList = async () => {
    await privateFetch(
      'GET',
      `/company/?q=${companyContext.query}&t=${companyContext.type || ''}`
    ).then((res) => {
      if (res) {
        setCompanyContext({
          ...companyContext,
          companies: res
        });
      }
    });
  };

  const fetchCompanyDetail = async (targetCompany = companyContext.company) => {
    await privateFetch('GET', `/company/${targetCompany.id}/`).then((res) => {
      if (res) {
        setCompanyContext({
          ...companyContext,
          company: res
        });
      }
    });
  };

  const createCompany = async (companyForm) => {
    await privateFetch(
      'POST',
      `/company/`,
      `L'entreprise ${companyForm.name} a bien été créé.`,
      JSON.stringify(companyForm)
    ).then((res) => {
      setCompanyManagerVisible(false);
      fetchCompanyList();
    });
  };

  const handleCreateCompany = (asDialog = false, defaultType = undefined) => {
    setCompanyManagerAsDialog(asDialog);
    setCompanyManagerVisible(true);
    setCompanyDefaultType(defaultType);
  };

  return (
    <CompanyContext.Provider
      value={{
        companyContext,
        setCompanyContext,
        fetchCompanyList,
        // fetchCompanyDetail,
        setCompanyQuery,
        setCompanyType,
        resetCompanyDetail,
        openCompanyDetail,
        getClientList,
        handleCreateCompany
      }}>
      {children}
      <ManageCompany
        isOpen={companyManagerVisible}
        defaultType={companyDefaultType}
        handleCreateCompany={createCompany}
        asDialog={companyManagerAsDialog}
        handleClose={() => {
          setCompanyManagerVisible(false);
          setCompanyDefaultType(undefined);
        }}
      />
    </CompanyContext.Provider>
  );
};

export const useCompanyContext = () => {
  return useContext(CompanyContext);
};

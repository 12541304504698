import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { CONSTANT, getConstant } from '../../../../utils/constantHelper';
import FormInput from '../../../../components/Form/FormInput';
import { privateFetch } from '../../../../utils/apiHelper';
import { AutoComplete } from 'primereact/autocomplete';
import Tag from '../../../../components/Display/Tag';
import { InputNumber } from 'primereact/inputnumber';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import { Message } from 'primereact/message';

function ManageOwnership({
  isOpen,
  handleClose,
  createOwnership,
  updateOwnership,
  deleteParcel,
  loading,
  project,
  ownership,
  ownerList,
  defaultValues
}) {
  const { navigationContext } = useNavigationContext();

  // Backend informations about parcel existence
  const [parcelBackendState, setParcelBackendState] = useState('');

  // Form
  const [parcelSection, setParcelSection] = useState('');
  const [parcelNumber, setParcelNumber] = useState('');
  const [parcelSurface, setParcelSurface] = useState(undefined);
  const [parcelIsCritical, setParcelIsCritical] = useState(true);
  const [parcelCity, setParcelCity] = useState(undefined);
  const [state, setState] = useState(undefined);
  const [ownerId, setOwnerId] = useState(undefined);

  // Autocomplete
  const [filteredCities, setFilteredCities] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (!ownership) {
      const initialCity = defaultValues?.parcel?.city || project.city;
      setOwnerId(defaultValues?.owner.id);
      setParcelSection(defaultValues?.parcel?.section || '');
      setParcelNumber(defaultValues?.parcel?.number || '');
      setParcelSurface(defaultValues?.parcel?.surface || '');
      setParcelIsCritical(defaultValues?.parcel?.is_criritcal || true);
      setState(defaultValues?.state || 'held');
      setParcelCity(initialCity);
      setQuery(`${initialCity?.name}, France`);
    }
    // Update case
    else {
      setOwnerId(ownership.owner.id);
      setParcelSection(ownership.parcel.section);
      setParcelNumber(ownership.parcel.number);
      setParcelSurface(ownership.parcel.surface);
      setParcelIsCritical(ownership.parcel.is_critical);
      setState(ownership.state);
      setParcelCity(ownership.parcel.city);
      setQuery(`${ownership.parcel.city.name}, France`);
    }
  }, [isOpen]);

  useEffect(() => {
    if (parcelSection.length && parcelNumber.length) {
      fetchParcelBackendState();
    } else {
      setParcelBackendState(undefined);
    }
  }, [parcelNumber, parcelSection, ownerId, ownership]);

  const fetchCityList = async (q) => {
    await privateFetch('GET', `/city/autocomplete/?query=${q}`).then((res) => {
      setFilteredCities(res);
    });
  };

  const fetchParcelBackendState = async () => {
    await privateFetch(
      'GET',
      `/project/${
        navigationContext.activeProject.id
      }/securisation/ownership/state/?section=${parcelSection}&number=${parcelNumber}&exclude_parcel_id=${
        ownership?.parcel.id || ''
      }`
    ).then((res) => {
      setParcelBackendState(res);
    });
  };

  const onValidate = () => {
    const ownershipForm = {
      state,
      parcel: {
        section: parcelSection,
        number: parcelNumber,
        surface: parcelSurface,
        is_critical: parcelIsCritical,
        city: parcelCity.place_id
      },
      owner: ownerId
    };
    ownership ? updateOwnership(ownershipForm) : createOwnership(ownershipForm);
  };

  const onDelete = () => {
    deleteParcel();
  };

  const dropdownTemplate = (option, props) => {
    if (option) {
      return (
        <Tag
          tag={{
            label: option.label,
            bgColor: option.bgColor
          }}
          rounded
        />
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const ownerIsValid =
    ownership ||
    (parcelBackendState && !parcelBackendState.forbidden_owners_id.find((oId) => oId == ownerId));
  const formIsValid = parcelSection && parcelNumber && parcelCity && ownerIsValid;

  const footerButtons = (
    <div className="manager-footer">
      {ownership && (
        <Button
          key={'delete'}
          label={'Supprimer la parcelle'}
          loading={loading}
          onClick={onDelete}
          severity="danger"
          outlined
        />
      )}
      <Button
        key={'validate'}
        label={'Valider'}
        loading={loading}
        onClick={onValidate}
        severity="success"
        disabled={!formIsValid}
      />
    </div>
  );

  const parcelForm = (
    <div className="flex flex-col gap-y-5">
      <FormInput label={'Surface (m²)'}>
        <InputNumber
          value={parcelSurface}
          placeholder="Veuillez saisir la surface"
          onChange={(e) => setParcelSurface(e.value)}
          min={0}
          suffix={' m²'}
          locale="fr-FR"
        />
      </FormInput>
      <FormInput key="is_criritcal" label={'Type'}>
        <Dropdown
          value={parcelIsCritical}
          onChange={(e) => setParcelIsCritical(e.value)}
          options={CONSTANT.project.securisation.parcelTypes}
          optionLabel="label"
          valueTemplate={dropdownTemplate}
          itemTemplate={dropdownTemplate}
        />
      </FormInput>
      <FormInput label={'Commune'} required isValid={parcelCity}>
        <AutoComplete
          field="name"
          forceSelection
          value={query}
          suggestions={filteredCities}
          completeMethod={(e) => fetchCityList(e.query)}
          onChange={(e) => {
            setQuery(e.value);
            setParcelCity(undefined);
          }}
          onSelect={(e) => setParcelCity(e.value)}
          placeholder="Veuillez sélectionner une commune"
        />
      </FormInput>
      <FormInput key="state" label={"Etat de l'accord"}>
        <Dropdown
          value={state}
          onChange={(e) => setState(e.value)}
          options={CONSTANT.project.securisation.ownershipStates}
          optionLabel="label"
          valueTemplate={dropdownTemplate}
          itemTemplate={dropdownTemplate}
        />
      </FormInput>
    </div>
  );

  const ownershipValidator = () => {
    if (!parcelBackendState) return null;
    else if (!parcelBackendState.parcel_exists)
      return (
        <div className="flex flex-col gap-y-5">
          <Message severity="success" text="Cette parcelle n'existe pas encore" />
          {parcelForm}
        </div>
      );
    // Parcel is already linked to owner, form is not valid
    else if (!ownerIsValid)
      return <Message severity="error" text="Cette parcelle est déjà liée à ce propriétaire" />;
    else {
      return (
        <div className="flex flex-col gap-y-5">
          <Message severity="success" text="Cette parcelle n'est pas liée à ce propiétaire" />
          <FormInput key="state" label={"Etat de l'accord"}>
            <Dropdown
              value={state}
              onChange={(e) => setState(e.value)}
              options={CONSTANT.project.securisation.ownershipStates}
              optionLabel="label"
              valueTemplate={dropdownTemplate}
              itemTemplate={dropdownTemplate}
            />
          </FormInput>
        </div>
      );
    }
  };

  return (
    <Sidebar
      style={{ width: 530, paddingLeft: 10, paddingRight: 10 }}
      header={
        <h2 className="text-slate-800">{`${ownership ? 'Modifier' : 'Ajouter'} une parcelle`}</h2>
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="manager-form-wrapper">
          <FormInput key="owner" label={'Propriétaire'}>
            <Dropdown
              value={ownerId}
              onChange={(e) => setOwnerId(e.value)}
              options={ownerList}
              optionLabel="alias"
              optionValue="id"
              disabled={ownership}
            />
          </FormInput>

          <FormInput key="section" label="Section" required isValid={parcelSection}>
            <InputText
              value={parcelSection}
              placeholder="Veuillez saisir la section de la parcelle"
              onChange={(e) => setParcelSection(e.target.value)}
              disabled={ownership}
            />
          </FormInput>
          <FormInput key="number" label="Numéro" required isValid={parcelNumber}>
            <InputText
              value={parcelNumber}
              placeholder="Veuillez saisir le numéro de la parcelle"
              onChange={(e) => setParcelNumber(e.target.value)}
              disabled={ownership}
            />
          </FormInput>

          {ownership ? parcelForm : ownershipValidator()}
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageOwnership;

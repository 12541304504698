import React from 'react';
import Section from '../../../components/Display/Section';
import PanelMeetingListItem from '../../../components/Panel/Project/Meeting/PanelMeetingListItem';
import { useProjectMeetingContext } from '../../../contexts/ProjectMeetingContext';

function ProjectLastMeetings({ handleOpenInTab }) {
  const { projectAllMeetingContext } = useProjectMeetingContext();

  const kmlActions = [
    {
      label: 'Voir tout',
      icon: 'pi-arrow-up-right',
      onClick: handleOpenInTab
    }
  ];

  return (
    <Section label="Dernières concertations" actions={kmlActions}>
      {!projectAllMeetingContext.meetings.length ? (
        <div className="h-[400px] w-full flex items-center justify-center italic text-slate-400 border border-slate-200 rounded">
          <span>Aucune concertation pour le moment</span>
        </div>
      ) : (
        <div className="h-[400px] w-full flex flex-col gap-2 items-center justify-start border-slate-200  ">
          {projectAllMeetingContext.meetings.map((m) => (
            <PanelMeetingListItem meeting={m} key={m.id} />
          ))}
        </div>
      )}
    </Section>
  );
}

export default ProjectLastMeetings;

import React from 'react';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CustomIcon from '../../../components/CustomIcon';

function AdminCities({ cityList, handleCityUpdate }) {
  const editBodyTemplate = (rowData) => (
    <Button
      onClick={() => handleCityUpdate(rowData)}
      icon="pi pi-pencil text-xs"
      severity={'secondary'}
      rounded
      outlined
    />
  );

  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex flex-row w-full justify-between items-center">
        <div className="flex flex-row gap-x-2 items-center text-slate-800">
          <CustomIcon icon={'company'} className="h-9 w-9 p-1" />
          <h4>{`${cityList.length} villes`}</h4>
        </div>
      </div>

      <DataTable
        id={'dashboard-table-with-filters'}
        value={cityList}
        removableSort
        scrollable={true}
        paginator={true}
        rows={50}
        size={'small'}
        filterDisplay="row"
        selectionMode="single">
        <Column className="w-12" body={editBodyTemplate}></Column>
        <Column
          sortable
          className="font-bold"
          field="name"
          header="Nom de la commune"
          filter
          filterMatchMode={FilterMatchMode.CONTAINS}
          showFilterMenu={false}></Column>
        <Column sortable field="zip_code" header="Code postal"></Column>
        <Column sortable field="region" header="Région"></Column>
        <Column sortable field="departement" header="Département"></Column>
        <Column sortable field="place_id" header="ID Google Maps"></Column>
      </DataTable>
    </div>
  );
}

export default AdminCities;

import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import ProjectPage from './Project/ProjectPage';
import DashboardPage from './Dashboard/DashboardPage';
import AdminPage from './Admin/AdminPage';
import StandaloneProjectKml from './Misc/StandaloneProjectKml';

function Router() {
  return (
    <Routes>
      <Route exact path="/project" element={<ProjectPage />} />
      <Route exact path="/project/:id/kml" element={<StandaloneProjectKml />} />
      <Route exact path="/dashboard" element={<DashboardPage />} />
      <Route exact path="/admin" element={<AdminPage />} />
    </Routes>
  );
}

export default Router;

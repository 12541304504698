import React from 'react';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import { useProjectCollaboratorContext } from '../../../../contexts/ProjectCollaboratorContext';
import Section from '../../../../components/Display/Section';
import Data from '../../../../components/Display/Data';
import DataTag from '../../../../components/Display/DataTag';
import { applyCurrencyFormat, formatAlias, formatDate } from '../../../../utils/stringHelper';
import { getConstant } from '../../../../utils/constantHelper';
import MultipleDataTag from '../../../../components/Display/MultipleDataTag';
import { useProjectCompanyContext } from '../../../../contexts/ProjectCompanyContext';
import InvoiceDatatable from '../Shared/Invoice/InvoiceDatatable';

function ProjectPrebuildingDetail({
  prebuilding,
  handleUpdatePrebuilding,
  handleUpdatePrebuildingCompany,
  handleCreateInvoice,
  handleUpdateInvoice,
  handleDeleteInvoice,
  handleUpdateAssociatedCollaborators
}) {
  const { openCollaboratorDetail } = useProjectCollaboratorContext();
  const { openProjectCompanyDetail } = useProjectCompanyContext();

  const hasRelatedCompany = prebuilding?.company;

  const prebuildingSectionActions = () => {
    const actions = [
      {
        onClick: handleUpdatePrebuilding,
        label: 'Modifier la tâche',
        icon: 'pi-pencil'
      }
    ];
    if (hasRelatedCompany) {
      actions.unshift({
        onClick: handleCreateInvoice,
        label: 'Facture',
        icon: 'pi-plus'
      });
      actions.unshift({
        onClick: handleUpdateAssociatedCollaborators,
        label: 'Contacts associés',
        icon: 'pi-pencil'
      });
    } else {
      actions.unshift({
        onClick: handleUpdatePrebuildingCompany,
        label: 'Prestataire',
        icon: 'pi-plus'
      });
    }

    return actions;
  };

  return (
    <Section label={prebuilding.name} actions={prebuildingSectionActions()}>
      <div className="data-section-row">
        <DataTag
          key="type"
          label={'Type'}
          tag={getConstant('project.prebuilding.type', prebuilding.type)}
          rounded
        />
        <DataTag
          key="state"
          label={'Etat'}
          tag={getConstant('project.prebuilding.state', prebuilding.state)}
          rounded
        />
        <Data
          key="estimatedStartDate"
          label={'Date de début prévisionnelle'}
          value={formatDate(prebuilding.expected_start_dt)}
          large
        />
        <Data
          key="estimatedEndDate"
          label={'Date de fin prévisionnelle'}
          value={formatDate(prebuilding.expected_end_dt)}
          large
        />
      </div>
      <div className="data-section-row">
        <DataTag
          key="company"
          label="Prestataire"
          tag={{
            label: prebuilding?.company?.name,
            icon: 'company'
          }}
          onClick={() => openProjectCompanyDetail(prebuilding?.company)}
          large
        />

        <MultipleDataTag
          key="contacts"
          label={'Contacts associés'}
          tags={prebuilding?.collaborators.map((c) => ({
            label: c.alias,
            icon: 'contact',
            onClick: () => openCollaboratorDetail(c)
          }))}
        />
      </div>
      <div className="flex flex-col gap-y-5">
        <div className="data-section-row">
          <Data
            key="estimatedBudget"
            label={'Budget estimé'}
            value={applyCurrencyFormat(prebuilding.expected_budget)}
            suffix={'HT'}
            large
          />
          {hasRelatedCompany && (
            <Data
              key="total_inv"
              label={'Total facturé'}
              value={applyCurrencyFormat(prebuilding.total_amount)}
              large
              suffix={'HT'}
            />
          )}
        </div>
        {hasRelatedCompany && (
          <InvoiceDatatable
            invoiceList={prebuilding.invoices}
            handleDelete={handleDeleteInvoice}
            handleUpdate={handleUpdateInvoice}
          />
        )}
      </div>
    </Section>
  );
}

export default ProjectPrebuildingDetail;

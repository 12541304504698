import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { privateFetch, refreshAccesToken } from '../utils/apiHelper';
import { useAccountContext } from '../contexts/AccountContext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigationContext } from '../contexts/NavigationContext';
import { useLocation } from 'react-router-dom';

function AppLoader() {
  const navigate = useNavigate();
  const { setAccountContext, isLoggedIn } = useAccountContext();
  const { navigationContext } = useNavigationContext();
  const location = useLocation();

  const fetchAccountDetail = async () => {
    await privateFetch('GET', `/`).then((response) => {
      setAccountContext(response);
    });
  };

  const refreshSession = async () => {
    return await refreshAccesToken();
  };

  useEffect(() => {
    refreshSession().then((isSessionValid) => {
      if (!isSessionValid) {
        navigate('/login');
      } else {
        fetchAccountDetail();
      }
    });
  });

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/${navigationContext.activeApp}`);
    }
  }, [isLoggedIn, navigate]);

  return (
    <div className="flex justify-center items-center h-screen w-screen bg-slate-800">
      <ProgressSpinner className="h-16 w-16" />
    </div>
  );
}

export default AppLoader;

import React from 'react';
import PanelMeetingListItem from './PanelMeetingListItem';
import { InputText } from 'primereact/inputtext';
import { useProjectMeetingContext } from '../../../../contexts/ProjectMeetingContext';
import { useCollaboratorContext } from '../../../../contexts/CollaboratorContext';
import PanelFilter from '../../PanelFilter';

function PanelMeetingList({ meetingList, handleSearch, handleMeetingUpdate }) {
  const { projectMeetingContext, setProjectMeetingAuthor } = useProjectMeetingContext();
  const { collaboratorContext } = useCollaboratorContext();

  const emptyMeetingList = (
    <div className="h-[calc(100vh-400px)] w-full flex items-center justify-center italic text-slate-400">
      <span>Aucune concertation à afficher</span>
    </div>
  );

  return (
    <div className="flex flex-col gap-y-3 h-full">
      <span className={`p-input-icon-left w-full`}>
        <i className={`pi pi-search`} />
        <InputText
          defaultValue={projectMeetingContext.query}
          placeholder="Rechercher une concertation"
          className="w-full p-inputtext-sm"
          onChange={(e) => handleSearch(e.target.value)}
        />
      </span>

      <div className="flex flew-row gap-x-2">
        <PanelFilter
          key="collaborator"
          emptyLabel={'Tous les utilisateurs'}
          options={collaboratorContext.internalCollaborators}
          selectedValue={projectMeetingContext.author}
          onSelect={setProjectMeetingAuthor}
          optionLabel={'alias'}
          showFilter
        />
      </div>

      <div className="flex flex-col gap-y-3 overflow-y-scroll pb-20">
        {!meetingList.length && emptyMeetingList}
        {meetingList.map((c) => (
          <PanelMeetingListItem
            key={c.id}
            meeting={c}
            handleMeetingUpdate={() => handleMeetingUpdate(c)}
            isPanel
          />
        ))}
      </div>
    </div>
  );
}

export default PanelMeetingList;

import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import FormInput from '../../../components/Form/FormInput';
import { CONSTANT, getConstant } from '../../../utils/constantHelper';
import { Divider } from 'primereact/divider';
import Data from '../../../components/Display/Data';
import DataTag from '../../../components/Display/DataTag';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import Tag from '../../../components/Display/Tag';

function ManageProjectStep({ isOpen, handleClose, updateStep, loading, project, targetKey }) {
  const [targetValue, setTargetValue] = useState(undefined);
  const [content, setContent] = useState('');

  useEffect(() => {
    setContent('');
    setTargetValue(undefined);
  }, [isOpen]);

  const onValidate = () => {
    const stepForm = {
      content,
      old_value: project[targetKey],
      target_value: targetValue,
      key: targetKey
    };
    stepForm[targetKey] = targetValue;
    updateStep(stepForm);
  };

  const contentIsValid = targetValue !== undefined && content.length > 10;

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={'Valider la modification'}
        loading={loading}
        disabled={!contentIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  const dropdownTemplate = (option, props) => {
    if (option) {
      return (
        <Tag
          tag={{
            label: option.label,
            bgColor: option.bgColor
          }}
          rounded
        />
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const currentStepLevel =
    targetKey && getConstant(`project.steps.${targetKey}.levels`, project[targetKey]);

  return (
    <Sidebar
      style={{ width: 530, paddingLeft: 10, paddingRight: 10 }}
      header={<h2 className="text-slate-800">{`Modifier une étape`}</h2>}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        {targetKey && (
          <div className="manager-form-wrapper">
            <Data
              key="stepType"
              label={'Étape'}
              value={CONSTANT.project.steps[targetKey].label}
              large
            />
            <DataTag
              key="currentValue"
              label="État actuel d'avancement"
              tag={{
                label: currentStepLevel.label,
                bgColor: currentStepLevel.bgColor
              }}
              rounded
              large
            />

            <FormInput label={"État souhaité d'avancement"}>
              <Dropdown
                id="targetStep"
                value={targetValue}
                onChange={(e) => setTargetValue(e.value)}
                options={CONSTANT.project.steps[targetKey].levels.filter(
                  (l) => l.value !== currentStepLevel.value
                )}
                valueTemplate={dropdownTemplate}
                itemTemplate={dropdownTemplate}
                placeholder="Veuillez sélectionner un état"
              />
            </FormInput>

            <Divider />

            <FormInput
              label={'Commentaire'}
              required
              isValid={content.length > 10}
              helpMessage={'Au moins 10 caractères'}>
              <InputTextarea
                rows={3}
                autoResize
                value={content}
                placeholder="Veuillez saisir un commentaire"
                onChange={(e) => setContent(e.target.value)}
              />
            </FormInput>
          </div>
        )}

        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageProjectStep;

import React, { useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CONSTANT, getConstant } from '../../../utils/constantHelper';
import { Button } from 'primereact/button';
import Tag from '../../../components/Display/Tag';
import { useDashboardContext } from '../../../contexts/DashboardContext';
import DashboardSearch from '../../../components/Dashboard/DashboardSearch';
import DashboardFilter from '../../../components/Dashboard/DashboardFilter';
import { useCollaboratorContext } from '../../../contexts/CollaboratorContext';
import { useCompanyContext } from '../../../contexts/CompanyContext';
import { useAccountContext } from '../../../contexts/AccountContext';

function DashboardDatatable({ onProjectSelect, data, loading }) {
  const {
    dashboardContext,
    setDashboardFilter,
    setDashboardSort,
    setDashboardOptions,
    columnIsHidden
  } = useDashboardContext();
  const { collaboratorContext } = useCollaboratorContext();
  const { companyContext, getClientList } = useCompanyContext();
  const { isAdmin } = useAccountContext();

  const clientList = useMemo(() => getClientList(), [companyContext]);

  const handleFilter = (key, value) => {
    setDashboardFilter(key, value);
  };

  const onPage = (event) => {
    setDashboardOptions({ page: event.page, offset: event.first, rows: event.rows });
  };

  const onSort = (sort) => {
    setDashboardSort(sort);
  };

  const stepBodyTemplate = (rowData, stepKey) => (
    <Tag
      key={stepKey}
      tag={getConstant(`project.steps.${stepKey}.veryShortLevels`, rowData[stepKey])}
      rounded
    />
  );

  const filterRow = (
    <div className="flex flex-row gap-x-3">
      <DashboardSearch
        handleSearch={(value) => handleFilter('query', value)}
        value={dashboardContext.filters.query}
      />
      <DashboardFilter
        key="state"
        options={CONSTANT.project.state}
        value={dashboardContext.filters.stateList}
        onChange={(value) => handleFilter('stateList', value)}
        emptyLabel="Filter par état"
      />
      <DashboardFilter
        key="technology"
        options={CONSTANT.project.technology}
        value={dashboardContext.filters.technologyList}
        onChange={(value) => handleFilter('technologyList', value)}
        emptyLabel="Filter par techno"
      />
      <DashboardFilter
        key="manager"
        options={collaboratorContext.internalCollaborators}
        value={dashboardContext.filters.managerList}
        onChange={(value) => handleFilter('managerList', value)}
        emptyLabel="Filter par chef de projet"
        optionLabel="alias"
        showFilter
      />
      {isAdmin && (
        <DashboardFilter
          key="client"
          options={clientList}
          value={dashboardContext.filters.clientList}
          onChange={(value) => handleFilter('clientList', value)}
          emptyLabel="Filter par client"
          optionLabel="name"
        />
      )}
    </div>
  );

  return (
    <div className="flex flex-col gap-y-3">
      {filterRow}
      <DataTable
        id={'dashboard-table-with-filters'}
        value={data.projectList}
        lazy
        loading={loading}
        scrollable={true}
        size="small"
        paginator
        rows={dashboardContext.options.rows}
        first={dashboardContext.options.offset}
        filters={dashboardContext.options.filters}
        totalRecords={data.totalRows}
        rowsPerPageOptions={[20, 50, 100, 200]}
        onPage={onPage}
        sortMode={'single'}
        sortField={dashboardContext.sortOption.sortField}
        sortOrder={dashboardContext.sortOption.sortOrder}
        onSort={onSort}
        selectionMode="single"
        onRowSelect={(e) => onProjectSelect(e.data)}>
        <Column sortable key="name" frozen className="font-bold" field="name" header="Nom"></Column>
        <Column
          key="state"
          field="state"
          header="État"
          hidden={columnIsHidden('state')}
          body={(rowData) => <Tag tag={getConstant('project.state', rowData.state)} />}></Column>
        <Column
          key="technology"
          field="technology"
          header="Techno"
          hidden={columnIsHidden('technology')}
          body={(rowData) => (
            <Tag tag={getConstant('project.technology', rowData.technology)} />
          )}></Column>
        <Column
          key="manager"
          field="manager.alias"
          header="Chef de projet"
          hidden={columnIsHidden('manager')}></Column>
        <Column
          key="client"
          field="client.name"
          header="Client"
          hidden={columnIsHidden('client')}></Column>
        <Column
          key="identification_step"
          field="identification_step"
          sortable
          className="w-12"
          header="Id"
          body={(rowData) => stepBodyTemplate(rowData, 'identification_step')}
          hidden={columnIsHidden('steps')}></Column>
        <Column
          key="securisation_step"
          field="securisation_step"
          sortable
          className="w-12"
          header="Sf"
          body={(rowData) => stepBodyTemplate(rowData, 'securisation_step')}
          hidden={columnIsHidden('steps')}></Column>
        <Column
          key="study_step"
          field="study_step"
          sortable
          className="w-12"
          header="Ét"
          body={(rowData) => stepBodyTemplate(rowData, 'study_step')}
          hidden={columnIsHidden('steps')}></Column>
        <Column
          key="instruction_step"
          field="instruction_step"
          sortable
          className="w-12"
          header="In"
          body={(rowData) => stepBodyTemplate(rowData, 'instruction_step')}
          hidden={columnIsHidden('steps')}></Column>
        <Column
          key="prebuilding_step"
          field="prebuilding_step"
          sortable
          className="w-12"
          header="Pc"
          body={(rowData) => stepBodyTemplate(rowData, 'prebuilding_step')}
          hidden={columnIsHidden('steps')}></Column>
        <Column
          key="cityName"
          field="city.name"
          header="Commune"
          hidden={columnIsHidden('cityName')}></Column>
        <Column
          key="zipCode"
          field="city.zip_code"
          header="ZIP"
          hidden={columnIsHidden('zipCode')}></Column>
        <Column
          key="comcom"
          field="comcom"
          header="Comcom"
          bodyClassName="overflow-hidden text-ellipsis whitespace-nowrap max-w-[200px]"
          hidden={columnIsHidden('comcom')}></Column>
        <Column
          sortable
          key="estimatePower"
          field="estimate_power"
          header="Puissance (MW)"
          hidden={columnIsHidden('estimatePower')}></Column>
        <Column
          key="departement"
          field="city.departement"
          header="Département"
          hidden={columnIsHidden('departement')}></Column>
        <Column
          key="region"
          field="city.region"
          header="Région"
          hidden={columnIsHidden('region')}></Column>
      </DataTable>
    </div>
  );
}

export default DashboardDatatable;

import React, { useMemo } from 'react';
import Data from '../../../../components/Display/Data';
import DataTag from '../../../../components/Display/DataTag';
import { getConstant } from '../../../../utils/constantHelper';
import Section from '../../../../components/Display/Section';
import { Divider } from 'primereact/divider';

function ProjectIdentification({
  project,
  handleProjectInformationUpdate,
  handleProjectKml,
  handleProjectMeasureUpdate,
  handleKmlOpenInTab
}) {
  const getKmlFileTag = () => {
    if (!project.kml_file) return null;
    return {
      label: 'Voir en plein écran',
      icon: 'earth'
    };
  };

  const isWind = useMemo(() => project.technology === 'wind', [project]);

  const informationSectionActions = [
    { icon: 'pi-map', label: 'Fichier KML', onClick: handleProjectKml },
    {
      onClick: handleProjectInformationUpdate,
      label: 'Modifier',
      icon: 'pi-pencil'
    }
  ];

  const measuresSectionActions = [
    {
      onClick: handleProjectMeasureUpdate,
      label: 'Modifier',
      icon: 'pi-pencil'
    }
  ];

  return (
    <div className="data-section accordion-tab">
      <Section label="Informations site" actions={informationSectionActions}>
        <div className="data-section-row">
          <Data key="city" label={'Commune'} value={project.city?.name} large />
          <Data key="zip" label={'Code Postal'} value={project.city?.zip_code} />
          <Data key="departement" label={'Département'} value={project.city?.departement} large />
          <Data key="region" label={'Région'} value={project.city?.region} large />
          <Data key="comcom" label={'Communauté de commune'} value={project.comcom} large />
        </div>
        <div className="data-section-row">
          <DataTag label="Fichier KML" tag={getKmlFileTag()} large onClick={handleKmlOpenInTab} />
          <DataTag
            label="Origine du projet"
            tag={getConstant('project.origin', project.origin)}
            rounded
          />
          <Data key="urbanism" label={'Urbanisme'} value={project.urbanism} large />
        </div>
      </Section>
      <Divider />
      <Section label="Dimensionnement et mesures" actions={measuresSectionActions}>
        <div className="data-section-row">
          <Data
            key="estimated_power"
            label={'Puissance estimée'}
            value={project.estimate_power}
            suffix={isWind ? 'MW' : 'MWc'}
          />
          <Data
            key="estimated_production"
            label={'Productible estimé'}
            value={project.estimated_production}
            suffix="MWc"
          />
          <Data key="parcel_nb" label={'Nb. parcelles'} value={project.parcel_nb} />
          <Data
            key="distance"
            label={'Distance raccordement'}
            value={project.distance}
            suffix="km"
            large
          />
          <Data
            key="surface"
            label={'Surface estimée'}
            value={project.estimated_surface}
            suffix="m²"
          />
          {!isWind && (
            <DataTag
              label="Structure PV"
              tag={getConstant('project.structure', project.pv_structure)}
              rounded
            />
          )}
        </div>
      </Section>
    </div>
  );
}

export default ProjectIdentification;

import React, { useState, useEffect, useRef } from 'react';
import Section from '../../../components/Display/Section';
import { MapContainer, TileLayer, FeatureGroup, LayersControl, LayerGroup } from 'react-leaflet';
import ReactLeafletKml from 'react-leaflet-kml'; // react-leaflet-kml must be loaded AFTER react-leaflet
import { FullscreenControl } from 'react-leaflet-fullscreen';
import { useNavigate, useParams } from 'react-router-dom';
import { privateFetch } from '../../../utils/apiHelper';

function StandaloneProjectKml() {
  const [kml, setKml] = useState(undefined);
  const [project, setProject] = useState(undefined);
  const mapRef = useRef(null);
  const groupRef = useRef(null);
  const navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchProjectKmlDetail(id);
    }
  }, []);

  useEffect(() => {
    if (project) {
      loadKml();
    }
  }, [project]);

  const fetchProjectKmlDetail = async (id) => {
    await privateFetch('GET', `/project/${id}/`).then((res) => {
      if (res) setProject(res);
    });
  };

  const loadKml = () => {
    fetch(project.kml_file.file)
      .then((res) => res.text())
      .then((kmlText) => {
        const parser = new DOMParser();
        const kml = parser.parseFromString(kmlText, 'text/xml');
        setKml(kml);
      });

    setTimeout(() => {
      centerMap();
    }, 500);
  };

  const centerMap = () => {
    const map = mapRef.current;
    const group = groupRef.current;
    if (map && group) {
      map.fitBounds(group.getBounds());
    }
  };

  const handleDownload = () => {
    window.open(project.kml_file.file, '_blank', 'noreferrer');
  };

  const kmlActions = [
    {
      label: 'Revenir au projet',
      icon: 'pi-chevron-left',
      onClick: () => navigate('/project')
    },
    {
      label: 'Télécharger le fichier',
      icon: 'pi-cloud-download',
      onClick: handleDownload
    }
  ];

  if (!project) return null;
  return (
    <div className="h-screen w-screen px-5 py-3">
      <Section label={project.name} actions={kmlActions} className={'h-full w-full'}>
        <MapContainer
          className="z-10 standalone-kml-wrapper"
          //style={{ height: '400px', width: '400px' }}
          zoom={4}
          center={[46.711, 1.719]}
          ref={mapRef}>
          <LayersControl>
            <LayersControl.BaseLayer name="Open Street Map" checked>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Google Map Satellite">
              <LayerGroup>
                <TileLayer
                  attribution="Google Maps Satellite"
                  url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                />
                <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}" />
              </LayerGroup>
            </LayersControl.BaseLayer>
          </LayersControl>
          <FeatureGroup ref={groupRef}>{kml && <ReactLeafletKml kml={kml} />}</FeatureGroup>
          <FullscreenControl />
        </MapContainer>
      </Section>
    </div>
  );
}

export default StandaloneProjectKml;

import React, { useEffect, useState } from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import ProjectPanel from '../../../components/Panel/Project/ProjectPanel';
import ProjectOverview from './ProjectOverview';
import ProjectSchedule from './ProjectSchedule';
import { confirmDialog } from 'primereact/confirmdialog';
import { useNavigationContext } from '../../../contexts/NavigationContext';
import { privateFetch } from '../../../utils/apiHelper';
import ProjectRisks from './ProjectRisks';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Tag from '../../../components/Display/Tag';
import ProjectIdentification from './Identification/ProjectIdentification';
import ManageProjectOverview from './ManageProjectOverview';
import ManageProjectInformation from './Identification/ManageProjectInformation';
import ManageProjectMeasure from './Identification/ManageProjectMeasure';
import ManageProjectRisk from './ManageProjectRisk';
import { useProjectCollaboratorContext } from '../../../contexts/ProjectCollaboratorContext';
import { useProjectEventContext } from '../../../contexts/ProjectEventContext';
import ManageDocument from '../Common/ManageDocument';
import { getConstant } from '../../../utils/constantHelper';
import ProjectSecurisation from './Securisation/ProjectSecurisation';
import ProjectStudy from './Study/ProjectStudy';
import ProjectInstruction from './Instruction/ProjectInstruction';
import ManageProjectStep from './ManageProjectStep';
import ProjectKml from './ProjectKml';
import { useNavigate } from 'react-router-dom';
import ManageProjectScheduleCreation from './ManageProjectScheduleCreation';
import ManageProjectScheduleUpdate from './ManageProjectScheduleUpdate';
import moment from 'moment';
import { capitalizeFirstLetter } from '../../../utils/stringHelper';
import ProjectBudget from './ProjectBudget';
import ProjectStudyOnboarding from './Study/ProjectStudyOnboarding';
import ProjectSecurisationOnboarding from './Securisation/ProjectSecurisationOnboarding';
import ProjectInstructionOnboarding from './Instruction/ProjectInstructionOnboarding';
import ProjectPrebuilding from './Prebuilding/ProjectPrebuilding';
import ProjectPrebuildingOnboarding from './Prebuilding/ProjectPrebuildingOnboarding';
import ProjectLastMeetings from './ProjectLastMeetings';
import ProjectLastEvent from './ProjectLastEvent';

function ProjectPage() {
  const { navigationContext, setActiveAccordionTabs, toggleProjectPanel } = useNavigationContext();
  const { openCollaboratorDetail } = useProjectCollaboratorContext();
  const { fetchProjectEventList, fetchProjectLastEvent } = useProjectEventContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState(undefined);
  const [projectPanelOpen, setProjectPanelOpen] = useState(false);
  const [projectInformationPanelOpen, setProjectInformationPanelOpen] = useState(false);
  const [projectKmlFilePanelOpen, setProjectKmlFilePanelOpen] = useState(false);
  const [projectMeasurePanelOpen, setProjectMeasurePanelOpen] = useState(false);

  const panelIsOpen =
    navigationContext.activeProjectPanel === 'comment' ||
    navigationContext.activeProjectPanel === 'collaborator' ||
    navigationContext.activeProjectPanel === 'event' ||
    navigationContext.activeProjectPanel === 'company' ||
    navigationContext.activeProjectPanel === 'meeting';

  // Risk
  const [riskTargetKey, setRiskTargetKey] = useState(undefined);
  const [projectRiskPanelOpen, setProjectRiskPanelOpen] = useState(false);

  // Step
  const [stepTargetKey, setStepTargetKey] = useState(undefined);
  const [projectStepPanelOpen, setProjectStepPanelOpen] = useState(false);

  // Budget
  const [budgetData, setBudgetData] = useState(undefined);

  const [projectScheduleResponse, setProjectScheduleResponse] = useState(undefined);
  const [projectCreateScheduleOpen, setProjectCreateScheduleOpen] = useState(false);
  const [schedule, setSchedule] = useState(undefined);
  const [projectUpdateScheduleOpen, setProjectUpdateScheduleOpen] = useState(false);

  useEffect(() => {
    if (navigationContext.activeProject && navigationContext.activeProject.id) {
      fetchProjectDetail();
      fetchProjectSchedule();
      fetchProjectBudget();
    }
  }, [navigationContext.activeProject?.id]);

  const fetchProjectDetail = async () => {
    await privateFetch('GET', `/project/${navigationContext.activeProject.id}/`).then((res) => {
      if (res) setProject(res);
    });
  };

  const fetchProjectBudget = async () => {
    await privateFetch('GET', `/project/${navigationContext.activeProject.id}/budget/`).then(
      (res) => {
        if (res) setBudgetData(res);
      }
    );
  };

  const fetchProjectSchedule = async () => {
    await privateFetch('GET', `/project/${navigationContext.activeProject.id}/schedule/`).then(
      (res) => {
        if (res) setProjectScheduleResponse(res);
      }
    );
  };

  const updateProjectOverview = async (projectForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/project/${project.id}/`,
      `Le projet ${projectForm.name} a bien été modifié.`,
      JSON.stringify(projectForm)
    ).then(() => {
      setProjectPanelOpen(false);
      setLoading(false);
      fetchProjectDetail();
    });
  };

  const updateProjectInformation = async (projectForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/project/${project.id}/information/`,
      `Le projet ${project.name} a bien été modifié.`,
      JSON.stringify(projectForm)
    ).then(() => {
      setProjectInformationPanelOpen(false);
      setLoading(false);
      fetchProjectDetail();
    });
  };

  const updateProjectMeasure = async (projectForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/project/${project.id}/measure/`,
      `Le projet ${project.name} a bien été modifié.`,
      JSON.stringify(projectForm)
    ).then(() => {
      setProjectMeasurePanelOpen(false);
      setLoading(false);
      fetchProjectDetail();
    });
  };

  const updateProjectRisk = async (projectRiskForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/project/${project.id}/risk/`,
      `Le projet ${project.name} a bien été modifié.`,
      JSON.stringify(projectRiskForm)
    ).then(() => {
      setProjectRiskPanelOpen(false);
      setLoading(false);
      fetchProjectDetail();
      fetchProjectEventList();
      fetchProjectLastEvent();
    });
  };

  const updateProjectStep = async (projectStepForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/project/${project.id}/step/`,
      `Le projet ${project.name} a bien été modifié.`,
      JSON.stringify(projectStepForm)
    ).then(() => {
      setProjectStepPanelOpen(false);
      setLoading(false);
      fetchProjectDetail();
      fetchProjectEventList();
      fetchProjectLastEvent();
    });
  };

  const uploadProjectKmlFile = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/project/${project.id}/kml/`,
      'Le fichier KML a bien été ajoutée.',
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setProjectKmlFilePanelOpen(false);
      fetchProjectDetail();
    });
  };

  const updateKmlFile = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/document/${project.kml_file.id}/`,
      'Le document a bien été modifié.',
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setProjectKmlFilePanelOpen(false);
      fetchProjectDetail();
    });
  };

  const deleteKmlFile = async (document) => {
    setLoading(true);
    await privateFetch(
      'DELETE',
      `/document/${project.kml_file.id}/`,
      'Une erreur est survenue lors de la suppression du fichier KML.',
      'Le document a bien été supprimé.'
    ).then(() => {
      setLoading(false);
      fetchProjectDetail();
    });
  };

  const handleStepUpdate = (key) => {
    setStepTargetKey(key);
    setProjectStepPanelOpen(true);
  };

  const handleRiskUpdate = (riskType) => {
    setRiskTargetKey(riskType.value);
    setProjectRiskPanelOpen(true);
  };

  const handleOpenKmfFileInTab = () => {
    navigate(`/project/${project.id}/kml`, '_blank', 'noreferrer');
  };

  const handleOpenEventPanel = () => {
    toggleProjectPanel('event');
  };

  const handleOpenMeetingPanel = () => {
    toggleProjectPanel('meeting');
  };

  const handleUpdateSchedule = (schedule) => {
    setSchedule(schedule);
    setProjectUpdateScheduleOpen(true);
  };

  const createProjectSchedules = async (form) => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/project/${project.id}/schedule/`,
      `La planification bien été ajouté.`,
      JSON.stringify(form)
    ).then(() => {
      setProjectCreateScheduleOpen(false);
      setLoading(false);
      fetchProjectSchedule();
    });
  };

  const updateProjectSchedule = async (form) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/project/${project.id}/schedule/${schedule.id}/`,
      `La planification bien été modifiée.`,
      JSON.stringify(form)
    ).then((res) => {
      if (res) {
        setProjectUpdateScheduleOpen(false);
        setLoading(false);
        fetchProjectSchedule();
        fetchProjectEventList();
        fetchProjectLastEvent();
      }

      // TODO : refresh Project Event Panel
    });
  };

  const confirmKmlFileDeletion = () => {
    setProjectKmlFilePanelOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer le fichier KML ?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: deleteKmlFile
    });
  };

  const buildStepRealInterval = (stepKey) => {
    const realStep = projectScheduleResponse?.schedule[stepKey]?.find(
      (s) => s.type === stepKey && s.is_real
    );
    if (!realStep) return '';
    return `${capitalizeFirstLetter(
      moment(realStep.start_dt).format('MMM YYYY')
    )} - ${capitalizeFirstLetter(moment(realStep.end_dt).format('MMM YYYY'))}`;
  };

  const stepHeader = (label, key) => {
    return (
      <div className="flex flex-row w-full justify-between items-center px-2">
        <div className="flex flex-row items-center gap-x-10">
          <h2 className="text-slate-800">{label}</h2>
          <span className="text-slate-600 font-normal">{buildStepRealInterval(key)}</span>
        </div>
        {project[key] != null ? (
          <Tag
            tag={getConstant(`project.steps.${key}.levels`, project[key])}
            rounded
            onClick={(e) => {
              handleStepUpdate(key);
              e.stopPropagation();
              e.preventDefault();
            }}
          />
        ) : (
          <span className="font-normal text-slate-600 italic text-sm">Non démarrée</span>
        )}
      </div>
    );
  };

  return (
    <div className={`h-screen w-screen bg-slate-50 text-slate-900 flex flex-col`}>
      <Navbar />
      <div className="w-full flex flex-row-reverse h-full">
        <ProjectPanel />
        {project && (
          <div className="page-wrapper">
            <div className="h-auto flex flex-col gap-y-8">
              <ProjectOverview
                project={project}
                openCollaboratorPanel={openCollaboratorDetail}
                handleProjectUpdate={() => setProjectPanelOpen(true)}
                handleStepUpdate={handleStepUpdate}
              />

              <ProjectSchedule
                schedule={projectScheduleResponse?.schedule}
                displayStartDate={projectScheduleResponse?.display_start_dt}
                displayEndDate={projectScheduleResponse?.display_end_dt}
                handleCreateSchedule={() => setProjectCreateScheduleOpen(true)}
                handleUpdateSchedule={handleUpdateSchedule}
              />
              <div className={`grid ${panelIsOpen ? 'grid-cols-2' : 'grid-cols-3'} gap-10`}>
                <ProjectKml project={project} handleOpenInTab={handleOpenKmfFileInTab} />
                <ProjectBudget data={budgetData} />
                <ProjectRisks project={project} onUpdate={handleRiskUpdate} />
                <ProjectLastEvent handleOpenInTab={handleOpenEventPanel} />
                <ProjectLastMeetings handleOpenInTab={handleOpenMeetingPanel} />
              </div>
              <div className="w-full my-3" />
              <Accordion
                activeIndex={navigationContext.activeProject.activeAccordionTabs}
                multiple
                onTabChange={(e) => setActiveAccordionTabs(e.index)}>
                <AccordionTab header={stepHeader('Identification', 'identification_step')}>
                  <ProjectIdentification
                    project={project}
                    handleProjectKml={() => setProjectKmlFilePanelOpen(true)}
                    handleProjectInformationUpdate={() => setProjectInformationPanelOpen(true)}
                    handleProjectMeasureUpdate={() => setProjectMeasurePanelOpen(true)}
                    handleKmlOpenInTab={handleOpenKmfFileInTab}
                  />
                </AccordionTab>
                <AccordionTab header={stepHeader('Sécurisation', 'securisation_step')}>
                  {project['securisation_step'] != null ? (
                    <ProjectSecurisation project={project} />
                  ) : (
                    <ProjectSecurisationOnboarding updateProjectStep={updateProjectStep} />
                  )}
                </AccordionTab>
                <AccordionTab header={stepHeader('Études', 'study_step')}>
                  {project['study_step'] != null ? (
                    <ProjectStudy project={project} fetchProjectBudget={fetchProjectBudget} />
                  ) : (
                    <ProjectStudyOnboarding updateProjectStep={updateProjectStep} />
                  )}
                </AccordionTab>
                <AccordionTab header={stepHeader('Instruction', 'instruction_step')}>
                  {project['instruction_step'] != null ? (
                    <ProjectInstruction project={project} fetchProjectBudget={fetchProjectBudget} />
                  ) : (
                    <ProjectInstructionOnboarding updateProjectStep={updateProjectStep} />
                  )}
                </AccordionTab>
                <AccordionTab header={stepHeader('Post-instruction', 'prebuilding_step')}>
                  {project['prebuilding_step'] != null ? (
                    <ProjectPrebuilding project={project} fetchProjectBudget={fetchProjectBudget} />
                  ) : (
                    <ProjectPrebuildingOnboarding updateProjectStep={updateProjectStep} />
                  )}
                </AccordionTab>
              </Accordion>
            </div>

            <ManageProjectOverview
              loading={loading}
              project={project}
              isOpen={projectPanelOpen}
              handleClose={() => {
                setProjectPanelOpen(false);
              }}
              updateProject={updateProjectOverview}
            />

            <ManageProjectInformation
              loading={loading}
              project={project}
              isOpen={projectInformationPanelOpen}
              handleClose={() => {
                setProjectInformationPanelOpen(false);
              }}
              updateProject={updateProjectInformation}
            />

            <ManageDocument
              accept={'application/vnd.google-earth.kml+xml'}
              key={'kml'}
              loading={loading}
              document={project.kml_file}
              isOpen={projectKmlFilePanelOpen}
              handleClose={() => setProjectKmlFilePanelOpen(false)}
              uploadDocument={uploadProjectKmlFile}
              updateDocument={updateKmlFile}
              deleteDocument={confirmKmlFileDeletion}
            />

            <ManageProjectStep
              loading={loading}
              project={project}
              targetKey={stepTargetKey}
              isOpen={projectStepPanelOpen}
              handleClose={() => {
                setStepTargetKey(undefined);
                setProjectStepPanelOpen(undefined);
              }}
              updateStep={updateProjectStep}
            />

            <ManageProjectMeasure
              loading={loading}
              project={project}
              isOpen={projectMeasurePanelOpen}
              handleClose={() => {
                setProjectMeasurePanelOpen(false);
              }}
              updateProject={updateProjectMeasure}
            />

            <ManageProjectRisk
              loading={loading}
              project={project}
              targetKey={riskTargetKey}
              isOpen={projectRiskPanelOpen}
              handleClose={() => {
                setRiskTargetKey(undefined);
                setProjectRiskPanelOpen(false);
              }}
              updateRisk={updateProjectRisk}
            />

            <ManageProjectScheduleCreation
              loading={loading}
              project={project}
              schedules={projectScheduleResponse?.schedule}
              isOpen={projectCreateScheduleOpen}
              handleClose={() => {
                setProjectCreateScheduleOpen(false);
              }}
              createSchedules={createProjectSchedules}
            />

            <ManageProjectScheduleUpdate
              loading={loading}
              project={project}
              schedule={schedule}
              isOpen={projectUpdateScheduleOpen}
              handleClose={() => {
                setProjectUpdateScheduleOpen(false);
              }}
              updateSchedule={updateProjectSchedule}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProjectPage;

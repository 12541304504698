import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import FormInput from '../../../components/Form/FormInput';
import { CONSTANT } from '../../../utils/constantHelper';
import { Dropdown } from 'primereact/dropdown';
import { privateFetch } from '../../../utils/apiHelper';
import { MultiSelect } from 'primereact/multiselect';
import Tag from '../../../components/Display/Tag';

function ManageProjectOverview({ isOpen, handleClose, updateProject, loading, project }) {
  // Form
  const [name, setName] = useState('');
  const [technology, setTechnology] = useState(CONSTANT.project.technology[0].value);
  const [manager, setManager] = useState(undefined);
  const [client, setClient] = useState(undefined);
  const [observers, setObservers] = useState([]);
  const [state, setState] = useState(undefined);
  const [spv, setSpv] = useState(undefined);

  // Data
  const [clientList, setClientList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [spvList, setSpvList] = useState([]);

  useEffect(() => {
    fetchClientList();
    fetchManagerList();
    fetchSpvList();
  }, []);

  useEffect(() => {
    setName(project.name);
    setTechnology(project.technology);
    setManager(project.manager);
    setClient(project.client);
    setObservers(project.observers);
    setState(project.state);
  }, [isOpen]);

  const fetchManagerList = async () => {
    await privateFetch('GET', `/collaborator/manager/`).then((res) => {
      if (res) setManagerList(res);
    });
  };

  const fetchClientList = async () => {
    await privateFetch('GET', `/company/?t=client`).then((res) => {
      if (res) setClientList(res);
    });
  };

  const fetchSpvList = async () => {
    await privateFetch('GET', `/company/?t=spv`).then((res) => {
      if (res) setSpvList(res);
    });
  };

  const onValidate = () => {
    const projectForm = {
      name,
      technology,
      manager: manager.id,
      client: client.id,
      spv: spv?.id,
      observers: observers.map((o) => o.id),
      state: state
    };
    updateProject(projectForm);
  };

  const dropdownTemplate = (option, props) => {
    if (option) {
      return (
        <Tag
          tag={{
            label: option.label,
            bgColor: option.bgColor
          }}
          rounded
        />
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const nameIsValid = name.length > 2;
  const formIsValid = nameIsValid && manager && client;

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={'Valider les modifications'}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 530, paddingLeft: 10, paddingRight: 10 }}
      header={<h2 className="text-slate-800">{`Informations générales`}</h2>}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="manager-form-wrapper">
          <FormInput key="name" label="Nom" required isValid={nameIsValid}>
            <InputText
              value={name}
              size={250}
              placeholder="Veuillez saisir le nom du projet"
              onChange={(e) => setName(e.target.value)}
            />
          </FormInput>

          <FormInput key="state" label={'Etat'}>
            <Dropdown
              id="state"
              value={state}
              onChange={(e) => setState(e.value)}
              options={CONSTANT.project.state}
              valueTemplate={dropdownTemplate}
              itemTemplate={dropdownTemplate}
              optionLabel="label"
            />
          </FormInput>

          <FormInput key="technology" label={'Technologie'}>
            <Dropdown
              id="technology"
              value={technology}
              onChange={(e) => setTechnology(e.value)}
              options={CONSTANT.project.technology}
              optionLabel="label"
            />
          </FormInput>

          <FormInput key="manager" label={'Chef de projet'} required isValid={manager}>
            <Dropdown
              id="manager"
              value={manager}
              onChange={(e) => setManager(e.value)}
              options={managerList}
              optionLabel="alias"
              placeholder="Veuillez sélectionner un chef de projet"
              scrollHeight={300}
              filter={true}
            />
          </FormInput>

          <FormInput key="client" label={'Client'} required isValid={client}>
            <Dropdown
              id="client"
              value={client}
              onChange={(e) => setClient(e.value)}
              options={clientList}
              optionLabel="name"
              delay={500}
              placeholder="Veuillez sélectionner un client"
            />
          </FormInput>
          <FormInput key="spv" label={'SPV'}>
            <Dropdown
              id="spv"
              value={spv}
              onChange={(e) => setSpv(e.value)}
              options={spvList}
              optionLabel="name"
              delay={500}
              placeholder="Veuillez sélectionner un SPV"
            />
          </FormInput>

          <FormInput key="observers" label={'Observateurs'}>
            <MultiSelect
              id="observers"
              value={observers}
              onChange={(e) => setObservers(e.value)}
              options={managerList.filter((m) => m.id !== manager.id)}
              optionLabel="alias"
              placeholder="Veuillez sélectionner des observateurs"
              display="chip"
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageProjectOverview;

import React, { useEffect } from 'react';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import PanelCompanyList from './PanelCompanyList';
import { Button } from 'primereact/button';
import PanelCompanyDetail from '../../Common/Company/PanelCompanyDetail';
import { useProjectCompanyContext } from '../../../../contexts/ProjectCompanyContext';
import { useDebouncedCallback } from 'use-debounce';
import { useProjectCollaboratorContext } from '../../../../contexts/ProjectCollaboratorContext';

function PanelCompany({ isActive }) {
  const { navigationContext, toggleProjectPanel } = useNavigationContext();
  const {
    projectCompanyContext,
    fetchProjectCompanyList,
    fetchProjectCompanyDetail,
    setProjectCompanyQuery,
    resetProjectCompanyDetail,
    handleCreateProjectCompany,
    handleUpdateCompany
  } = useProjectCompanyContext();
  const { openCollaboratorDetail, handleCreateProjectCollaborator } =
    useProjectCollaboratorContext();

  useEffect(() => {
    fetchProjectCompanyList();
  }, [navigationContext.activeProject?.id]);

  const debouncedQuery = useDebouncedCallback((query) => {
    setProjectCompanyQuery(query);
  }, 500);

  return (
    <div
      className={`h-full w-[450px] bg-white flex flex-col border-l py-3 px-5 gap-y-3 ${
        isActive ? '' : 'hidden'
      }`}>
      <div className="flex flex-row w-full justify-between items-center">
        <h3 className="text-2xl text-slate-900 font-semibold">{'Entreprises'}</h3>
        <div className="flex flex-row gap-x-1">
          {projectCompanyContext.company ? (
            <Button
              key="back"
              size="small"
              icon={'pi pi-arrow-left'}
              rounded
              text
              onClick={resetProjectCompanyDetail}
            />
          ) : (
            <Button
              key="add"
              text
              rounded
              iconPos="left"
              icon={'pi pi-plus'}
              onClick={() => handleCreateProjectCompany(false)}
            />
          )}
          <Button
            key="close"
            text
            rounded
            icon={'pi pi-times'}
            onClick={() => {
              toggleProjectPanel(undefined);
            }}
          />
        </div>
      </div>

      {projectCompanyContext.company ? (
        <div className="panel-company-detail-container">
          <PanelCompanyDetail
            company={projectCompanyContext.company}
            handleCompanyUpdate={() => handleUpdateCompany(false)}
            selectCollaborator={openCollaboratorDetail}
            createCollaborator={() =>
              handleCreateProjectCollaborator(true, 'consultant', projectCompanyContext.company)
            }
          />
        </div>
      ) : (
        <div className="panel-content-list-container ">
          <PanelCompanyList
            companyList={projectCompanyContext.companies}
            handleSelectCompany={(company) => fetchProjectCompanyDetail(company)}
            handleSearch={debouncedQuery}
          />
        </div>
      )}
    </div>
  );
}

export default PanelCompany;

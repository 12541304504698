import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AppLoader from './pages/AppLoader';
import PrivateRouter from './pages/private/Router';
import { useAccountContext } from './contexts/AccountContext';
import SignIn from './pages/public/SignIn/SignIn';
import RecoverPassword from './pages/public/RecoverPassword/RecoverPassword';
import PasswordSetup from './pages/public/PasswordSetup/PasswordSetup';
import useScreenSize from './hooks/useScreenSize';
import MobileCatcher from './pages/MobileCatcher';

function Router() {
  const { isLoggedIn, accountContext } = useAccountContext();
  const screenSize = useScreenSize();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (screenSize.width < 700) {
      navigate('/mobile-catcher');
    }
  }, [screenSize.width]);

  useEffect(() => {
    if (!isLoggedIn && !pathname.startsWith('/password-setup')) {
      navigate('/');
    }
  }, [accountContext]);

  const getScopeRouter = () => {
    if (!isLoggedIn) return null;
    return <PrivateRouter />;
  };

  return (
    <>
      <Routes>
        {/* Public routes */}
        <Route exact path="/login" element={<SignIn />} />
        <Route exact path="/recover-password" element={<RecoverPassword />} />
        <Route exact path="/password-setup/:token" element={<PasswordSetup />} />
        <Route exact path="/mobile-catcher" element={<MobileCatcher />} />
        <Route path="/" element={<AppLoader />} />
      </Routes>
      {/* Private routes */}
      {getScopeRouter()}
    </>
  );
}

export default Router;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import { privateFetch } from '../../../../utils/apiHelper';
import PanelCommentList from './PanelCommentList';
import ManageComment from './ManageComment';
import { useProjectCommentContext } from '../../../../contexts/ProjectCommentContext';
import { useDebouncedCallback } from 'use-debounce';

function PanelComment({ isActive }) {
  const { navigationContext, toggleProjectPanel } = useNavigationContext();
  const { projectCommentContext, fetchProjectCommentList, setProjectCommentQuery } =
    useProjectCommentContext();

  const [comment, setComment] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [commentManagerIsOpen, setCommentManagerIsOpen] = useState(false);

  useEffect(() => {
    fetchProjectCommentList();
  }, [navigationContext.activeProject?.id]);

  const createComment = async (commentForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/project/${navigationContext.activeProject.id}/comment/`,
      `Le commentaire a bien été créé.`,
      JSON.stringify(commentForm)
    ).then(() => {
      setLoading(false);
      setCommentManagerIsOpen(false);
      fetchProjectCommentList();
    });
  };

  const updateComment = async (commentForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/comment/${comment.id}/`,
      `Le commentaire a bien été modifié.`,
      JSON.stringify(commentForm)
    ).then(() => {
      setLoading(false);
      setCommentManagerIsOpen(false);
      fetchProjectCommentList();
    });
  };

  const handleCommentUpdate = (comment) => {
    setComment(comment);
    setCommentManagerIsOpen(true);
  };

  const debouncedQuery = useDebouncedCallback((query) => {
    setProjectCommentQuery(query);
  }, 500);

  return (
    <div
      className={`h-full w-[450px] bg-white flex flex-col border-l py-3 px-5 gap-y-3 ${
        isActive ? '' : 'hidden'
      }`}>
      <div className="flex flex-row w-full justify-between items-center">
        <h3 className="text-2xl text-slate-900 font-semibold">{'Commentaires'}</h3>

        <div className="flex flex-row gap-x-1">
          <Button
            key="add"
            text
            rounded
            iconPos="left"
            icon={'pi pi-plus'}
            onClick={() => {
              setComment(undefined);
              setCommentManagerIsOpen(true);
            }}
          />
          <Button
            key="close"
            text
            rounded
            icon={'pi pi-times'}
            onClick={() => {
              toggleProjectPanel(undefined);
            }}
          />
        </div>
      </div>

      <div className="panel-content-list-container">
        <PanelCommentList
          commentList={projectCommentContext.comments}
          handleCommentUpdate={handleCommentUpdate}
          handleSearch={debouncedQuery}
        />
      </div>

      <ManageComment
        loading={loading}
        isOpen={commentManagerIsOpen}
        comment={comment}
        handleClose={() => {
          setCommentManagerIsOpen(false);
        }}
        createComment={createComment}
        updateComment={updateComment}
      />
    </div>
  );
}

export default PanelComment;

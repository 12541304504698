import { Dialog } from 'primereact/dialog';
import React from 'react';
import CustomIcon from '../CustomIcon';
import { Button } from 'primereact/button';

function ReleaseNoteDialog({ handleClose, content }) {
  const headerElement = (
    <div className="flex flex-row gap-x-4 items-center">
      <CustomIcon icon={'rocket'} className="h-10 w-10" />
      <span className="font-semibold text-2xl">
        {content?.title || `Notes de version : ${content?.version}`}
      </span>
    </div>
  );

  const footerButton = (
    <div className="flex mt-5">
      <Button key={'validate'} label={'C\'est compris !'} onClick={handleClose} />
    </div>
  );

  return (
    <Dialog
      header={headerElement}
      visible={content != null}
      draggable={false}
      style={{ width: '50vw', maxHeight: '60vh' }}
      onHide={handleClose}>
      <div className="flex flex-col gap-y-5 pb-5">
        {content?.intro && <p className="m-0">{content.intro}</p>}
        {content?.newFeatures?.length && (
          <div className="flex flex-col gap-y-3">
            <h4>Nouvelles fonctionnalités</h4>
            <div className="flex flex-col gap-y-1 ml-5">
              {content?.newFeatures.map((nf) => (
                <li>{nf}</li>
              ))}
            </div>
          </div>
        )}
        {content?.fixes?.length ? (
          <div className="flex flex-col gap-y-3">
            <h4>Corrections</h4>
            <div className="flex flex-col gap-y-1 ml-5">
              {content?.fixes.map((f) => (
                <li>{f}</li>
              ))}
            </div>
          </div>
        ) : null}
        {footerButton}
      </div>
    </Dialog>
  );
}

export default ReleaseNoteDialog;

import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import FormInput from '../../../components/Form/FormInput';
import { CONSTANT, getConstant } from '../../../utils/constantHelper';
import { Divider } from 'primereact/divider';
import Data from '../../../components/Display/Data';
import DataTag from '../../../components/Display/DataTag';
import { InputTextarea } from 'primereact/inputtextarea';
import Tag from '../../../components/Display/Tag';
import { Dropdown } from 'primereact/dropdown';

function ManageProjectRisk({ isOpen, handleClose, updateRisk, loading, project, targetKey }) {
  const [targetValue, setTargetValue] = useState(undefined);
  const [content, setContent] = useState('');

  useEffect(() => {
    setContent('');
    setTargetValue(undefined);
  }, [isOpen]);

  const onValidate = () => {
    const riskForm = {
      content,
      old_value: project[targetKey],
      target_value: targetValue,
      key: targetKey
    };
    riskForm[targetKey] = targetValue;
    updateRisk(riskForm);
  };

  const contentIsValid = content.length > 9;

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={'Valider la modification'}
        loading={loading}
        disabled={!contentIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  const currentRiskLevel = getConstant('project.riskLevels', project[targetKey]);

  const dropdownTemplate = (option, props) => {
    if (option) {
      return (
        <Tag
          tag={{
            label: option.label,
            bgColor: option.bgColor
          }}
          rounded
        />
      );
    }
    return <span>{props.placeholder}</span>;
  };

  return (
    <Sidebar
      style={{ width: 530, paddingLeft: 10, paddingRight: 10 }}
      header={<h2 className="text-slate-800">{`Modifier un risque`}</h2>}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="manager-form-wrapper">
          <Data
            key="riskType"
            label={'Type de risque'}
            value={getConstant('project.riskTypes', targetKey).label}
            large
          />
          <DataTag
            key="currentValue"
            label="Niveau de risque actuel"
            tag={{
              label: currentRiskLevel.label,
              bgColor: currentRiskLevel.bgColor
            }}
            large
          />
          <FormInput label={'Nouveau niveau de risque'}>
            <Dropdown
              id="targetRiskLevel"
              value={targetValue}
              onChange={(e) => setTargetValue(e.value)}
              options={CONSTANT.project.riskLevels.filter(
                (rl) => rl.value != currentRiskLevel.value
              )}
              valueTemplate={dropdownTemplate}
              itemTemplate={dropdownTemplate}
              placeholder="Veuillez sélectionner un état"
            />
          </FormInput>

          <Divider />

          <FormInput
            label={'Commentaires concernant la décision'}
            required
            isValid={contentIsValid}>
            <InputTextarea
              rows={3}
              autoResize
              value={content}
              placeholder="Veuillez saisir un commentaire d'au moins 10 caractères"
              onChange={(e) => setContent(e.target.value)}
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageProjectRisk;

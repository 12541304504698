import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import FormInput from '../../../components/Form/FormInput';
import { validateEmail } from '../../../utils/stringHelper';
import { ToggleButton } from 'primereact/togglebutton';
import { CONSTANT } from '../../../utils/constantHelper';
import { Dropdown } from 'primereact/dropdown';
import { privateFetch } from '../../../utils/apiHelper';
import { useAccountContext } from '../../../contexts/AccountContext';

function ManageAccount({ account, isOpen, handleClose, createAccount, updateAccount, loading }) {
  const { isAdmin, accountContext } = useAccountContext();
  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [type, setType] = useState(undefined);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [client, setClient] = useState(undefined);
  const [isActive, setIsActive] = useState(true);

  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    fetchClientList();
  }, []);

  useEffect(() => {
    if (account) {
      setName(account.collaborator.name);
      setFirstName(account.collaborator.first_name);
      setPhone(account.collaborator.phone);
      setEmail(account.collaborator.email);
      setType(account.type);
      setIsActive(account.user.is_active);
      setClient(account.client);
    } else {
      setName('');
      setFirstName('');
      setPhone('');
      setType(undefined);
      setEmail('');
      setIsActive(true);
      if (isAdmin) setClient(undefined);
      else setClient(accountContext.client);
    }
  }, [isOpen, account]);

  const fetchClientList = async () => {
    await privateFetch('GET', `/company/?t=client`).then((res) => {
      if (res) setClientList(res);
    });
  };

  const onValidate = () => {
    const accountForm = {
      name,
      first_name: firstName,
      email,
      phone,
      type,
      is_active: isActive
    };
    if (type === 'ADMIN') accountForm['client'] = null;
    else if (client) accountForm['client'] = client.id;
    account ? updateAccount(accountForm) : createAccount(accountForm);
  };

  const nameIsValid = name.length > 2;
  const firstNameIsValid = firstName.length > 2;
  const emailIsValid = validateEmail(email);
  const clientIsValid = type === 'ADMIN' || client;
  const formIsValid = nameIsValid && firstNameIsValid && emailIsValid && clientIsValid;

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={account ? `Modifier le compte` : 'Créer le compte'}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  const accountTypeOptions = () => {
    if (isAdmin) return CONSTANT.admin.accountType;
    else return CONSTANT.admin.accountType.filter((at) => at.value !== 'ADMIN');
  };

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={<h2 className="text-slate-800">{`${account ? 'Modifier' : 'Créer'} un compte`}</h2>}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="manager-form-wrapper">
          <FormInput key="type" label={'Type de compte'}>
            <Dropdown
              id="type"
              value={type}
              onChange={(e) => setType(e.value)}
              options={accountTypeOptions()}
              optionLabel="label"
              placeholder="Veuillez sélectionner un type de compte"
            />
          </FormInput>

          <FormInput key="firstName" label="Prénom" required isValid={firstNameIsValid}>
            <InputText
              value={firstName}
              placeholder="Veuillez saisir le prénom"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormInput>

          <FormInput key="name" label="Nom" required isValid={nameIsValid}>
            <InputText
              value={name}
              placeholder="Veuillez saisir le nom du collaborateur"
              onChange={(e) => setName(e.target.value)}
            />
          </FormInput>

          <FormInput key="email" label="Email" required isValid={emailIsValid}>
            <InputText
              value={email}
              disabled={account}
              placeholder="Veuillez saisir l'email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormInput>

          {type !== 'ADMIN' && (
            <FormInput key="client" label={'Client'} required isValid={clientIsValid}>
              <Dropdown
                id="client"
                value={client}
                onChange={(e) => setClient(e.value)}
                options={clientList}
                optionLabel="name"
                placeholder="Veuillez sélectionner un client"
                disabled={!isAdmin}
                isValid
              />
            </FormInput>
          )}

          <FormInput key="phone" label="Téléphone">
            <InputText
              value={phone}
              placeholder="Veuillez saisir le téléphone"
              onChange={(e) => setPhone(e.target.value)}
            />
          </FormInput>

          <FormInput key="active" label="Autorisation">
            <div className="flex">
              <ToggleButton
                onLabel="Compte actif"
                offLabel="Compte inactif"
                onIcon="pi pi-check text-slate-50"
                offIcon="pi pi-times text-red-700"
                checked={isActive}
                onChange={(e) => setIsActive(e.value)}
                className={`${isActive ? 'text-slate-50' : 'text-red-700'}`}
              />
            </div>
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageAccount;

import React, { createContext, useContext, useEffect, useState } from 'react';
import { privateFetch } from '../utils/apiHelper';
import { useNavigationContext } from './NavigationContext';
import ManageCollaborator from '../pages/private/Common/ManageCollaborator';

const ProjectCollaboratorContext = createContext(null);

const initial = {
  query: '',
  type: undefined,
  collaborators: [],
  collaborator: undefined
};

export const ProjectCollaboratorContextProvider = ({ children }) => {
  const { navigationContext, toggleProjectPanel } = useNavigationContext();
  const [projectCollaboratorContext, setProjectCollaboratorContext] = useState(initial);

  // Manager Controls
  const [managerVisible, setManagerVisible] = useState(false);
  const [mandatoryType, setMandatoryType] = useState(undefined);
  const [mandatoryCompany, setMandatoryCompany] = useState(undefined);
  const [asDialog, setAsDialog] = useState(false);

  useEffect(() => {
    if (navigationContext.activeProject?.id) {
      fetchProjectCollaboratorList();
    }
  }, [
    projectCollaboratorContext.query,
    projectCollaboratorContext.type,
    navigationContext.activeProject?.id
  ]);

  const setProjectCollaboratorQuery = (query) => {
    setProjectCollaboratorContext({
      ...projectCollaboratorContext,
      query
    });
  };

  const setProjectCollaboratorType = (type) => {
    setProjectCollaboratorContext({
      ...projectCollaboratorContext,
      type
    });
  };

  const resetCollaboratorDetail = () => {
    setProjectCollaboratorContext({
      ...projectCollaboratorContext,
      collaborator: undefined
    });
  };

  const openCollaboratorDetail = (collaborator) => {
    if (navigationContext.activeProjectPanel !== 'collaborator') toggleProjectPanel('collaborator');
    fetchProjectCollaboratorDetail(collaborator);
  };

  const fetchProjectCollaboratorList = async () => {
    await privateFetch(
      'GET',
      `/project/${navigationContext.activeProject.id}/collaborator/?q=${
        projectCollaboratorContext.query
      }&t=${projectCollaboratorContext.type || ''}`
    ).then((res) => {
      if (res) {
        setProjectCollaboratorContext({
          ...projectCollaboratorContext,
          collaborators: res
        });
      }
    });
  };

  const fetchProjectCollaboratorDetail = async (
    targetCollaborator = projectCollaboratorContext.collaborator
  ) => {
    await privateFetch('GET', `/collaborator/${targetCollaborator.id}/`).then((res) => {
      if (res) {
        setProjectCollaboratorContext({
          ...projectCollaboratorContext,
          collaborator: res
        });
      }
    });
  };

  const createProjectCollaborator = async (collaboratorForm) => {
    await privateFetch(
      'POST',
      `/project/${navigationContext.activeProject.id}/collaborator/`,
      `Le contact a bien été créé.`,
      JSON.stringify(collaboratorForm)
    ).then((res) => {
      setManagerVisible(false);
      fetchProjectCollaboratorList();
      setTimeout(() => {
        openCollaboratorDetail(res);
      }, 300);
    });
  };

  const updateProjectCollaborator = async (collaboratorForm) => {
    await privateFetch(
      'PUT',
      `/collaborator/${projectCollaboratorContext.collaborator.id}/`,
      `Le contact a bien été modifié.`,
      JSON.stringify(collaboratorForm)
    ).then((res) => {
      setManagerVisible(false);
      fetchProjectCollaboratorList();
      fetchProjectCollaboratorDetail();
    });
  };

  const handleCreateProjectCollaborator = (
    asDialog = false,
    mandatoryType = undefined,
    mandatoryCompany = undefined
  ) => {
    resetCollaboratorDetail();
    setAsDialog(asDialog);
    setManagerVisible(true);
    setMandatoryType(mandatoryType);
    setMandatoryCompany(mandatoryCompany);
  };

  const handleUpdateProjectCollaborator = (asDialog = false) => {
    setAsDialog(asDialog);
    setManagerVisible(true);
  };

  return (
    <ProjectCollaboratorContext.Provider
      value={{
        projectCollaboratorContext,
        setProjectCollaboratorContext,
        fetchProjectCollaboratorList,
        fetchProjectCollaboratorDetail,
        setProjectCollaboratorQuery,
        setProjectCollaboratorType,
        resetCollaboratorDetail,
        openCollaboratorDetail,
        handleCreateProjectCollaborator,
        handleUpdateProjectCollaborator
      }}>
      {children}

      <ManageCollaborator
        isOpen={managerVisible}
        asDialog={asDialog}
        mandatoryType={mandatoryType}
        mandatoryCompany={mandatoryCompany}
        collaborator={projectCollaboratorContext?.collaborator}
        createCollaborator={createProjectCollaborator}
        updateCollaborator={updateProjectCollaborator}
        handleClose={() => {
          setManagerVisible(false);
          setMandatoryType(undefined);
        }}
      />
    </ProjectCollaboratorContext.Provider>
  );
};

export const useProjectCollaboratorContext = () => {
  return useContext(ProjectCollaboratorContext);
};

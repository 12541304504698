import React, { useMemo } from 'react';
import Avatar from '../../../Display/Avatar';
import { getConstant } from '../../../../utils/constantHelper';

function PanelCollaboratorListItem({ collaborator, selectCollaborator }) {
  const collaboratorType = useMemo(() => {
    if (!collaborator) return null;
    if (collaborator.type === 'internal' && collaborator.custom_user)
      return getConstant('customUser.type', collaborator.custom_user.type).label;
    if (collaborator.type === 'parcel_owner')
      return getConstant('collaborator.subtype', collaborator.subtype).label;
    return getConstant('collaborator.type', collaborator.type).label;
  }, [collaborator]);

  if (!collaborator) return null;
  return (
    <div
      className="w-full flex flex-row gap-x-3 p-3 border-b last:border-b-0 cursor-pointer hover:bg-slate-200"
      onClick={() => selectCollaborator(collaborator)}>
      <Avatar alias={collaborator.alias} />
      <div className="flex flex-col justify-center ">
        <span className="font-semibold text-slate-800">{collaborator.alias}</span>
        <span className="text-sm text-slate-600">{collaboratorType}</span>
      </div>
    </div>
  );
}

export default PanelCollaboratorListItem;

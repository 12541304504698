import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useAccountContext } from '../../../contexts/AccountContext';
import { privateFetch } from '../../../utils/apiHelper';
import Navbar from '../../../components/Navbar/Navbar';
import AdminAccountInfo from './AdminAccountInfo';
import ManagePassword from './ManagePassword';
import AdminAccounts from './AdminAccounts';
import ManageAccount from './ManageAccount';
import ManageClient from './ManageClient';
import AdminClients from './AdminClients';
import { TabPanel, TabView } from 'primereact/tabview';
import AdminCities from './AdminCities';
import ManageCity from './ManageCity';

function AdminPage() {
  const { accountContext, isAdmin, isEmployee } = useAccountContext();
  const [passwordPanelOpen, setPasswordPanelOpen] = useState(false);
  const [clientPanelOpen, setClientPanelOpen] = useState(false);
  const [accountPanelOpen, setAccountPanelOpen] = useState(false);
  const [cityPanelOpen, setCityPanelOpen] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState(undefined);
  const [client, setClient] = useState(undefined);
  const [city, setCity] = useState(undefined);

  useEffect(() => {
    fetchAccountList();
    fetchClientList();
    fetchCityList();
  }, []);

  const fetchAccountList = async () => {
    await privateFetch('GET', `/custom_user/`).then((res) => {
      if (res) setAccountList(res);
    });
  };

  const fetchClientList = async () => {
    await privateFetch('GET', `/company/?t=client`).then((res) => {
      if (res) setClientList(res);
    });
  };

  const fetchCityList = async () => {
    await privateFetch('GET', `/city/`).then((res) => {
      if (res) setCityList(res);
    });
  };

  const updatePassword = async (passwordForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/password/`,
      'Le mot de passe a bien été modifié.',
      JSON.stringify(passwordForm)
    ).then(() => {
      setLoading(false);
      setPasswordPanelOpen(false);
    });
  };

  const createAccount = async (accountForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/custom_user/`,
      `Le compte ${accountForm.email} a bien été créé.`,
      JSON.stringify(accountForm)
    ).then(() => {
      setAccountPanelOpen(false);
      setLoading(false);
      fetchAccountList();
    });
  };

  const updateAccount = async (accountForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/custom_user/${account.id}/`,
      `Le compte ${account.email} a bien été modifié.`,
      JSON.stringify(accountForm)
    ).then(() => {
      setAccountPanelOpen(false);
      setLoading(false);
      fetchAccountList();
    });
  };

  const createClient = async (clientForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/company/`,
      `Le client ${clientForm.name} a bien été créé.`,
      JSON.stringify({ ...clientForm, type: 'client' })
    ).then(() => {
      setClientPanelOpen(false);
      setLoading(false);
      fetchClientList();
    });
  };

  const updateClient = async (clientForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/company/${client.id}/`,
      `Le client ${client.name} a bien été modifié.`,
      JSON.stringify(clientForm)
    ).then(() => {
      setClientPanelOpen(false);
      setLoading(false);
      fetchClientList();
    });
  };

  const updateCity = async (cityForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/city/${city.place_id}/`,
      `La ville ${city.name} a bien été modifié.`,
      JSON.stringify(cityForm)
    ).then(() => {
      setCityPanelOpen(false);
      setLoading(false);
      fetchCityList();
    });
  };

  return (
    <div className={`h-screen w-screen flex-col bg-slate-50 text-slate-900 `}>
      <Navbar />
      <div className="px-5 py-1 h-full flex flex-col">
        <TabView panelContainerClassName="bg-slate-50 tabview-height">
          <TabPanel header="Mon compte" headerClassName="text-2xl font-semibold">
            <AdminAccountInfo
              account={accountContext}
              handlePasswordUpdate={() => setPasswordPanelOpen(true)}
            />
          </TabPanel>
          <TabPanel
            disabled={isEmployee}
            header="Utilisateurs"
            headerClassName="text-2xl font-semibold"
            className="h-full">
            <AdminAccounts
              accountList={accountList}
              handleAccountCreation={() => {
                setAccount(undefined);
                setAccountPanelOpen(true);
              }}
              handleAccountUpdate={(account) => {
                setAccount(account);
                setAccountPanelOpen(true);
              }}
            />
          </TabPanel>
          <TabPanel
            disabled={!isAdmin}
            header="Villes"
            headerClassName="text-2xl font-semibold"
            className="h-full">
            <AdminCities
              cityList={cityList}
              handleCityUpdate={(city) => {
                setCity(city);
                setCityPanelOpen(true);
              }}
            />
          </TabPanel>
          <TabPanel
            disabled={!isAdmin}
            header="Clients"
            headerClassName="text-2xl font-semibold"
            className="h-full">
            <AdminClients
              clientList={clientList}
              handleClientCreation={() => {
                setClient(undefined);
                setClientPanelOpen(true);
              }}
              handleClientUpdate={(client) => {
                setClient(client);
                setClientPanelOpen(true);
              }}
            />
          </TabPanel>
        </TabView>
      </div>

      <ManagePassword
        loading={loading}
        isOpen={passwordPanelOpen}
        handleClose={() => {
          setPasswordPanelOpen(false);
        }}
        updatePassword={updatePassword}
      />

      <ManageAccount
        account={account}
        loading={loading}
        isOpen={accountPanelOpen}
        handleClose={() => {
          setAccount(undefined);
          setAccountPanelOpen(false);
        }}
        createAccount={createAccount}
        updateAccount={updateAccount}
      />

      <ManageClient
        client={client}
        loading={loading}
        isOpen={clientPanelOpen}
        handleClose={() => {
          setClient(undefined);
          setClientPanelOpen(false);
        }}
        createClient={createClient}
        updateClient={updateClient}
      />

      <ManageCity
        city={city}
        loading={loading}
        isOpen={cityPanelOpen}
        handleClose={() => {
          setCity(undefined);
          setCityPanelOpen(false);
        }}
        updateCity={updateCity}
      />
    </div>
  );
}

export default AdminPage;

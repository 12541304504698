import React, { useEffect } from 'react';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import { Button } from 'primereact/button';
import PanelCompanyDetail from '../../Common/Company/PanelCompanyDetail';
import { useDashboardCompanyContext } from '../../../../contexts/DashboardCompanyContext';
import { useDashboardCollaboratorContext } from '../../../../contexts/DashboardCollaboratorContext';
import { useDebouncedCallback } from 'use-debounce';
import DashboardPanelCompanyList from './DashboardPanelCompanyList';

function DashboardPanelCompany({ isActive }) {
  const { navigationContext, toggleDashboardPanel } = useNavigationContext();
  const {
    dashboardCompanyContext,
    fetchDashboardCompanyList,
    fetchDashboardCompanyDetail,
    setDashboardCompanyQuery,
    resetDashboardCompanyDetail,
    handleCreateDashboardCompany,
    handleUpdateCompany
  } = useDashboardCompanyContext();

  const { openCollaboratorDetail, handleCreateCollaborator } = useDashboardCollaboratorContext();

  useEffect(() => {
    fetchDashboardCompanyList();
  }, [navigationContext.activeDashboard?.id]);

  const debouncedQuery = useDebouncedCallback((query) => {
    setDashboardCompanyQuery(query);
  }, 500);

  return (
    <div
      className={`h-full w-[450px] bg-white flex flex-col border-l py-3 px-5 gap-y-3 ${
        isActive ? '' : 'hidden'
      }`}>
      <div className="flex flex-row w-full justify-between items-center">
        <h3 className="text-2xl text-slate-900 font-semibold">{'Entreprises'}</h3>
        <div className="flex flex-row gap-x-1">
          {dashboardCompanyContext.company ? (
            <Button
              key="back"
              size="small"
              icon={'pi pi-arrow-left'}
              rounded
              text
              onClick={resetDashboardCompanyDetail}
            />
          ) : (
            <Button
              key="add"
              text
              rounded
              iconPos="left"
              icon={'pi pi-plus'}
              onClick={() => handleCreateDashboardCompany(false)}
            />
          )}
          <Button
            key="close"
            text
            rounded
            icon={'pi pi-times'}
            onClick={() => {
              toggleDashboardPanel(undefined);
            }}
          />
        </div>
      </div>

      {dashboardCompanyContext.company ? (
        <div className="panel-company-detail-container">
          <PanelCompanyDetail
            company={dashboardCompanyContext.company}
            handleCompanyUpdate={() => handleUpdateCompany(false)}
            selectCollaborator={openCollaboratorDetail}
            createCollaborator={() =>
              handleCreateCollaborator(true, 'consultant', dashboardCompanyContext.company)
            }
          />
        </div>
      ) : (
        <div className="panel-content-list-container ">
          <DashboardPanelCompanyList
            companyList={dashboardCompanyContext.companies}
            handleSelectCompany={(company) => fetchDashboardCompanyDetail(company)}
            handleSearch={debouncedQuery}
          />
        </div>
      )}
    </div>
  );
}

export default DashboardPanelCompany;

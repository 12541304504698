import React, { useMemo } from 'react';
import Section from '../../../components/Display/Section';
import Timeline, {
  TimelineMarkers,
  CustomMarker,
  TimelineHeaders,
  DateHeader,
  SidebarHeader
} from 'react-calendar-timeline';
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
import 'moment/locale/fr';
import { CONSTANT } from '../../../utils/constantHelper';
import { useAccountContext } from '../../../contexts/AccountContext';

const GROUP_HEIGHT = 65;
const SECOND_LINE_OFFSET = 30;

function ProjectSchedule({
  schedule,
  displayStartDate,
  displayEndDate,
  handleCreateSchedule,
  handleUpdateSchedule
}) {
  const { isEmployee } = useAccountContext();

  const isEditable = (step) => {
    if (step?.is_real || !isEmployee) return true;
    return false;
  };

  const computedGroups = useMemo(() => {
    const groups = [];
    for (const step in schedule) {
      if (schedule[step]) {
        groups.push({
          id: step,
          title: CONSTANT.project.steps[step].label,
          stackItems: false,
          height: GROUP_HEIGHT
        });
      }
    }
    return groups;
  });

  const computedItems = useMemo(() => {
    let items = [];
    for (const step in schedule) {
      if (schedule[step]) {
        schedule[step].forEach((s) => {
          items.push({
            id: s.id,
            group: s.type,
            type: s.is_real ? 'real' : 'initial',
            title: s.is_real ? 'Réel' : 'Initial',
            start_time: moment(s.start_dt),
            end_time: moment(s.end_dt),
            canMove: false,
            canResize: false,
            onClick: () => (isEditable(s) ? handleUpdateSchedule(s) : null)
          });
        });
      }
    }
    return items;
  }, [schedule]);

  const scheduleActions =
    !schedule?.study_step || !schedule?.instruction_step || !schedule?.prebuilding_step
      ? [
          {
            label: `Plannifier le projet`,
            icon: 'pi-calendar-plus',
            onClick: handleCreateSchedule
          }
        ]
      : [];

  const emptyScheduleSection = (
    <div className="h-[300px] w-full flex items-center justify-center italic text-slate-400 border border-slate-200 rounded">
      <span>Pas de planning à afficher pour le moment</span>
    </div>
  );

  const groupRenderer = ({ group }) => {
    return (
      <div className="flex flex-col justify-center h-full pl-1">
        <span className="font-medium whitespace-normal leading-5">{group.title}</span>
      </div>
    );
  };

  const itemRenderer = ({ item, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const isReal = item.type === 'real';
    return (
      <div
        onClick={item.onClick}
        {...getItemProps(item.itemProps)}
        className={`${
          getItemProps(item.itemProps).className
        } rounded !h-6  !border !border-slate-700 font-semibold ${
          isReal ? '!bg-slate-700 ' : '!bg-slate-300 !text-slate-700'
        } ${isReal || !isEmployee ? '' : '!cursor-not-allowed'}`}
        style={{
          ...getItemProps().style,
          top: isReal
            ? parseInt(getItemProps().style.top) + SECOND_LINE_OFFSET
            : getItemProps().style.top
        }}>
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}
        <div className="rct-item-content" style={{ maxHeight: `${itemContext.dimensions.height}` }}>
          {itemContext.title}
        </div>
        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
      </div>
    );
  };

  if (!schedule) return null;
  return (
    <Section label="Planning" actions={scheduleActions}>
      {computedGroups.length ? (
        <div className="flex flex-col gap-y-2">
          <Timeline
            groupRenderer={groupRenderer}
            itemRenderer={itemRenderer}
            groups={computedGroups}
            items={computedItems}
            resizeDetector={containerResizeDetector}
            defaultTimeStart={moment(displayStartDate).add(-1, 'months')}
            defaultTimeEnd={moment(displayEndDate).add(1, 'months')}
            maxZoom={10 * 365.24 * 86400 * 1000}
            minZoom={1 * 365.24 * 86400 * 1000}>
            <TimelineHeaders>
              <SidebarHeader>
                {({ getRootProps }) => {
                  return <div className="bg-slate-300" {...getRootProps()}></div>;
                }}
              </SidebarHeader>
              <DateHeader
                unit="primaryHeader"
                className="bg-slate-300"
                intervalRenderer={({ getIntervalProps, intervalContext }) => {
                  return (
                    <div
                      {...getIntervalProps()}
                      className="text-slate-800 font-medium flex h-full flex-row justify-around items-center border-l border-r border-slate-400">
                      <span>{intervalContext.intervalText}</span>
                      <span>{intervalContext.intervalText}</span>
                      <span>{intervalContext.intervalText}</span>
                    </div>
                  );
                }}
              />
              <DateHeader
                className="bg-slate-200"
                labelFormat={'MMM'}
                intervalRenderer={({ getIntervalProps, intervalContext }) => {
                  const isJanuary = intervalContext.interval.startTime.month() == 0;
                  const isDecember = intervalContext.interval.startTime.month() == 11;
                  return (
                    <div
                      {...getIntervalProps()}
                      className={`text-slate-700 flex h-full flex-col justify-center items-center text-sm border-slate-400 ${
                        isJanuary && ' border-l '
                      }
                ${isDecember && ' border-r '}
                `}>
                      {intervalContext.intervalText}
                    </div>
                  );
                }}
              />
            </TimelineHeaders>
            <TimelineMarkers>
              <CustomMarker date={moment()}>
                {({ styles, date }) => {
                  const customStyles = {
                    ...styles,
                    backgroundColor: '#F59E0B',
                    width: '2px',
                    zIndex: 89
                  };
                  return <div style={customStyles} />;
                }}
              </CustomMarker>
            </TimelineMarkers>
          </Timeline>
          <div className="flex flex-wrap gap-x-3 text-xs text-slate-600">
            <p>CTRL + Molette pour zoomer grossièrement</p>
            <p>|</p>
            <p>ALT + Molette pour zoomer précisement</p>
            <p>|</p>
            <p>SHIFT + Molette / Maintenir le clic gauche pour déplacer</p>
          </div>
        </div>
      ) : (
        emptyScheduleSection
      )}
    </Section>
  );
}

export default ProjectSchedule;

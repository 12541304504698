import React from 'react';
import CustomIcon from '../CustomIcon';

function LogoutItem({ onClick }) {
  return (
    <div
      onClick={onClick}
      className={
        'w-20 h-full flex items-center justify-center transition-colors opacity-80 text-slate-50 hover:opacity-100 hover:bg-slate-900 hover:cursor-pointer'
      }>
      <CustomIcon icon={'logout'} className={'h-6 w-6'} />
    </div>
  );
}

export default LogoutItem;

import { Dropdown } from 'primereact/dropdown';
import React, { useMemo } from 'react';

function PanelFilter({ options, selectedValue, onSelect, emptyLabel, optionLabel, showFilter }) {
  const valueTemplate = (option, props) => {
    if (!option) return <span className="italic text-sm">{props.placeholder}</span>;
    return (
      <span className="text-slate-800 text-sm font-semibold">{option[optionLabel || 'label']}</span>
    );
  };
  const isActive = useMemo(() => selectedValue && selectedValue.value !== '', [selectedValue]);
  return (
    <Dropdown
      value={selectedValue}
      onChange={(e) => onSelect(e.value)}
      options={options}
      optionLabel={optionLabel || 'label'}
      placeholder={emptyLabel}
      showClear
      filter={showFilter}
      className={`w-[200px] text-sm rounded-full pl-1  ${isActive ? 'active-custom-dropdown' : ''}`}
      scrollHeight={'350px'}
      valueTemplate={valueTemplate}
    />
  );
}

export default PanelFilter;

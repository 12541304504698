import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { CONSTANT } from '../../../../utils/constantHelper';
import FormInput from '../../../../components/Form/FormInput';
import { InputText } from 'primereact/inputtext';
import { getDateStringOrUndefined } from '../../../../utils/stringHelper';
import Tag from '../../../../components/Display/Tag';

function ManagePrebuilding({
  isOpen,
  handleClose,
  createPrebuilding,
  updatePrebuilding,
  deletePrebuilding,
  loading,
  prebuilding
}) {
  const [name, setName] = useState('');
  const [type, setType] = useState(undefined);
  const [state, setState] = useState(undefined);
  const [expectedBudget, setExpectedBudget] = useState(0);
  const [expectedStartDate, setExpectedStartDate] = useState(undefined);
  const [expectedEndDate, setExpectedEndDate] = useState(undefined);

  useEffect(() => {
    if (prebuilding) {
      setName(prebuilding.name);
      setState(prebuilding.state);
      setType(prebuilding.type);
      setExpectedStartDate(new Date(prebuilding.expected_start_dt));
      setExpectedEndDate(new Date(prebuilding.expected_end_dt));
      setExpectedBudget(prebuilding.expected_budget);
    } else {
      setName('');
      setState('in_progress');
      setType(CONSTANT.project.prebuilding.type[0].value);
      setExpectedBudget(0);
      setExpectedStartDate(undefined);
      setExpectedEndDate(undefined);
    }
  }, [isOpen]);

  const onValidate = () => {
    const prebuildingForm = {
      name,
      type,
      state,
      expected_budget: expectedBudget,
      expected_start_dt: getDateStringOrUndefined(expectedStartDate),
      expected_end_dt: getDateStringOrUndefined(expectedEndDate)
    };
    prebuilding ? updatePrebuilding(prebuildingForm) : createPrebuilding(prebuildingForm);
  };

  const expectedBudgetIsValid = expectedBudget !== null && expectedBudget !== undefined;

  const contentIsValid =
    name.length > 2 &&
    type &&
    expectedBudgetIsValid &&
    expectedStartDate &&
    expectedEndDate &&
    state;

  const footerButtons = (
    <div className="manager-footer">
      {prebuilding && (
        <Button
          key={'delete'}
          label={`Supprimer la post-instruction`}
          onClick={deletePrebuilding}
          severity="danger"
          outlined
        />
      )}
      <Button
        key={'validate'}
        label={`Valider la ${prebuilding ? 'modification' : 'création'}`}
        loading={loading}
        disabled={!contentIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  const dropdownTemplate = (option, props) => {
    if (option) {
      return (
        <Tag
          tag={{
            label: option.label,
            bgColor: option.bgColor
          }}
          rounded
        />
      );
    }
    return <span>{props.placeholder}</span>;
  };

  return (
    <Sidebar
      style={{ width: 530, paddingLeft: 10, paddingRight: 10 }}
      header={
        <h2 className="text-slate-800">{`${
          prebuilding ? 'Modifier' : 'Créer'
        } une post-instruction`}</h2>
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="manager-form-wrapper">
          <FormInput key="name" label="Nom" required isValid={name.length > 2}>
            <InputText
              value={name}
              placeholder="Veuillez saisir le nom de la post-instruction"
              onChange={(e) => setName(e.target.value)}
              disabled={prebuilding}
            />
          </FormInput>

          <FormInput label={'Type'} required isValid={type}>
            <Dropdown
              id="type"
              value={type}
              onChange={(e) => setType(e.value)}
              options={CONSTANT.project.prebuilding.type}
              placeholder="Veuillez sélectionner un type de post-instruction"
            />
          </FormInput>

          <FormInput label={'État'} required isValid={state}>
            <Dropdown
              id="state"
              value={state}
              onChange={(e) => setState(e.value)}
              options={CONSTANT.project.prebuilding.state}
              placeholder="Veuillez sélectionner un état"
              disabled={!prebuilding}
              valueTemplate={dropdownTemplate}
              itemTemplate={dropdownTemplate}
            />
          </FormInput>

          <FormInput label={'Budget estimé (€ HT)'} required isValid={expectedBudgetIsValid}>
            <InputNumber
              value={expectedBudget}
              placeholder="Veuillez saisir le budget estimé"
              onChange={(e) => setExpectedBudget(e.value)}
              min={0}
              suffix={' € HT'}
              locale="fr-FR"
            />
          </FormInput>

          <FormInput
            key="expectedStartDate"
            label="Date de début prévisionnelle"
            required
            isValid={expectedStartDate}>
            <Calendar
              value={expectedStartDate}
              onChange={(e) => setExpectedStartDate(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date de début prévisionnelle"
              locale="fr"
            />
          </FormInput>

          <FormInput
            key="expectedEndDate"
            label="Date de fin prévisionnelle"
            required
            isValid={expectedEndDate}>
            <Calendar
              value={expectedEndDate}
              onChange={(e) => setExpectedEndDate(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date de fin prévisionnelle"
              locale="fr"
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManagePrebuilding;

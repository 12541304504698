import React, { useEffect } from 'react';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import PanelCollaboratorList from './PanelCollaboratorList';
import { Button } from 'primereact/button';
import PanelCollaboratorDetail from '../../Common/Collaborator/PanelCollaboratorDetail';
import { useProjectCollaboratorContext } from '../../../../contexts/ProjectCollaboratorContext';
import { useDebouncedCallback } from 'use-debounce';
import { useProjectCompanyContext } from '../../../../contexts/ProjectCompanyContext';

function PanelCollaborator({ isActive }) {
  const { navigationContext, toggleProjectPanel } = useNavigationContext();
  const {
    projectCollaboratorContext,
    fetchProjectCollaboratorList,
    fetchProjectCollaboratorDetail,
    setProjectCollaboratorQuery,
    resetCollaboratorDetail,
    handleCreateProjectCollaborator,
    handleUpdateProjectCollaborator
  } = useProjectCollaboratorContext();
  const { openProjectCompanyDetail } = useProjectCompanyContext();

  useEffect(() => {
    fetchProjectCollaboratorList();
  }, [navigationContext.activeProject?.id]);

  const handleCreate = () => {
    handleCreateProjectCollaborator(false, 'other');
  };

  const debouncedQuery = useDebouncedCallback((query) => {
    setProjectCollaboratorQuery(query);
  }, 500);

  return (
    <div
      className={`h-full w-[450px] bg-white flex flex-col border-l py-3 px-5 gap-y-3 ${
        isActive ? '' : 'hidden'
      }`}>
      <div className="flex flex-row w-full justify-between items-center">
        <h3 className="text-2xl text-slate-900 font-semibold">{'Contacts'}</h3>

        <div className="flex flex-row gap-x-1">
          {projectCollaboratorContext.collaborator ? (
            <Button
              key="back"
              size="small"
              icon={'pi pi-arrow-left'}
              rounded
              text
              onClick={resetCollaboratorDetail}
            />
          ) : (
            <Button
              key="add"
              text
              rounded
              iconPos="left"
              icon={'pi pi-plus'}
              onClick={handleCreate}
            />
          )}
          <Button
            key="close"
            text
            rounded
            icon={'pi pi-times'}
            onClick={() => {
              toggleProjectPanel(undefined);
            }}
          />
        </div>
      </div>
      {projectCollaboratorContext.collaborator ? (
        <div className="panel-content-detail-container">
          <PanelCollaboratorDetail
            collaborator={projectCollaboratorContext.collaborator}
            handleCollaboratorUpdate={() => handleUpdateProjectCollaborator(false)}
            selectCompany={openProjectCompanyDetail}
          />
        </div>
      ) : (
        <div className="panel-content-list-container">
          <PanelCollaboratorList
            collaboratorList={projectCollaboratorContext.collaborators}
            handleSelectCollaborator={(collaborator) =>
              fetchProjectCollaboratorDetail(collaborator)
            }
            handleSearch={debouncedQuery}
          />
        </div>
      )}
    </div>
  );
}

export default PanelCollaborator;

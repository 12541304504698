import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import FormInput from '../../../../components/Form/FormInput';
import { privateFetch } from '../../../../utils/apiHelper';
import DataTag from '../../../../components/Display/DataTag';
import { Divider } from 'primereact/divider';
import { ListBox } from 'primereact/listbox';
import Avatar from '../../../../components/Display/Avatar';
import { useProjectCollaboratorContext } from '../../../../contexts/ProjectCollaboratorContext';

function ManageStudyCompanyCollaborators({
  isOpen,
  handleClose,
  updateStudyCompanyCollaborators,
  study,
  loading
}) {
  const [selectedCollaboratorList, setSelectedCollaboratorList] = useState([]);
  const [companyCollaboratorList, setCompanyCollaboratorList] = useState([]);
  const { projectCollaboratorContext, handleCreateProjectCollaborator } =
    useProjectCollaboratorContext();

  useEffect(() => {
    if (study) {
      setSelectedCollaboratorList(study?.collaborators || []);
      fetchCompanyCollaboratorList();
    }
  }, [isOpen, projectCollaboratorContext]);

  const fetchCompanyCollaboratorList = async () => {
    await privateFetch('GET', `/company/${study?.company.id}/collaborator/`).then((res) => {
      setCompanyCollaboratorList(res);
    });
  };

  const onValidate = () => {
    const studyCompanyCollaboratorForm = {
      collaborators: selectedCollaboratorList.map((c) => c.id)
    };
    updateStudyCompanyCollaborators(studyCompanyCollaboratorForm);
  };

  const collaboratorTemplate = (option, e) => {
    return (
      <div className="flex flex-row gap-x-3 items-center">
        <Avatar alias={option.alias} />
        <div className="font-medium">{option.alias}</div>
      </div>
    );
  };

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={'Nouveau contact associé'}
        onClick={() => handleCreateProjectCollaborator(true, 'consultant', study.company)}
        severity="primary"
        outlined
        className="w-full"
      />
      <Button
        key={'validate'}
        label={`Valider la modification`}
        loading={loading}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 530, paddingLeft: 10, paddingRight: 10 }}
      header={<h2 className="text-slate-800">{`Modifier les contacts associés`}</h2>}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="manager-form-wrapper">
          <DataTag
            key="company"
            label="Prestataire"
            tag={{
              label: study?.company?.name,
              icon: 'company'
            }}
            large
          />
          <Divider className="m-0" />
          <FormInput label={'Contacts associés'}>
            <ListBox
              multiple
              itemTemplate={collaboratorTemplate}
              value={selectedCollaboratorList}
              onChange={(e) => setSelectedCollaboratorList(e.value)}
              options={companyCollaboratorList}
              optionLabel="alias"
              className="w-full"
            />
          </FormInput>
        </div>

        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageStudyCompanyCollaborators;

import { Button } from 'primereact/button';
import React from 'react';
import Data from '../../../components/Display/Data';
import { getConstant } from '../../../utils/constantHelper';
import DataTag from '../../../components/Display/DataTag';

function AdminAccountInfo({ account, handlePasswordUpdate }) {
  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex flex-row w-full justify-between items-center">
        <h4 className="text-slate-800">Mes informations</h4>
        <Button
          key="password"
          label={'Changer de mot de passe'}
          size="small"
          outlined
          iconPos="left"
          icon="pi pi-lock"
          onClick={handlePasswordUpdate}
        />
      </div>
      <div className="flex flex-row gap-x-5">
        <Data key="firstName" label={'Prénom'} value={account.collaborator.first_name} />
        <Data key="lastName" label={'Nom'} value={account.collaborator.name} />
        <DataTag key="type" tag={getConstant('admin.accountType', account.type)} label={'Rôle'} />
        <Data key="phone" label={'Téléphone'} value={account.collaborator.phone} />
        <Data key="email" label={'Email'} value={account.collaborator.email} large />
      </div>
    </div>
  );
}

export default AdminAccountInfo;

import React, { useState, useEffect } from 'react';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import { privateFetch } from '../../../../utils/apiHelper';
import { Button } from 'primereact/button';
import ChecklistItem from '../../../../components/Display/ChecklistItem';
import { getConstant } from '../../../../utils/constantHelper';

function ProjectInstructionOnboarding({ updateProjectStep }) {
  const { navigationContext } = useNavigationContext();
  const [onBoardingDetail, setOnBoardingDetail] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchInstructionOnboardingDetail();
  }, []);

  const fetchInstructionOnboardingDetail = async () => {
    setLoading(true);
    await privateFetch(
      'GET',
      `/project/${navigationContext.activeProject.id}/instruction/onboarding/`
    ).then((res) => {
      if (res) setOnBoardingDetail(res);
      setLoading(false);
    });
  };

  const handleUpdateProjectStep = () => {
    const payload = {
      old_value: null,
      target_value: 2,
      key: 'instruction_step'
    };
    payload['instruction_step'] = 2;
    updateProjectStep(payload);
  };

  return (
    <div className="data-section accordion-tab">
      <p>
        Avant de démarrer l'étape d'instruction, les conditions suivantes doivent être remplies :
      </p>
      <div className="flex flex-col ml-2 gap-y-2">
        {onBoardingDetail?.valid_conditions.map((vc) => (
          <ChecklistItem
            key={vc}
            label={getConstant('project.instruction.onBoarding', vc).label}
            isValid
          />
        ))}
        {onBoardingDetail?.invalid_conditions.map((ic) => (
          <ChecklistItem key={ic} label={getConstant('project.instruction.onBoarding', ic).label} />
        ))}
      </div>
      <div className="flex flex-row gap-x-3">
        <Button
          key="start"
          label={"Démarrer l'instruction"}
          loading={loading}
          disabled={onBoardingDetail?.invalid_conditions?.length}
          onClick={handleUpdateProjectStep}
        />
        <Button
          key="start"
          label={'Rafraichir les conditions'}
          outlined
          text
          loading={loading}
          icon={'pi pi-refresh'}
          onClick={fetchInstructionOnboardingDetail}
        />
      </div>
    </div>
  );
}

export default ProjectInstructionOnboarding;

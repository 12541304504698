import React from 'react';
import QuillEditor from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function RichTextEditor({ value, onChange, height, placeholder }) {
  const modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ]
    }
  };
  return (
    <QuillEditor
      theme="snow"
      value={`${value}`}
      onChange={onChange}
      className={`${height || 'h-80'} mb-12`}
      modules={modules}
      placeholder={`${placeholder}`}
    />
  );
}

export default RichTextEditor;

import React, { useState, useEffect, useMemo } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { CONSTANT } from '../../../utils/constantHelper';
import moment from 'moment';
import FormInput from '../../../components/Form/FormInput';
import { Calendar } from 'primereact/calendar';
import Data from '../../../components/Display/Data';
import { Divider } from 'primereact/divider';
import { InputTextarea } from 'primereact/inputtextarea';
import { capitalizeFirstLetter } from '../../../utils/stringHelper';

function ManageProjectScheduleUpdate({ isOpen, handleClose, schedule, updateSchedule, loading }) {
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [content, setContent] = useState('');

  useEffect(() => {
    if (schedule) {
      setStartDate(moment(schedule.start_dt));
      setEndDate(moment(schedule.end_dt));
      setContent('');
    }
  }, [schedule, isOpen]);

  const onValidate = () => {
    const stepForm = {
      start_dt: moment(startDate).format('YYYY-MM-DD'),
      end_dt: moment(endDate).format('YYYY-MM-DD'),
      content
    };
    updateSchedule(stepForm);
  };

  const contentIsValid = content?.length > 9 || !schedule?.is_real;
  const formIsValid =
    startDate && endDate && moment(startDate).isBefore(moment(endDate)) && contentIsValid;

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={'Valider la modification'}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  if (!schedule) return null;
  return (
    <Sidebar
      style={{ width: 530, paddingLeft: 10, paddingRight: 10 }}
      header={
        <h2 className="text-slate-800">{`Modifier ${
          CONSTANT.project.steps[schedule.type].additionnalLabel
        }`}</h2>
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="manager-form-wrapper">
          <Data
            key="actualStartDate"
            label={`Date de début ${schedule?.is_real ? 'réelle' : 'initiale'} actuelle`}
            value={capitalizeFirstLetter(moment(schedule.start_dt).format('MMMM yy'))}
            large
          />
          <Data
            key="actualEndDate"
            label={`Date de fin ${schedule?.is_real ? 'réelle' : 'initiale'} actuelle`}
            value={capitalizeFirstLetter(moment(schedule.end_dt).format('MMMM yy'))}
            large
          />
          <FormInput
            key="startDate"
            label={`Nouvelle date de début ${schedule?.is_real ? 'réelle' : 'initiale'}`}
            required
            isValid={startDate}>
            <Calendar
              value={new Date(startDate)}
              maxDate={new Date(endDate)}
              view="month"
              onChange={(e) => setStartDate(e.value)}
              dateFormat={`MM yy`}
              placeholder="Veuillez selectionner le mois de début estimée"
              locale="fr"
            />
          </FormInput>
          <FormInput
            key="endDate"
            label={`Nouvelle date de fin ${schedule?.is_real ? 'réelle' : 'initiale'}`}
            required
            isValid={endDate}>
            <Calendar
              view="month"
              value={new Date(endDate)}
              minDate={new Date(startDate)}
              onChange={(e) => setEndDate(e.value)}
              dateFormat={`MM yy`}
              placeholder="Veuillez selectionner le mois de fin estimée"
              locale="fr"
            />
          </FormInput>
          <Divider />
          {schedule?.is_real ? (
            <FormInput
              label={'Commentaires concernant la décision'}
              required
              isValid={contentIsValid}>
              <InputTextarea
                rows={3}
                autoResize
                value={content}
                placeholder="Veuillez saisir un commentaire d'au moins 10 caractères"
                onChange={(e) => setContent(e.target.value)}
              />
            </FormInput>
          ) : (
            <div className="text-slate-600 italic text-sm">
              Vous êtes sur le point de modifier une planification initiale. Cette action n'est
              disponible que pour les managers et administrateurs.
            </div>
          )}
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageProjectScheduleUpdate;

import moment from 'moment';

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const formatAlias = (collaborator) => {
  if (!collaborator) return '';
  return [collaborator.first_name, collaborator.name].join(' ');
};

export const formatCity = (city) => {
  if (!city) return '';
  return [city.zip_code, city?.name].join(' - ');
};

export const formatDate = (date) => {
  return date ? moment(date).format('DD/MM/YYYY') : '';
};

export const getDateStringOrUndefined = (date) => {
  return date ? moment(date).format('YYYY-MM-DD') : undefined;
};

export const applyCurrencyFormat = (value) => {
  if (value === 0)
    return value.toLocaleString('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0
    });
  if (!value) return '';
  return value.toLocaleString('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0
  });
};

export const applyAreaFormat = (value) => {
  if (value === 0) return '0 m²';
  if (!value) return '';
  return `${value.toLocaleString('fr-FR', {
    minimumFractionDigits: 0
  })} m²`;
};

import React, { createContext, useContext, useEffect, useState } from 'react';
import { privateFetch } from '../utils/apiHelper';
import { useNavigationContext } from './NavigationContext';

const ProjectEventContext = createContext(null);

const initial = {
  query: '',
  type: undefined,
  author: undefined,
  events: []
};

export const ProjectEventContextProvider = ({ children }) => {
  const { navigationContext } = useNavigationContext();
  const [projectEventContext, setProjectEventContext] = useState(initial);
  const [lastEventContext, setLastEventContext] = useState(undefined);

  useEffect(() => {
    if (navigationContext.activeProject?.id) {
      fetchProjectEventList();
      fetchProjectLastEvent();
    }
  }, [
    projectEventContext.query,
    projectEventContext.type,
    projectEventContext.author,
    navigationContext.activeProject?.id
  ]);

  useEffect(() => {
    if (navigationContext.activeProject?.id) {
      fetchProjectLastEvent();
    }
  }, [projectEventContext]);

  const setProjectEventQuery = (query) => {
    setProjectEventContext({
      ...projectEventContext,
      query
    });
  };

  const setProjectEventType = (type) => {
    setProjectEventContext({
      ...projectEventContext,
      type
    });
  };

  const setProjectEventAuthor = (collaborator) => {
    setProjectEventContext({
      ...projectEventContext,
      author: collaborator
    });
  };

  const fetchProjectEventList = async () => {
    await privateFetch(
      'GET',
      `/project/${navigationContext.activeProject.id}/event/?q=${projectEventContext.query}&t=${
        projectEventContext.type || ''
      }&c=${projectEventContext.author?.id || ''}`
    ).then((res) => {
      if (res) {
        setProjectEventContext({
          ...projectEventContext,
          events: res
        });
      }
    });
  };

  const fetchProjectLastEvent = async () => {
    await privateFetch('GET', `/project/${navigationContext.activeProject.id}/last-event/`).then(
      (res) => {
        if (res) {
          if (res.length) {
            setLastEventContext(res[0]);
          } else {
            setLastEventContext(undefined);
          }
        }
      }
    );
  };

  return (
    <ProjectEventContext.Provider
      value={{
        projectEventContext,
        setProjectEventContext,
        lastEventContext,
        fetchProjectEventList,
        setProjectEventQuery,
        setProjectEventType,
        setProjectEventAuthor,
        fetchProjectLastEvent
      }}>
      {children}
    </ProjectEventContext.Provider>
  );
};

export const useProjectEventContext = () => {
  return useContext(ProjectEventContext);
};

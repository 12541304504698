import React from 'react';
import Section from '../../../components/Display/Section';
import { Chart } from 'primereact/chart';

const options = {
  indexAxis: 'x',
  maintainAspectRatio: false,
  aspectRatio: 0.8,
  plugins: {
    legend: {
      labels: {
        color: '#475569',
        font: {
          size: 14,
          family: 'Montserrat',
          weight: 500
        }
      }
    },
    tooltip: {
      titleFont: {
        size: 16,
        family: 'Montserrat',
        weight: 500
      },
      bodyFont: {
        size: 14,
        family: 'Montserrat'
      },
      callbacks: {
        label: function (tooltipItems, data) {
          return tooltipItems.formattedValue + ' € HT';
        }
      }
    }
  },
  scales: {
    x: {
      ticks: {
        color: '#020617',
        font: {
          size: 14,
          weight: 500,
          family: 'Montserrat'
        }
      },
      grid: {
        display: false,
        drawBorder: false
      }
    },
    y: {
      ticks: {
        color: '#020617',
        font: {
          size: 12,
          family: 'Montserrat'
        },
        callback: function (value, index, ticks) {
          return value + ' €HT';
        }
      },
      grid: {
        color: '#E2E8F0',
        drawBorder: false
      }
    }
  }
};

function ProjectBudget({ data }) {
  const emptyBudgetSection = (
    <div className="h-[400px] w-[600px] flex items-center justify-center italic text-slate-400 border border-slate-200 rounded">
      <span>Pas de budget à afficher</span>
    </div>
  );

  return (
    <Section label="Budget">
      {!data && emptyBudgetSection}
      {data && (
        <div className="w-full">
          <Chart type="bar" data={data} options={options} className="w-full h-[400px]" />
        </div>
      )}
    </Section>
  );
}

export default ProjectBudget;

import React, { createContext, useContext, useEffect, useState } from 'react';
import { privateFetch } from '../utils/apiHelper';
import { useNavigationContext } from './NavigationContext';
import ManageCompany from '../pages/private/Common/ManageCompany';

const DashboardCompanyContext = createContext(null);

const initial = {
  query: '',
  type: '',
  companies: [],
  company: undefined
};

export const DashboardCompanyContextProvider = ({ children }) => {
  const { navigationContext, toggleDashboardPanel } = useNavigationContext();
  const [dashboardCompanyContext, setDashboardCompanyContext] = useState(initial);

  // Manager Controls
  const [dashboardCompanyManagerVisible, setDashboardCompanyManagerVisible] = useState(false);
  const [dashboardCompanyMandatoryType, setDashboardCompanyMandatoryType] = useState(undefined);
  const [dashboardManagerAsDialog, setDashboardManagerAsDialog] = useState(false);

  useEffect(() => {
    fetchDashboardCompanyList();
  }, [dashboardCompanyContext.query, dashboardCompanyContext.type]);

  const setDashboardCompanyQuery = (query) => {
    setDashboardCompanyContext({
      ...dashboardCompanyContext,
      query
    });
  };

  const setDashboardCompanyType = (type) => {
    setDashboardCompanyContext({
      ...dashboardCompanyContext,
      type
    });
  };

  const resetDashboardCompanyDetail = () => {
    setDashboardCompanyContext({
      ...dashboardCompanyContext,
      company: undefined
    });
  };

  const openDashboardCompanyDetail = (dashboardCompany) => {
    if (navigationContext.activeDashboardPanel !== 'company') toggleDashboardPanel('company');
    fetchDashboardCompanyDetail(dashboardCompany);
  };

  const fetchDashboardCompanyList = async () => {
    await privateFetch(
      'GET',
      `/company/?q=${dashboardCompanyContext.query}&t=${dashboardCompanyContext.type || ''}`
    ).then((res) => {
      if (res) {
        setDashboardCompanyContext({
          ...dashboardCompanyContext,
          companies: res
        });
      }
    });
  };

  const fetchDashboardCompanyDetail = async (
    targetDashboardCompany = dashboardCompanyContext.company
  ) => {
    await privateFetch('GET', `/company/${targetDashboardCompany.id}/`).then((res) => {
      if (res) {
        setDashboardCompanyContext({
          ...dashboardCompanyContext,
          company: res
        });
      }
    });
  };

  const createDashboardCompany = async (companyForm) => {
    await privateFetch(
      'POST',
      `/company/`,
      `L'entreprise ${companyForm.name} a bien été créé.`,
      JSON.stringify(companyForm)
    ).then((res) => {
      setDashboardCompanyManagerVisible(false);
      fetchDashboardCompanyList();
    });
  };

  const updateDashboardCompany = async (companyForm) => {
    await privateFetch(
      'PUT',
      `/company/${dashboardCompanyContext.company.id}/`,
      `L'entreprise ${companyForm.name} a bien été modifiée.`,
      JSON.stringify(companyForm)
    ).then((res) => {
      setDashboardCompanyManagerVisible(false);
      fetchDashboardCompanyList();
      fetchDashboardCompanyDetail();
    });
  };

  const handleCreateDashboardCompany = (asDialog = false, mandatoryType = undefined) => {
    setDashboardManagerAsDialog(asDialog);
    setDashboardCompanyManagerVisible(true);
    setDashboardCompanyMandatoryType(mandatoryType);
  };

  const handleUpdateCompany = (asDialog = false, mandatoryType = undefined) => {
    setDashboardManagerAsDialog(asDialog);
    setDashboardCompanyManagerVisible(true);
  };

  return (
    <DashboardCompanyContext.Provider
      value={{
        dashboardCompanyContext,
        setDashboardCompanyContext,
        fetchDashboardCompanyList,
        fetchDashboardCompanyDetail,
        setDashboardCompanyQuery,
        setDashboardCompanyType,
        resetDashboardCompanyDetail,
        openDashboardCompanyDetail,
        handleCreateDashboardCompany,
        handleUpdateCompany
      }}>
      {children}

      <ManageCompany
        isOpen={dashboardCompanyManagerVisible}
        company={dashboardCompanyContext.company}
        asDialog={dashboardManagerAsDialog}
        mandatoryType={dashboardCompanyMandatoryType}
        handleCreateCompany={createDashboardCompany}
        handleUpdateCompany={updateDashboardCompany}
        handleClose={() => {
          setDashboardCompanyManagerVisible(false);
          setDashboardCompanyMandatoryType(undefined);
        }}
      />
    </DashboardCompanyContext.Provider>
  );
};

export const useDashboardCompanyContext = () => {
  return useContext(DashboardCompanyContext);
};

import React from 'react';

function ChecklistItem({ isValid, label }) {
  return (
    <div
      className={`flex flex-row gap-x-4 items-center text-lg font-semibold ${
        isValid ? 'text-custom-green-dark' : 'text-custom-red'
      }`}>
      <i className={`pi ${isValid ? 'pi-check' : 'pi-times'}`} />
      <span>{label}</span>
    </div>
  );
}

export default ChecklistItem;

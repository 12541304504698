import React from 'react';
import Panelbar from './DashboardPanelbar';
import PanelColumns from './Columns/PanelColumns';
import DashboardPanelCollaborator from './Collaborator/DashboardPanelCollaborator';
import { useNavigationContext } from '../../../contexts/NavigationContext';

import '../Panel.scss';
import DashbaordPanelCompany from './Company/DashboardPanelCompany';

function DashboardPanel() {
  const { navigationContext } = useNavigationContext();
  return (
    <div className="flex flex-row h-full">
      <PanelColumns isActive={navigationContext.activeDashboardPanel === 'column'} />
      <DashboardPanelCollaborator
        isActive={navigationContext.activeDashboardPanel === 'collaborator'}
      />
      <DashbaordPanelCompany isActive={navigationContext.activeDashboardPanel === 'company'} />
      <Panelbar />
    </div>
  );
}
export default DashboardPanel;

import React from 'react';
import { useNavigationContext } from '../../../contexts/NavigationContext';
import PanelbarItem from '../PanelbarItem';
import { CONSTANT } from '../../../utils/constantHelper';

function ProjectPanelbar() {
  const { navigationContext, toggleProjectPanel, showReleaseNote } = useNavigationContext();

  return (
    <div className="h-full flex flex-col justify-between bg-slate-800 w-20 pb-3">
      <div className="w-full h-full flex flex-col">
        <PanelbarItem
          key={'comment'}
          icon={'comment'}
          className={'h-16'}
          onClick={() => toggleProjectPanel('comment')}
          isActive={navigationContext.activeProjectPanel === 'comment'}
        />
        <PanelbarItem
          key={'event'}
          icon={'event'}
          className={'h-16'}
          onClick={() => toggleProjectPanel('event')}
          isActive={navigationContext.activeProjectPanel === 'event'}
        />
        <PanelbarItem
          key={'meeting'}
          icon={'meeting'}
          className={'h-16'}
          onClick={() => toggleProjectPanel('meeting')}
          isActive={navigationContext.activeProjectPanel === 'meeting'}
        />
        <PanelbarItem
          key={'collaborator'}
          icon={'contact'}
          className={'h-16'}
          onClick={() => toggleProjectPanel('collaborator')}
          isActive={navigationContext.activeProjectPanel === 'collaborator'}
        />
        <PanelbarItem
          key={'company'}
          icon={'company'}
          className={'h-16'}
          onClick={() => toggleProjectPanel('company')}
          isActive={navigationContext.activeProjectPanel === 'company'}
        />
      </div>
      <span
        onClick={showReleaseNote}
        className="text-slate-400 text-sm hover:underline hover:text-slate-100 cursor-pointer text-center">
        {CONSTANT.version}
      </span>
    </div>
  );
}

export default ProjectPanelbar;

import React, { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import CustomIcon from '../CustomIcon';
import { Button } from 'primereact/button';
import FormInput from '../Form/FormInput';
import { FileUpload } from 'primereact/fileupload';
import { privateFetch } from '../../utils/apiHelper';
import RichTextEditor from '../Form/RichTextEditor';

function BugReportDialog({ visible, handleClose }) {
  const fileUploadRef = useRef(null);
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const handleValidate = () => {
    const formData = new FormData();
    formData.append('content', description);
    file && formData.append('file', file);
    sendBugReport(formData);
  };

  const sendBugReport = async (formData) => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/bug/`,
      'Le rapport de bug a bien été envoyé. Merci.',
      formData,
      false,
      true
    ).then(() => {
      setLoading(false);
      setDescription('');
      setFile(undefined);
      handleClose();
    });
  };

  const headerElement = (
    <div className="flex flex-row gap-x-4 items-center text-slate-800">
      <CustomIcon icon={'bug'} className="h-10 w-10" />
      <span className="font-semibold text-2xl">{`Rapporter un bug`}</span>
    </div>
  );

  const handleRemoveFile = () => {
    setFile(undefined);
    fileUploadRef.current.clear();
  };

  const onUpload = (event) => {
    if (event.files.length === 1) {
      const file = event.files[0];
      setFile(file);
    }
  };

  const isDescriptionValid = description.length > 20;

  const footerButton = (
    <div className="flex mt-5">
      <Button
        key={'validate'}
        label={'Envoyer par mail aux développeurs'}
        onClick={handleValidate}
        severity="success"
        disabled={!isDescriptionValid}
        loading={loading}
      />
    </div>
  );

  return (
    <Dialog
      header={headerElement}
      visible={visible}
      draggable={false}
      style={{ width: '50vw', maxHeight: '80vh' }}
      onHide={handleClose}>
      <div className="flex flex-col gap-y-3 pb-5">
        <div className="flex gap-2 items-center ">
          <i className="pi pi-exclamation-triangle text-orange-500 text-lg"></i>
          <span className="font-medium">
            Avant de déclarer une anomalie,{' '}
            <strong className="text-orange-500"> tentez de rafraichir la page</strong>. Si le bug
            persiste, merci de le signaler.
          </span>
        </div>
        <p>
          {
            "Afin de comprendre au mieux le bug rencontré et de pouvoir le reproduire, les développeurs ont besoin d'un maximum d'informations :"
          }
        </p>
        <div className={'flex flex-col gap-y-2 ml-5'}>
          <li>
            Quelle est la nature du bug (page blanche, alerte rouge, comportement non attendu, ...)
            ?
          </li>
          <li>Sur quelle page est survenu le bug ?</li>
          <li>Que faisiez-vous juste avant que le bug se produise ?</li>
        </div>

        <FormInput
          label={'Décrire le bug'}
          required
          isValid={isDescriptionValid}
          helpMessage={'Au moins 10 caractères'}>
          <RichTextEditor
            value={description}
            onChange={(value) => {
              setDescription(value);
            }}
            height={'h-48'}
            placeholder={'Décrivez le bug'}
          />
        </FormInput>
        <FormInput label={"Capture d'écran"}>
          {file && (
            <div className="flex gap-x-3 text-custom-green-dark font-medium items-center mb-2 mt-1">
              <span className="pi pi-check"></span>
              <span>{file.name}</span>
            </div>
          )}
          <div className="flex flex-row gap-x-3">
            {file && (
              <Button
                key={'delete-img'}
                label={'Changer de fichier'}
                outlined
                onClick={handleRemoveFile}
                severity="primary"
              />
            )}
            <FileUpload
              ref={fileUploadRef}
              auto
              disabled={file}
              mode="basic"
              name="file"
              accept="image/*"
              customUpload
              uploadHandler={onUpload}
              chooseLabel="Depuis mon ordinateur"
            />
          </div>
        </FormInput>
        {footerButton}
      </div>
    </Dialog>
  );
}

export default BugReportDialog;
